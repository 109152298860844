import {
  ChangeDetectionStrategy,
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  inject,
  ChangeDetectorRef,
  OnInit,
  OnDestroy, SimpleChanges, OnChanges
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  ManageCegTableRow,
  PerformanceColumnData,
  SegmentBreakdownConfig,
  ManageTableRowTypeLabel,
  ManageCegTableSelectionState,
  ManageCegTableActionDataSource,
  ManageCegTableActionEvent,
  ManageCegTableRowAllocations,
  CreateOverlayEvent,
  ManageTableBudgetColumnName,
  PresentationTimeframe,
  OpenExpensesEvent, BudgetTimeframeBrief, SegmentBreakdownRow,
} from '@manage-ceg/types/manage-ceg-page.types';
import { slideFromRightColumnAnimations } from '@manage-ceg/constants/manage-ceg-page.constants';
import { ObjectsIconConfig } from 'app/app.constants';
import { ManageTableCegRowBaseComponent } from '@manage-ceg/components/manage-table-ceg/components/manage-table-ceg-row-base';
import {
  BudgetAllocationActionTooltipContext
} from 'app/budget-allocation/components/budget-allocation-action-tooltip/budget-allocation-action-tooltip.component';
import {
  BudgetAllocationCellGesturesEvent
} from 'app/budget-allocation/components/budget-allocation-cell/budget-allocation-cell.types';
import { LocalStorageService } from '@common-lib/services/local-storage.service';
import { LAST_CREATED_OBJECT_ID } from '@shared/constants/storage.constants';
import { BudgetSegmentAccess } from '@shared/types/segment.interface';
import { SharedCostRule } from '@shared/types/shared-cost-rule.interface';
import { ManageTableRowType } from '@shared/enums/manage-table-row-type.enum';
import { ManageCegDataValidationService } from '@manage-ceg/services/manage-ceg-data-validation.service';
import { ManageCegTableDataService } from '@manage-ceg/services/manage-ceg-table-data.service';
import { CEGStatus } from '@shared/enums/ceg-status.enum';

@Component({
  selector: '[manage-table-ceg-row]',
  templateUrl: './manage-table-ceg-row.component.html',
  styleUrls: ['./manage-table-ceg-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    slideFromRightColumnAnimations
  ],
})
export class ManageTableCegRowComponent extends ManageTableCegRowBaseComponent implements OnInit, OnChanges, OnDestroy {
  @Input() record: ManageCegTableRow;
  @Input() allocations: ManageCegTableRowAllocations;
  @Input() presentationAllocations: ManageCegTableRowAllocations;
  @Input() level: number;
  @Input() parent: ManageCegTableRow;
  @Input() performanceColumnData: PerformanceColumnData;
  @Input() segmentBreakdownConfig: SegmentBreakdownConfig;
  @Input() editPermission: boolean;
  @Input() hasHiddenHierarchy: boolean;
  @Input() newItemCreationActive: boolean;
  @Input() iconByRowType: ObjectsIconConfig;
  @Input() routeActionByRowType: Record<ManageTableRowType, () => void>;
  @Input() objectTypeNameMap: Record<number, string>;
  @Input() togglingState: Record<string, boolean>;
  @Input() selectionState: ManageCegTableSelectionState;
  @Input() isAdmin: boolean;
  @Input() remainingBudget: number;
  @Input() freezeTotalsSidebarAnimations: boolean;
  @Input() showDataCells: boolean;
  @Input() selectedRecords: number[];
  @Input() contextMenuTargetId: string;
  @Input() segments: BudgetSegmentAccess[];
  @Input() sharedCostRules: SharedCostRule[];
  @Input() restrictedFromDrop: Record<string, boolean>;

  @Output() createNewItemTemplate = new EventEmitter<void>();
  @Output() createItemFromTemplate = new EventEmitter<string>();
  @Output() handlePerformanceClick = new EventEmitter<number>();
  @Output() handleToggleChange = new EventEmitter<boolean>();
  @Output() selectionChange = new EventEmitter<boolean>();
  @Output() onNameClick = new EventEmitter<void>();
  @Output() onEntityDragStart = new EventEmitter<ManageCegTableRow>();
  @Output() onEntityDragEnd = new EventEmitter<void>();
  @Output() onAllocationChange = new EventEmitter<ManageCegTableActionEvent>();
  @Output() onSegmentAllocationChange = new EventEmitter<ManageCegTableActionEvent>();
  @Output() onDoubleClick = new EventEmitter<ManageCegTableActionEvent>();
  @Output() onDrop = new EventEmitter<ManageCegTableActionEvent>();
  @Output() onDragStart = new EventEmitter<ManageCegTableActionEvent>();
  @Output() onDragEnd = new EventEmitter<ManageCegTableActionEvent>();
  @Output() onOpenContextMenu = new EventEmitter<CreateOverlayEvent>();
  @Output() onShowHierarchyTooltip = new EventEmitter<CreateOverlayEvent>();
  @Output() onHideHierarchyTooltip = new EventEmitter<void>();
  @Output() openExpenses = new EventEmitter<OpenExpensesEvent>();

  protected readonly manageTableRowType = ManageTableRowType;
  protected readonly labelByRowType = ManageTableRowTypeLabel;
  protected readonly tooltipContext = BudgetAllocationActionTooltipContext.Default;
  protected readonly segmentTooltipContext = BudgetAllocationActionTooltipContext.BudgetPage;
  protected readonly presentationTimeframe = PresentationTimeframe;
  protected readonly segmentBreakdownRow = SegmentBreakdownRow;
  private readonly destroy$ = new Subject<void>();

  public lastCreatedObjectId = null;
  @ViewChild('lastCreatedElementAnchor') private readonly lastCreatedElementAnchor: ElementRef;

  private readonly cdr = inject(ChangeDetectorRef);
  private readonly tableDataService = inject(ManageCegTableDataService);
  private readonly dataValidationService = inject(ManageCegDataValidationService);

  ngOnInit(): void {
    this.tableDataService.refreshTable$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(() => this.cdr.markForCheck());
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.record) {
      this.lastCreatedObjectId = LocalStorageService.getFromStorage(LAST_CREATED_OBJECT_ID);
      if (this.lastCreatedObjectId) {
        this.scrollToLastCreatedObject();
      }
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private scrollToLastCreatedObject(): void {
    setTimeout(() => {
      if (this.lastCreatedElementAnchor) {
        this.lastCreatedElementAnchor.nativeElement.scrollIntoView(
          {
            behavior: 'smooth',
            block: 'end'
          }
        );
        LocalStorageService.removeFromStorage(LAST_CREATED_OBJECT_ID);
      }
    }, 100);
  }

  protected allowDrag(item: ManageCegTableRow): boolean {
    return item.type !== ManageTableRowType.Goal
      && item.type !== ManageTableRowType.Segment
      && item.type !== ManageTableRowType.SegmentGroup
      && !!item.objectId
      && !item.isClosed;
  }

  public openContextMenu(record: ManageCegTableRow, event: MouseEvent): void {
    this.onOpenContextMenu.emit({ record, event });
  }

  public showHierarchyTooltip(record: ManageCegTableRow, event: MouseEvent): void {
    this.onShowHierarchyTooltip.emit({ record, event });
  }

  public hideHierarchyTooltip(): void {
    this.onHideHierarchyTooltip.emit();
  }

  // Smart cells handling
  public isCegDropAllowed(record: ManageCegTableRow): boolean {
    const draggableCellRowTypes = [ManageTableRowType.ExpenseGroup, ManageTableRowType.Campaign]; 
    const draggedRecord = this.dataValidationService.draggedRecord;

    //  This check allows us to move the available amount only horizontally for the committed rows
    if(draggableCellRowTypes.includes(draggedRecord?.type) && draggedRecord?.status === CEGStatus.COMMITTED) {
      return draggedRecord.id === record.id;
    }
    return !this.restrictedFromDrop[record.id];
  }

  public handleSegmentAllocationChange(amount: number, dataSource: ManageCegTableActionDataSource): void {
    const { timeframe, record } = dataSource;
    const prevValue = record.allocations[timeframe.id]?.[ManageTableBudgetColumnName.Budget]?.ownAmount;

    if (prevValue !== amount) {
      this.onSegmentAllocationChange.emit({
        amount,
        dataSource
      });
    }
  }

  public handleAllocationChange(amount: number, dataSource: ManageCegTableActionDataSource): void {
    const { timeframe, record } = dataSource;
    const prevValue = record.allocations[timeframe.id]?.[ManageTableBudgetColumnName.Budget]?.ownAmount;

    if (prevValue !== amount) {
      this.onAllocationChange.emit({
        amount,
        dataSource
      });
    }
  }

  public handleDoubleClick($event: BudgetAllocationCellGesturesEvent, dataSource: ManageCegTableActionDataSource): void {
    this.onDoubleClick.emit({
      gestureEvent: $event,
      dataSource
    });
  }

  public handleOnDragStart($event: BudgetAllocationCellGesturesEvent, dataSource: ManageCegTableActionDataSource): void {
    this.onDragStart.emit({
      gestureEvent: $event,
      dataSource
    });
  }

  public handleOnDrop($event: BudgetAllocationCellGesturesEvent, dataSource: ManageCegTableActionDataSource): void {
    this.onDrop.emit({
      gestureEvent: $event,
      dataSource
    });
  }

  public handleOnDragEnd(): void {
    this.onDragEnd.emit();
  }

  protected isActualCellClickable(
    cellName: ManageTableBudgetColumnName,
    value: number,
    timeframe: BudgetTimeframeBrief,
    rowKey: SegmentBreakdownRow
  ): boolean {
    return timeframe.isOriginal && rowKey !== SegmentBreakdownRow.campaignsAndPrograms && cellName === ManageTableBudgetColumnName.Actual && value != 0;
  }

  protected onActualClick(
    cellName: ManageTableBudgetColumnName,
    value: number,
    timeframe: BudgetTimeframeBrief,
    rowKey: SegmentBreakdownRow
  ): void {
    if (!this.isActualCellClickable(cellName, value, timeframe, rowKey)) {
      return;
    }
    this.openExpenses.emit({
      timeframeId: timeframe.id as number,
      withoutParent: rowKey === SegmentBreakdownRow.unallocated
    });
  }
}
