import { ChangeDetectorRef, inject, Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, combineLatest, forkJoin, merge, Observable, of, Subject, timer } from 'rxjs';
import { filter, map, switchMap, takeUntil, tap, catchError, skip, finalize, take } from 'rxjs/operators';
import { FilterName, FilterSet } from 'app/header-navigation/components/filters/filters.interface';
import { LightCampaign } from 'app/shared/types/campaign.interface';
import { Company } from 'app/shared/types/company.interface';
import { Budget, BudgetTimeframesType } from 'app/shared/types/budget.interface';
import { AppRoutingService } from 'app/shared/services/app-routing.service';
import { CompanyDataService, GLCode, Vendor } from 'app/shared/services/company-data.service';
import { BudgetDataService } from 'app/dashboard/budget-data/budget-data.service';
import { UtilityService } from 'app/shared/services/utility.service';
import { UserDataService } from 'app/shared/services/user-data.service';
import { FilterManagementService } from 'app/header-navigation/components/filters/filter-services/filter-management.service';
import { Configuration } from 'app/app.constants';
import { BudgetSegmentAccess } from 'app/shared/types/segment.interface';
import { ExpenseTableDataService } from './expense-table-data.service';
import { SpendingSidebarService } from './spending-sidebar.service';
import {
  BudgetObjectsData,
  SidebarObjectTypes,
  ExpensePageBudgetData,
  ExpensePageCompanyData,
  HierarchyViewMode,
  SidebarHierarchyOption,
  NOT_SPECIFIED_ID,
  SpendingManagementMode,
  LocalSpendingPageFilters,
  BudgetSegmentsData
} from '../types/expense-page.type';
import { LocationService } from '../../budget-object-details/services/location.service';
import { SegmentGroup } from '@shared/types/segment-group.interface';
import { SharedCostRule } from '@shared/types/shared-cost-rule.interface';
import { ObjectAccessManagerService } from '@shared/services/object-access-manager.service';
import { BudgetTimeframe } from '@shared/types/timeframe.interface';
import { ExpensesService } from '@shared/services/backend/expenses.service';
import { ExpenseActionsService } from '@spending/services/expense-actions.service';
import { ExpensesQueryService } from '@spending/services/expenses-query.service';
import { SpendingLocalFiltersService } from '@spending/services/spending-local-filters.service';
import { BudgetObjectType } from '@shared/types/budget-object-type.interface';
import { UserManager } from 'app/user/services/user-manager.service';
import { LightProgram, ProgramDO } from '@shared/types/program.interface';
import { HierarchySelectItem } from '@shared/components/hierarchy-select/hierarchy-select.types';
import { ExpensePageDrawerService } from '@spending/services/expense-page-drawer.service';
import { BudgetObjectCreationContext } from '../../budget-object-details/types/details-creation-context.interface';
import { SegmentMenuHierarchyService } from '@shared/services/segment-menu-hierarchy.service';
import { ExpenseTotalsDO } from '@shared/types/expense.interface';
import { ExpenseActionType } from '@spending/types/expense-action.type';
import { BudgetObjectService } from '@shared/services/budget-object.service';
import { ExpensePageSelectionService, SelectableSpendingRow } from '@spending/services/expense-page-selection.service';
import { SpendingMiniDashSummaryService } from '@spending/services/spending-mini-dash-summary.service';
import { ActiveToast } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { BudgetObjectDetailsManager } from '../../budget-object-details/services/budget-object-details-manager.service';
import { ExpensePageSortByService } from '@spending/services/expense-page-sort-by.service';
import { InvoiceTableDataService } from '@spending/services/invoice-table-data.service';
import { SpendingModeService } from '@spending/services/spending-mode.service';
import { InvoiceLiveTracking } from '@shared/services/invoice-live-tracking';
import { InvoicePageSortByService } from '@spending/services/invoice-page-sort-by.service';
import { CompanyUserDO } from '@shared/types/company-user-do.interface';
import { ExpensesSummaryGroup } from '@shared/types/expenses-summary.type';
import { InvoiceUploadManagerService } from '@spending/services/invoice-upload-manager.service';
import { SegmentedObject } from '@shared/types/segmented-budget-object';
import { SharedCostRulesService} from '@shared/services/backend/shared-cost-rules.service';
import { ProgramService } from '@shared/services/backend/program.service';
import { ExpenseTableColumnsService } from '@spending/services/expense-table-columns.service';
import { BudgetObjectOwnersService } from '../../budget-object-details/services/budget-object-owners.service';
import { BudgetObjectSourceLabels } from '../../budget-object-details/types/budget-object-details-state.interface';
import { CustomFieldFiltersSummaryService } from 'app/header-navigation/components/filters/filter-services/custom-field-filter-summary.service';

@Injectable()
export class SpendingService implements OnDestroy {
  private readonly appRoutingService = inject(AppRoutingService);
  private readonly companyDataService = inject(CompanyDataService);
  private readonly budgetDataService = inject(BudgetDataService);
  private readonly budgetObjectDetailsManager = inject(BudgetObjectDetailsManager);
  private readonly expensesService = inject(ExpensesService);
  private readonly programService = inject(ProgramService);
  private readonly utilityService = inject(UtilityService);
  private readonly configuration = inject(Configuration);
  private readonly filterManagementService = inject(FilterManagementService);
  private readonly objectAccessManager = inject(ObjectAccessManagerService);
  private readonly userDataService = inject(UserDataService);
  private readonly userManager = inject(UserManager);
  private readonly locationService = inject(LocationService);
  private readonly expensesTableDataService = inject(ExpenseTableDataService);
  private readonly invoiceTableDataService = inject(InvoiceTableDataService);
  private readonly spendingSidebarService = inject(SpendingSidebarService);
  private readonly expenseActionsService = inject(ExpenseActionsService);
  private readonly expensesQueryService = inject(ExpensesQueryService);
  private readonly spendingLocalFiltersService = inject(SpendingLocalFiltersService);
  private readonly expensePageDrawerService = inject(ExpensePageDrawerService);
  private readonly segmentMenuService = inject(SegmentMenuHierarchyService);
  private readonly expenseSortByService = inject(ExpensePageSortByService);
  private readonly invoiceSortByService = inject(InvoicePageSortByService);
  private readonly expensePageSelectionService = inject(ExpensePageSelectionService);
  private readonly spendingMiniDashSummaryService = inject(SpendingMiniDashSummaryService);
  private readonly router = inject(Router);
  private readonly spendingModeService = inject(SpendingModeService);
  private readonly invoiceLiveTracking = inject(InvoiceLiveTracking);
  private readonly cdr = inject(ChangeDetectorRef);
  private readonly invoiceUploadManager = inject(InvoiceUploadManagerService);
  private readonly sharedCostRulesService = inject(SharedCostRulesService);
  private readonly expenseTableColumnsService = inject(ExpenseTableColumnsService);
  private readonly activatedRoute = inject(ActivatedRoute);
  private readonly customFieldFiltersSummaryService = inject(CustomFieldFiltersSummaryService);

  public tableDataLoading$ = new BehaviorSubject<boolean>(false);
  private destroy$ = new Subject<void>();
  private isPowerUser: boolean;
  private currentFilters: FilterSet;
  public readOnlyMode: boolean;
  private selectedSingleObject: {[key: string]: any } = {};
  public sideBarHierarchyMode: HierarchyViewMode;
  public sidebarSelection: any[] = [];
  private _reloadTable$ = new Subject<void>();
  public reloadTable$ = this._reloadTable$.asObservable();
  private _reloadGrandTotalValues$ = new Subject<void>();
  public reloadGrandTotalValues$ = this._reloadGrandTotalValues$.asObservable();
  public sidebarSelectionQuery$ = new BehaviorSubject<Record<string, string>>(null);

  public budget: Budget;
  private companyId: number;

  private _timeframes: BudgetTimeframe[];
  private lastProcessedTimeframes: BudgetTimeframe[];
  public segments: BudgetSegmentAccess[];
  public segmentGroups: SegmentGroup[];
  public sharedCostRules: SharedCostRule[] = [];
  public companySharedCostRules: SharedCostRule[] = [];

  private _dragExpenses: SelectableSpendingRow[];
  private backNavToast: ActiveToast<any>;
  private currentInvoiceCount: number;
  public initialInvoiceCount: number = null; // initial number of invoices in current budget (used for Well-done message)
  private initedOnInvoicesPage: boolean;
  public hasInvoiceAccess = false;
  private skipSelectionClear = false;
  public statuslessExpenses: boolean;

  constructor() {
    this.appRoutingService.setInitFiltersForHistoryNavigation();
    this.initedOnInvoicesPage = this.router.routerState.snapshot.url.indexOf('/spending/invoices') === 0;

    this.initSubscriptions();
    this.initDataLoading();

    merge(this.activatedRoute.url, this.activatedRoute.queryParams)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.initBackNavToast());
  }

  private initBackNavToast(): void {
    const prevLocation = this.router.getCurrentNavigation().extras.state?.backNavToastFor;
    if (prevLocation) {
      if (this.backNavToast) {
        this.hideBackNavToast();
      }
      this.backNavToast = this.utilityService.showToastrNav(prevLocation);
    }
  }

  private initSubscriptions() {
    this.invoiceLiveTracking.hasInvoiceAccess$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(hasAccess => {
      this.hasInvoiceAccess = hasAccess;
      this.checkInvoiceModeAccess();
    });

    this.invoiceUploadManager.updatePageData$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(() => {
      this.updateInvoiceCount();

      // Update segment list if no default segment already exists
      const hasDefaultSegment = !!this.segments.find(segment => segment.name === this.configuration.defaultSegmentName);
      if (!hasDefaultSegment) {
        this.skipSelectionClear = true;
        this.budgetDataService.loadAvailableBudgetSegments(this.budget.id);
      }
    });

    this.companyDataService.selectedCompanyDO$
      .pipe(
        filter(cmp => cmp != null),
        takeUntil(this.destroy$)
      ).subscribe(companyDO => {
      this.spendingMiniDashSummaryService.updateCurrency(companyDO.currency);
    });

    this.invoiceLiveTracking.invoiceTotalCount$
      .pipe(
        takeUntil(this.destroy$)
      ).subscribe(count => {
        if (this.initialInvoiceCount === null) {
          // get only first value for each budget
          this.initialInvoiceCount = count;
        }
        if (this.currentInvoiceCount !== count) {
          if (
            this.isInvoiceMode &&
            this.currentInvoiceCount !== undefined && this.sidebarSelection.length
          ) {
            // new invoice was uploaded to the budgi system
            this.refreshTableDataAndTotals();
          }
          this.currentInvoiceCount = count;
          this.updateSwitcherInvoiceCount(count);
        }
    });

    this.userDataService.editPermission$
      .pipe(takeUntil(this.destroy$))
      .subscribe(editPermission => this.readOnlyMode = !editPermission);

    this.userManager.currentCompanyUser$.pipe(
      filter(user => !!user),
      tap(user =>
        this.isPowerUser = this.userDataService.isPowerUser(user)
      )
    );

    combineLatest([
      this.expensePageSelectionService.rowSelection$,
      this.expensePageSelectionService.isTotalSelected$
    ]).pipe(takeUntil(this.destroy$))
      .subscribe(([ rowSelection, isTotalSelected ]) => {
        if (isTotalSelected) {
          this.spendingMiniDashSummaryService.updateSelectedExpensesSummary({}, isTotalSelected);
        } else {
          const selectedExpenses = Object.values(rowSelection).map(row => row.expenseObject);
          this.expenseActionsService.onSelectedExpensesUpdate(selectedExpenses);
          this.spendingMiniDashSummaryService.updateSelectedExpensesSummary(rowSelection, isTotalSelected);
        }
      });

    combineLatest([
      this.expenseSortByService.sortByField$,
      this.invoiceSortByService.sortByField$
    ]).pipe(
      takeUntil(this.destroy$)
    ).subscribe(([ expenseSortBy, invoiceSortBy ]) => {
      this.spendingLocalFiltersService.sortBy = this.isInvoiceMode ? invoiceSortBy : expenseSortBy;
    });

    this.budgetObjectDetailsManager.budgetObjectChanged$
      .pipe(
        filter(data =>
          data.objectType === this.configuration.OBJECT_TYPES.expense || data.objectType === this.configuration.OBJECT_TYPES.program
        ),
        takeUntil(this.destroy$),
      ).subscribe(data => {
        this.invoiceUploadManager.resetState();
        this.refreshTableDataAndTotals();

        if (data.objectType === this.configuration.OBJECT_TYPES.program) {
          this.updateProgramSnapshot();
        }
      });

    this.filterManagementService.customFilterModeChanged$
      .pipe(
        takeUntil(this.destroy$)
      ).subscribe(() => this.refreshTableDataAndTotals());

    this.spendingModeService.spendingDataMode$
      .pipe(
        takeUntil(this.destroy$),
        tap(mode => {
          this.spendingMiniDashSummaryService.updateGroupsForActiveMode(mode);
        }),
        skip(1)
      ).subscribe(() => {
      this.updateGrandTotals();
      this.updateSidebarCounts();
    });

    this.expenseActionsService.programsCreated$
      .pipe(
        takeUntil(this.destroy$)
      ).subscribe(() =>
        this.updateProgramSnapshot()
      );
  }

  private updateProgramSnapshot(): void {
    this.budgetDataService.loadLightPrograms(
      this.companyId,
      this.budget.id,
      this.configuration.programStatusNames.active,
      error => this.utilityService.handleError(error)
    );
  }

  private initDataLoading() {
    this.spendingSidebarService.setIsLoading(true);

    const company$ = this.companyDataService.selectedCompany$
      .pipe(
        filter(cmp => cmp != null),
        tap(company => this.onSelectedCompanyChanged(company))
      );

    const companyData$ =
      combineLatest([
        this.companyDataService.glCodeList$.pipe(
          tap(glCodes => this.onGlCodesLoaded(glCodes))
        ),
        this.companyDataService.vendorList$.pipe(
          take(1),
          tap(vendors => this.onVendorsLoaded(vendors)),
        ),
        this.companyDataService.expenseTypeList$.pipe(
          tap(expenseTypes => this.onExpenseTypesLoaded(expenseTypes))
        )
      ]).pipe(
        map(([glCodes, vendors]) => ({ glCodes, vendors }))
      );

    const budgetData$ =
      this.budgetDataService.selectedBudget$.pipe(
        tap(budget => {
          this.onSelectedBudgetChanged(budget);
          this.invoiceUploadManager.resetState();
          this.invoiceUploadManager.invoiceUploadContext = {
            userId: this.userManager.getCurrentUser().id,
            companyId: this.companyId,
            budgetId: this.budget.id
          };
        }),
        switchMap(() => this.userManager.currentCompanyUser$),
        switchMap(
          companyUser => combineLatest([
            this.filterManagementService.budgetFiltersInit$.pipe(
              switchMap(() => this.filterManagementService.currentFilterSet$),
              tap(filterSet => this.onCurrentFiltersChange(filterSet))
            ),
            this.getExpensePageBudgetData$(companyUser)
          ])
        )
      );

    const dataLoading$ = company$.pipe(
      catchError(error => {
        this.utilityService.handleError(error);
        this.spendingSidebarService.setIsLoading(false);
        return dataLoading$;
      }),
      switchMap(
        () => combineLatest([
          companyData$,
          budgetData$,
          this.spendingSidebarService.hierarchyMode$.pipe(
            tap(mode => {
              this.sideBarHierarchyMode = mode;
              if (!this.skipSelectionClear) {
                this.expensePageSelectionService.clearSelection();
              }
            })
          )
        ])
      ),
      switchMap(
        ([companyData, [filters, budgetData], sideBarHierarchyMode]) => {
          if (this.appRoutingService.isDrawerOpen()) {
            this.appRoutingService.closeActiveDrawer();
          }
          this._reloadGrandTotalValues$.next();
          return this.loadSidebarHierarchyData$(companyData, budgetData, sideBarHierarchyMode).pipe(
            map(() => [sideBarHierarchyMode, filters]),
            tap(() => {
              if (this.initedOnInvoicesPage) {
                this.initedOnInvoicesPage = false;
                this.spendingSidebarService.setAllSelected(true);
              }
              if (this.spendingSidebarService.hasSelection) {
                this.spendingSidebarService.validateMarkedAsSelected();
                const isExpenseCFFilterSelected =  Object.keys(this.filterManagementService.getCurrentSelectedCustomFieldFilterSet()).length > 0;
                if(!isExpenseCFFilterSelected) { 
                  this.refreshTableDataAndTotals(true);
                 }
              }
              this.skipSelectionClear = false;
            })
          );
        }
      ),
      switchMap(
        ([sideBarHierarchyMode, filters]) => this.spendingSidebarService.selectedSidebarOptionsMap$.pipe(
          tap(selectedOptionsMap => {
            this.sidebarSelection = Object.values(selectedOptionsMap) || [];
            this.selectedSingleObject = this.sidebarSelection.length === 1 ? this.sidebarSelection[0] : null;

            // If corresponding filter applied, click by 'no {Entity}' option should return empty response
            if (
              this.selectedSingleObject?.id === 0
                && ((filters[FilterName.GlCodes]?.length && sideBarHierarchyMode === HierarchyViewMode.GlCode)
                || (filters[FilterName.Campaigns]?.length && sideBarHierarchyMode === HierarchyViewMode.Campaign)
                || (filters[FilterName.Vendors]?.length && sideBarHierarchyMode === HierarchyViewMode.Vendor)
                || (filters[FilterName.Goals]?.length && sideBarHierarchyMode === HierarchyViewMode.Goal))
            ) {
              this.selectedSingleObject.id = this.configuration.NEGATIVE_ID;
            }
          }),
          map(selectedSidebarOptionsMap => [
            Object.values(selectedSidebarOptionsMap), sideBarHierarchyMode, filters
          ])
        )
      ),
      takeUntil(this.destroy$),
    ).subscribe(([selected, mode]: [SidebarHierarchyOption[], HierarchyViewMode]) => {
      this.sidebarSelectionQuery$.next(this.createQueryFromSelection(selected, mode));
    });
  }

  public checkInvoiceModeAccess(): void {
    if (!this.hasInvoiceAccess && this.spendingModeService.spendingManagementMode === SpendingManagementMode.Invoices) {
      this.initedOnInvoicesPage = false;
      this.spendingModeService.openSpendingManagementMode(SpendingManagementMode.Expenses);
    }
  }

  private updateSwitcherInvoiceCount(count = 0): void {
    const invoiceBtn = this.spendingModeService.dataModeOptions.find(mode => mode.value === SpendingManagementMode.Invoices);
    invoiceBtn.suffix = count !== null ? `(${count.toString()})` : '';
    this.spendingModeService.dataModeOptions = [ ...this.spendingModeService.dataModeOptions ];
    this.cdr.markForCheck();
  }

  private createQueryFromSelection(selected: SidebarHierarchyOption[], mode: HierarchyViewMode): Record<string, string> {
    if (!selected.length) {
      return null;
    }

    const filterToQueryMap = {
      [SidebarObjectTypes.Segment]: 'company_budget_segment1_ids',
      [SidebarObjectTypes.Campaign]: 'campaign_ids',
      [SidebarObjectTypes.ExpenseGroup]: 'program_ids',
      [SidebarObjectTypes.Goal]: 'goal_ids',
      [SidebarObjectTypes.GlCode]: 'gl_code_ids',
      [SidebarObjectTypes.Source]: 'source',
      [SidebarObjectTypes.Status]: 'modes',
      [SidebarObjectTypes.Vendor]: 'vendor_ids',
      [SidebarObjectTypes.Timeframe]: 'company_budget_allocation_ids',
    };

    if (this.spendingSidebarService.isAllSelectedValue) {
      // should not use filtering in case selectAll is active
      return {};
    }

    const selectionObject: Record<string, number[]> = selected.reduce((store, item) => {
      const isSegmentGroup = item.objectType === SidebarObjectTypes.SegmentGroup;
      const objectType = isSegmentGroup ? SidebarObjectTypes.Segment : item.objectType;
      if (!store[objectType]) {
        store[objectType] = [];
      }
      const ids = isSegmentGroup ? item.groupSegmentIds : [item.id];
      store[objectType].push(...ids);
      return store;
    }, {});

    if (mode === HierarchyViewMode.Segment) {
      if (selected.length === 1 &&
        (selectionObject[SidebarObjectTypes.Campaign] ||
          selectionObject[SidebarObjectTypes.ExpenseGroup])) {
        // single Campaign or ExpenseGroup selected in Segment mode
        const selectedObject = selected[0];
        selectionObject[SidebarObjectTypes.Segment] = [ selectedObject.segmentId ];
        if (selectedObject.sharedCostRuleId) {
          const objectId = (selectedObject.id as string).split('_')[1];
          selectionObject[selectedObject.objectType] = [ +objectId ];
        }
      }
    }

    // no Campaign is selected in Campaign mode
    if (mode === HierarchyViewMode.Campaign && selected[0].id === NOT_SPECIFIED_ID) {
      selectionObject[SidebarObjectTypes.ExpenseGroup] = [ NOT_SPECIFIED_ID ];
    }

    return Object.entries(selectionObject).reduce((store, [key, values]) => {
      if (values.length) {
        store[filterToQueryMap[key]] = values.join(',');
      }
      return store;
    }, {});
  }

  public get isActionInProgress$(): Observable<boolean> {
    return this.expenseActionsService.isActionInProgress$;
  }

  public get hierarchyIsAllSelected(): boolean {
    return this.spendingSidebarService.isAllSelectedValue;
  }

  public get selectedHierarchyModeValue(): HierarchyViewMode {
    return this.spendingSidebarService.hierarchyModeValue;
  }

  public get sidebarLoading$(): Observable<boolean> {
    return this.spendingSidebarService.isLoading$;
  }

  public get isFilteredBySegment(): boolean {
    return this.sideBarHierarchyMode === HierarchyViewMode.Segment || !!this.currentFilters[FilterName.Segments]?.length;
  }

  public updateInvoiceCount(): void {
    this.invoiceLiveTracking.restartTracking();
  }

  private getExpensePageBudgetData$(companyUser: CompanyUserDO): Observable<ExpensePageBudgetData> {
    const allBudgetObjects$ =
      combineLatest([
        this.budgetDataService.goalList$,
        this.budgetDataService.lightCampaignList$,
        this.budgetDataService.lightProgramList$,
      ]).pipe(
        map(([goals, campaigns, expGroups]) => ({ goals, campaigns, expGroups }))
      );

    const isPowerUser = this.userDataService.isPowerUser(companyUser);

    const getFilteredByAccess =
      <T extends SegmentedObject>(
        objects: T[],
        segments: BudgetSegmentAccess[],
        sharedCostRules: SharedCostRule[]
      ) => {
      return isPowerUser ?
        objects :
        objects
          ?.filter(obj =>
            this.objectAccessManager.hasAccessBySegmentData(
              { split_rule: obj.splitRuleId, company_budget_segment1: obj.budgetSegmentId },
              segments,
              sharedCostRules
            )
          );
    };

    const budgetSegments$ =
      combineLatest([
        this.budgetDataService.segmentList$.pipe(tap(segments => this.segments = segments)),
        this.budgetDataService.segmentGroupList$,
        this.budgetDataService.sharedCostRuleList$.pipe(tap(sharedCostRules => this.sharedCostRules = sharedCostRules)),
      ]).pipe(
        map(([segments, segmentGroups, sharedCostRules]) => {
          return {
            segments,
            segmentGroups,
            sharedCostRules,
            allowedSharedCostRules: this.objectAccessManager.getAllowedSharedCostRules(
              sharedCostRules,
              segments
            )
          };
        })
      );

    const budgetHierarchyData$ =
      combineLatest([
        allBudgetObjects$,
        budgetSegments$
      ]).pipe(
        map(([objectsData, segmentsData]) => {
          return {
            segmentsData,
            objectsData: {
              goals: objectsData?.goals,
              campaigns: getFilteredByAccess(objectsData?.campaigns, segmentsData?.segments, segmentsData?.sharedCostRules),
              expGroups: getFilteredByAccess(objectsData?.expGroups, segmentsData?.segments, segmentsData?.sharedCostRules)
            }
          };
        }),
        tap(({ objectsData, segmentsData }) => {
          this.setManageMenuHierarchySelectItems(objectsData, segmentsData);
        })
      );

    const budgetTimeframes$ =
      this.budgetDataService.timeframeList$.pipe(
        filter(tf => tf !== this.lastProcessedTimeframes),
        tap(timeframes => this.onBudgetTimeframesChange(timeframes))
    );

    return combineLatest([budgetHierarchyData$, budgetTimeframes$]).pipe(
      map(([budgetHierarchyData, budgetTimeframes]) => ({ budgetHierarchyData, budgetTimeframes }))
    );
  }

  private onBudgetTimeframesChange(timeframes: BudgetTimeframe[]) {
    this.timeframeList = this.lastProcessedTimeframes = timeframes;

    this.expenseActionsService.setOptionsForSelect(
      ExpenseActionType.ChangeTimeframe,
      (timeframes || []).filter(tf => !tf.locked).map(tf => ({ value: tf.id, title: tf.shortName }))
    );

    this.expenseActionsService.setOptionsForSelect(
      ExpenseActionType.MoveToBudget,
      (this.budgetDataService.budgetListSnapshot || [])
        .filter(budget => budget.id !== this.budget.id)
        .map(budget => ({
          value: budget.id,
          title: budget.name,
          context: { extraProps: { status: budget.status } }
        }))
    );
  }

  public get dragExpenses(): SelectableSpendingRow[] {
    return this._dragExpenses;
  }

  public set dragExpenses(expenses: SelectableSpendingRow[]) {
    this._dragExpenses = expenses;
  }

  private loadSidebarHierarchyData$(
    companyData: ExpensePageCompanyData,
    budgetData: ExpensePageBudgetData,
    sideBarHierarchyMode: HierarchyViewMode
  ): Observable<BudgetObjectsData> {
    this.spendingSidebarService.setIsLoading(true);
    return this.getObjectsForCurrentSidebarMode$(budgetData, sideBarHierarchyMode).pipe(
      switchMap(budgetObjectsForCurrentSidebarMode =>
        this.spendingSidebarService.updateExpenseCounts(this.getExpensesQueryObject()).pipe(
          tap(() => {
            this.spendingSidebarService.updateViewOptions(companyData, budgetData, budgetObjectsForCurrentSidebarMode);
          }),
          map(() => budgetObjectsForCurrentSidebarMode)
        )
      ));
  }

  private setManageMenuHierarchySelectItems(objectsData: BudgetObjectsData, segmentsData: BudgetSegmentsData): void {
    const parentSelectItems =
      this.getChangeParentOptions(
        objectsData.campaigns, objectsData.expGroups, segmentsData.segments, segmentsData.allowedSharedCostRules
      );
    const segmentSelectItems = this.segmentMenuService.prepareDataForSegmentMenu({
      segments: segmentsData.segments,
      groups: segmentsData.segmentGroups,
      rules: segmentsData.allowedSharedCostRules,
    });
    this.expenseActionsService.setHierarchySelectOptions(ExpenseActionType.ChangeParent, parentSelectItems);
    this.expenseActionsService.setHierarchySelectOptions(ExpenseActionType.ChangeSegment, segmentSelectItems);
  }

  private getChangeParentOptions(
    campaigns: LightCampaign[], programs: LightProgram[], segments: BudgetSegmentAccess[], rules: SharedCostRule[]
  ): HierarchySelectItem[] {
    const { items: locationItems } = this.locationService.createLocationHierarchyItems({
      goals: [],
      campaigns,
      programs,
      currentLocation: null,
      segments,
      rules,
      isPowerUser: this.isPowerUser
    }, false, true);

    return locationItems
      .filter(item => !item.notClickable || item.children.length)
      .map(option => ({ ...option, value: option.id }));
  }

  private getExpensesQueryObject() {
    const invoicesOnly = this.isInvoiceMode;
    const includePseudoObjects = this.isViewBySegment && !invoicesOnly;
    const localParams = {
      search: this.spendingLocalFiltersService.localFilters.search
    } as unknown as LocalSpendingPageFilters;

    return this.expensesQueryService.getExpenseBasePayload(localParams, true, includePseudoObjects, invoicesOnly);
  }

  public refreshTableDataAndTotals(resetPagination = false): void {
    if (resetPagination) {
      this.spendingLocalFiltersService.resetPagination();
      this._reloadGrandTotalValues$.next();
      return;
    }
    this._reloadGrandTotalValues$.next();
    this._reloadTable$.next();
    this.updateSidebarCounts();
  }

  public updateSidebarCounts(): void {
    const queryObject = this.getExpensesQueryObject();
    this.spendingSidebarService.updateExpenseCounts(queryObject)
      .pipe(takeUntil(this.destroy$))
      .subscribe();
  }

  public updateGrandTotals(): void {
    this.getGrandTotal()
      .pipe(takeUntil(this.destroy$))
      .subscribe();
  }

  private get isViewBySegment(): boolean {
    return this.sideBarHierarchyMode === HierarchyViewMode.Segment;
  }

  private get isInvoiceMode(): boolean {
    return this.spendingManagementMode === SpendingManagementMode.Invoices;
  }

  public getGrandTotal(): Observable<ExpenseTotalsDO> {
    if (!this.budget.id || !this.budgetDataService.segmentsSnapshot) {
      console.error('budgetDataService.segmentsSnapshot doesn\'t ready yet');
      return;
    }
    const params = {
      company: this.companyId.toString(),
      budget_id: this.budget.id.toString(),
      status: 'Active',
      include_nested: true,
      include_pseudo_objects: this.isViewBySegment && !this.isInvoiceMode,
      company_budget_segment1_ids: this.budgetDataService.segmentsSnapshot.map(segment => segment.id).join(','),
    };
    if (this.isInvoiceMode) {
      params['is_verified'] = false;
    }

    this.spendingMiniDashSummaryService.grandTotalsLoading$.next(true);
    return this.expensesService.getExpensesTotalAmount(params).pipe(
      finalize(() => {
        setTimeout(() => {
          this.spendingMiniDashSummaryService.grandTotalsLoading$.next(false);
        }, 400);
      }),
      tap(totalWithoutFilters => {
        this.onExpensesAmountLoaded(ExpensesSummaryGroup.Grand, totalWithoutFilters);
      })
    );
  }

  public getViewedTotal(): Observable<ExpenseTotalsDO> {
    if (!this.budget.id) {
      return;
    }
    const params = this.getExpenseBasePayload(this.isInvoiceMode);
    // total's endpoint uses "budget_id" instead "budget"
    delete params.budget;
    params['budget_id'] = this.budget.id.toString();

    this.spendingMiniDashSummaryService.viewedTotalsLoading$.next(true);

    if(params['custom_fields']) {

    // Wait for Expense Filter Results and count and return the Summary Total From Subject 
    return timer(100).pipe(
      map(() => this.customFieldFiltersSummaryService.getExpenseCustomFieldFiltersSummaryTotal()), 
      finalize(() => {
        setTimeout(() => {
          this.spendingMiniDashSummaryService.viewedTotalsLoading$.next(false);
        }, 400);
      }),
      tap(totalWithFilters => {
        this.onExpensesAmountLoaded(ExpensesSummaryGroup.Filtered, totalWithFilters);
      })
    );

  }
    
    return this.expensesService.getExpensesTotalAmount(params).pipe(
      finalize(() => {
        setTimeout(() => {
          this.spendingMiniDashSummaryService.viewedTotalsLoading$.next(false);
        }, 400);
      }),
      tap(totalWithFilters => {
        this.onExpensesAmountLoaded(ExpensesSummaryGroup.Filtered, totalWithFilters);
      })
    );
  }

  public onExpensesAmountLoaded(groupType: ExpensesSummaryGroup, data: ExpenseTotalsDO) {
    const count = !data ? null :
      this.isViewBySegment ? (data.total_count_with_pseudo_objects || data.total_count) : data.total_count;
    const amount = data?.total_amount || null;
    this.spendingMiniDashSummaryService.setExpensesSummaryForGroup(groupType, count, amount);
  }

  public getExpenseBasePayload(invoicesOnly: boolean): {[key: string]: string | number | boolean} {
    const localParams = this.spendingLocalFiltersService.localFilters;
    const sidebarSelectionQuery = this.sidebarSelectionQuery$.getValue() || {};
    const sideBarHierarchyMode = this.sideBarHierarchyMode;
    const includeNested = sideBarHierarchyMode === HierarchyViewMode.Campaign
      || sideBarHierarchyMode === HierarchyViewMode.Goal
      || (sideBarHierarchyMode === HierarchyViewMode.Segment && !!sidebarSelectionQuery['campaign_ids']);
    const includePseudoObjects = sideBarHierarchyMode === HierarchyViewMode.Segment && !this.hierarchyIsAllSelected && !invoicesOnly;

    const basePayload = this.expensesQueryService.getExpenseBasePayload(localParams, includeNested, includePseudoObjects, invoicesOnly);
    return { ...basePayload, ...sidebarSelectionQuery };
  }

  public getFilteredExpenseIds(): Observable<number[]> {
    const queryObject = this.getExpenseBasePayload(this.isInvoiceMode);

    return this.expensesService.getExpenseIds(queryObject);
  }

  private getObjectsForCurrentSidebarMode$(
    budgetData: ExpensePageBudgetData,
    sideBarHierarchyMode: HierarchyViewMode
  ): Observable<BudgetObjectsData> {
    const { objectsData, segmentsData } = (budgetData?.budgetHierarchyData || {});
    const { campaignStatusNames, programStatusNames } = this.configuration;
    const budgetId = this.budget.id;

    if (sideBarHierarchyMode === HierarchyViewMode.Segment) {
      const requestParams = {
        company_budget_segment1_ids: (segmentsData.segments || [])?.map(segment => segment.id).join(','),
        include_pseudo_objects: true
      };
      return forkJoin([
        this.budgetDataService.getLightCampaigns(this.companyId, budgetId, campaignStatusNames.active, requestParams),
        this.budgetDataService.getLightPrograms(this.companyId, budgetId, programStatusNames.active, requestParams)
      ]).pipe(
        map(resp => ({
          goals: [...(objectsData?.goals || [])],
          campaigns: resp[0],
          expGroups: resp[1],
        }))
      );
    }

    return of({
      goals: [...(objectsData?.goals || [])],
      campaigns: [...(objectsData?.campaigns || [])],
      expGroups: [...(objectsData?.expGroups || [])]
    });
  }

  private onCurrentFiltersChange(filterSet: FilterSet) {
    if (!FilterManagementService.hasSelectedFilters(filterSet)) {
      this.hideBackNavToast();
    }
    this.currentFilters = { ...filterSet };
    this.appRoutingService.updateCurrentFiltersInRouting(
      this.companyId,
      this.budget?.id,
      this.currentFilters
    );
  }

  public set timeframeList(timeframes: BudgetTimeframe[]) {
    const monthlyBudget = this.budget.type === BudgetTimeframesType.Month;
    const shortNamesArr = monthlyBudget ? this.configuration.month : this.configuration.quarter;
    let shortNamesOrdered;

    const [startFromYear, startFromMonth] = this.budget.budget_from.split('-');
    if (monthlyBudget && startFromMonth !== '01') {
      const startInd = +startFromMonth - 1;
      const beforeStart = shortNamesArr.slice(0, startInd);
      const afterStart = shortNamesArr.slice(startInd, 12);
      shortNamesOrdered = [...afterStart, ...beforeStart];
    } else {
      shortNamesOrdered = shortNamesArr;
    }
    const firstMonthOrder = monthlyBudget ? shortNamesOrdered.indexOf(shortNamesArr[0]) : null;
    const getTfYear = (timeframe: BudgetTimeframe) => {
      if (!monthlyBudget) {
        return null;
      }
      return firstMonthOrder === 0 || timeframe.order < firstMonthOrder ? +startFromYear : (+startFromYear + 1);
    };
    this._timeframes = timeframes.map(tf => {
      tf.year = getTfYear(tf);
      tf.shortName = shortNamesOrdered[tf.order];
      return tf;
    });
    this.invoiceTableDataService.timeframes = this._timeframes;
  }

  public get timeframeList(): BudgetTimeframe[] {
    return this._timeframes;
  }

  ngOnDestroy(): void {
    this.hideBackNavToast();
    this.destroy$.next();
    this.destroy$.complete();
  }

  private onSelectedBudgetChanged(budget: Budget) {
    if (this.statuslessExpenses !== budget.new_campaigns_programs_structure) {
      this.statuslessExpenses = budget.new_campaigns_programs_structure;
      this.expenseTableColumnsService.updateVisibilityForStatuslessChanges(this.statuslessExpenses);
      this.expenseSortByService.updateItemsForStatuslessChanges(this.statuslessExpenses);
      this.expenseActionsService.setStatuslessExpensesFlag(this.statuslessExpenses);
    }
    this.initialInvoiceCount = null;
    if (budget?.id !== this.budget?.id) {
      // Load budget related data (for filters)
      this.loadBudgetObjects(budget.id);
    }
    this.budget = budget;
    this.updateOwnerOptionsList();
  }

  private onSelectedCompanyChanged(company: Company) {
    this.companyId = company.id;
    this.getCompanySharedCostRules(this.companyId);
    this.companyDataService.loadCompanyData(this.companyId);
  }

  private getCompanySharedCostRules(companyId: number): void {
    this.sharedCostRulesService.getAllRules({ company: companyId, is_active: true }, true)
      .pipe(takeUntil(this.destroy$))
      .subscribe((rules: SharedCostRule[]) => {
        this.companySharedCostRules = rules;
      });
  }

  private loadBudgetObjects(budgetId: number) {
    if (!budgetId || !this.companyId) {
      return;
    }

    this.budgetDataService.loadLightCampaigns(
      this.companyId,
      budgetId,
      this.configuration.campaignStatusNames.active,
      error => this.utilityService.handleError(error),
    );

    this.budgetDataService.loadLightPrograms(
      this.companyId,
      budgetId,
      this.configuration.programStatusNames.active,
      error => this.utilityService.handleError(error)
    );
  }

  public get isAddingExpenseAllowed(): boolean {
    if (this.selectedSingleObject?.objectType === SidebarObjectTypes.Campaign) {
      return !this.selectedSingleObject?.segmentId && !this.selectedSingleObject?.sharedCostRuleId;
    }
    if (this.selectedSingleObject?.objectType === SidebarObjectTypes.Timeframe) {
      return this.selectedSingleObject?.locked;
    }
    if (this.selectedSingleObject?.objectType === SidebarObjectTypes.Source) {
      return this.selectedSingleObject?.title !== BudgetObjectSourceLabels.manual_entry;
    }
    return this.spendingSidebarService.isAllSelectedValue;
  }

  private onGlCodesLoaded(glCodes: GLCode[]): void {
    const filteredGLCodes = glCodes.filter(glCode => glCode.isEnabled);
    this.expenseActionsService.setOptionsForSelect(
      ExpenseActionType.AddGLCode,
      (filteredGLCodes || []).map(glCode => ({ value: glCode.id, title: glCode.name }))
    );
  }

  private onVendorsLoaded(vendors: Vendor[]): void {
    const filteredVendors = vendors.filter(vendor => vendor.is_enabled);
    this.expenseActionsService.setOptionsForSelect(
      ExpenseActionType.AddVendor,
      (filteredVendors || []).map(vendor => ({ value: vendor.id, title: vendor.name }))
    );
  }

  private onExpenseTypesLoaded(expenseTypes: BudgetObjectType[]): void {
    const { filteredObjectTypes, integrationTypeIds } = BudgetObjectService.processIntegrationObjectTypes(expenseTypes);
    this.expenseActionsService.integrationExpenseTypeIds = integrationTypeIds;
    this.expenseActionsService.setOptionsForSelect(
      ExpenseActionType.ChangeType,
      (filteredObjectTypes || []).map(expenseType => ({ value: expenseType.id, title: expenseType.name }))
    );
  }

  private updateOwnerOptionsList(): void {
    this.budgetObjectDetailsManager.getCompanyUsers()
      .pipe(takeUntil(this.destroy$))
      .subscribe(companyUsers => {
        const allowedUsers = companyUsers
          .filter(user => BudgetObjectOwnersService.filterUserBySegmentAccess(user, this.budget.id));

        this.expenseActionsService.setOptionsForSelect(
          ExpenseActionType.ChangeOwner,
          (allowedUsers || []).map(owner => ({ value: owner.id, title: owner.name }))
        );
    });
  }

  public get searchDisabled(): boolean {
    // Disabling search if Custom Filters are selected in Expenses
    const isExpenseCFFilterSelected =  Object.keys(this.filterManagementService.getCurrentSelectedCustomFieldFilterSet()).length > 0;
    const rows: Record<SpendingManagementMode, any> = {
      [SpendingManagementMode.Expenses]: this.expensesTableDataService.rows,
      [SpendingManagementMode.Invoices]: this.invoiceTableDataService.rows,
    };
    return (isExpenseCFFilterSelected) || ( !rows[this.spendingModeService.spendingManagementMode]?.length && !this.spendingLocalFiltersService.localFilters.search);
  }

  public get searchHidden(): boolean {
    // Hiding search if Custom Filters are selected in Expenses
    const isExpenseCFFilterSelected =  Object.keys(this.filterManagementService.getCurrentSelectedCustomFieldFilterSet()).length > 0;
    return isExpenseCFFilterSelected
  }

  public get spendingManagementMode(): SpendingManagementMode {
    return this.spendingModeService.spendingManagementMode;
  }

  public expenseBulkUpdate(fields: {[key: string]: any}, toastMessage?: string, undoPayloads?: Record<string, any>[]): void {
    this.utilityService.showLoading(true);
    this.expensesService.updateMultiExpenses(fields)
      .pipe(
        takeUntil(this.destroy$),
        tap(() => {
          if (toastMessage) {
            this.showActionToastr(toastMessage, undoPayloads);
          }
        })
      ).subscribe({
        next: () => this.refreshTableDataAndTotals(),
        error: error => this.utilityService.handleError(error),
        complete: () => this.utilityService.showLoading(false)
      });
  }

  public showActionToastr(toastMessage: string, undoPayloads: Record<string, any>[]): void {
    if (undoPayloads && !this.expensePageSelectionService.isTotalSelectedValue) {
      this.utilityService.showCustomToastr(toastMessage, 'UNDO', { timeOut: 3000 })
        .onAction
        .subscribe(() => {
          this.expenseActionsService.undoMultiExpenses(undoPayloads).subscribe(() => {
            this.refreshTableDataAndTotals();
          });
        });
    } else {
      this.utilityService.showToast({ Title: '', Message: toastMessage });
    }
  }

  public get openedInDrawerId$(): Observable<number> {
    return this.expensePageDrawerService.openedExpenseId$;
  }

  private getContextForChildObjectCreation$(selectedSingleObject: SidebarHierarchyOption): Observable<BudgetObjectCreationContext> {
    if (!selectedSingleObject) {
      return of({});
    }

    const isPseudoObject = (selectedSingleObject.id || '').toString().includes('sub');

    const defineParentIdForPseudoObject = (objectId) => {
      const splitIds = objectId.toString().split('_');
      return splitIds[1];
    };

    const getParentId = (selectedObject) => {
      if (selectedObject.objectType === SidebarObjectTypes.Goal) {
        return null;
      }
      return isPseudoObject ? defineParentIdForPseudoObject(selectedSingleObject.id) : selectedSingleObject.id;
    };

    const context: BudgetObjectCreationContext = {
      parent: {
        id: getParentId(selectedSingleObject),
        type: selectedSingleObject.objectType === SidebarObjectTypes.ExpenseGroup
          ? this.configuration.OBJECT_TYPES.program
          : selectedSingleObject.objectType,
      },
      segmentId: isPseudoObject ? null : selectedSingleObject.segmentId,
      sharedCostRuleId: selectedSingleObject.sharedCostRuleId
    };

    const selectedViewModeContext = {
      [SidebarObjectTypes.Segment]: of({ segmentId: selectedSingleObject.id }),
      [SidebarObjectTypes.ExpenseGroup]:
        this.programService.getProgram(context.parent?.id).pipe(
          catchError(() => of(null)),
          map((programDO: ProgramDO) => programDO ? { glCodeId: programDO.gl_code, poNumber: programDO.po_number } : {})
        ),
      [SidebarObjectTypes.GlCode]: of({ glCodeId: selectedSingleObject.id }),
      [SidebarObjectTypes.Timeframe]: of({ budgetAllocationId: selectedSingleObject.id }),
      [SidebarObjectTypes.Status]: of({ mode: selectedSingleObject.id }),
      [SidebarObjectTypes.Vendor]: of({ vendorId: selectedSingleObject.id })
    };

    return (selectedViewModeContext[selectedSingleObject.objectType] || of({})).pipe(
      map((selectedContext: Object) => ({ ...context, ...selectedContext }))
    );
  }

  public openExpenseCreation(): void {
    const selectedObjects: SidebarHierarchyOption[] = Object.values(this.spendingSidebarService.selectedOptionsMapValue);
    const selectedSingleObject = selectedObjects.length === 1 ? selectedObjects[0] : null;
    this.getContextForChildObjectCreation$(selectedSingleObject).pipe(
      takeUntil(this.destroy$)
    ).subscribe(
      context => this.appRoutingService.openExpenseCreation(context)
    );
  }

  public openExpenseDetails(id: number): void {
    this.appRoutingService.openExpenseDetails(id);
  }

  public openInvoiceReview(id: number): void {
    this.appRoutingService.openInvoiceReview(id);
  }

  public hideBackNavToast() {
    if (this.backNavToast) {
      this.utilityService.hideToastr(this.backNavToast.toastId);
    }
    this.backNavToast = null;
  }
}
