<div class="filter-container">
  <div class="filters-container-actions">
    <button mat-button color="primary" (click)="onCancelClick.emit()">Cancel</button>
    <span
          [matTooltip]="saveBtnDisabledDueToCustomFieldFiltersMsg"
          matTooltipClass="dark-theme-tooltip below multi-line"
          matTooltipPosition="below"
          [matTooltipDisabled]="!(customFieldFiltersAllowedPage.includes(activeRouteForCustomFilters) && isCustomFieldFiltersSelected)"
    >
      <button 
      mat-stroked-button color="primary"
       (click)="saveFilterSet()"
        [disabled]="!canSaveFilters || (customFieldFiltersAllowedPage.includes(activeRouteForCustomFilters) && isCustomFieldFiltersSelected)"
        
        >
        <fa-duotone-icon [icon]="['fad', 'save']"></fa-duotone-icon>
        <span>Save Filters</span>
      </button>
    </span>
    <button mat-flat-button color="primary" (click)="applyFilters()" [disabled]="!unappliedFilters">
      <fa-icon [icon]="['far', 'check']"></fa-icon>
      <span>Apply</span>
    </button>
  </div>
  <ul class="filters-list" [ngClass]="{ 'disabled': (isCEGMode && customFieldFiltersAllowedPage.includes(activeRouteForCustomFilters) && isCustomFieldFiltersSelected) }">
    <ng-template ngFor [ngForOf]="filtersData" let-filter [ngForTrackBy]="trackListBy">
      <li *ngIf="filter.availableItems.length" class="filter-list-item" [ngClass]="{'bigger-searchItem': isCEGMode && filter.fieldName === 'campaignTypes'}">
        <filter-control [filter]="filter" [selectedOptions]="pickedFilters[filter.fieldName]" (onChange)="onSelectionChanged($event)"></filter-control>
      </li>
    </ng-template>
  </ul>

  <div *ngIf="isCEGMode && customFieldFiltersAllowedPage.includes(activeRouteForCustomFilters) && customFiltersData.length" class="custom-field-filters">
    <p class="picked-filters-header">
      <span>Custom Attributes</span>
    </p>
    <ul class="filters-list" [ngClass]="{ 'disabled': (isCEGMode && customFieldFiltersAllowedPage.includes(activeRouteForCustomFilters) && isCEGFiltersSelected) }">
      <ng-template ngFor [ngForOf]="customFiltersData" let-filter [ngForTrackBy]="trackListBy">
        <li *ngIf="filter.availableItems.length" class="filter-list-item">
          <filter-control [filter]="filter" [selectedOptions]="pickedFilters[filter.fieldName] || []" (onChange)="onSelectionChanged($event)"></filter-control>
        </li>
        </ng-template>
    </ul>
  </div>

  <div class="picked-filters">
    <p class="picked-filters-header">
      <span>Filters Picked ({{pickedFiltersCount}})</span>
      <fa-icon 
      *ngIf=" isCEGMode && (customFieldFiltersAllowedPage.includes(activeRouteForCustomFilters) && customFiltersData.length)"
      style="margin-right: 8px; margin-left: -10px;"
      [icon]="['fas', 'info-circle']"
      [matTooltip]="pickedFiltersDisabledTooltipInfo"
      matTooltipClass="dark-theme-tooltip above multi-line"
      matTooltipPosition="above"
      ></fa-icon>
      <link-button (onClick)="clearAllFilters()" [disabled]="!pickedFiltersCount">Clear All</link-button>
    </p>
      <ul class="picked-filters-list">
        <ng-container *ngFor="let filter of filtersData">
          <ng-container *ngIf="pickedFilters[filter.fieldName].length">
            <li class="picked-filters-row">
              <p class="filter-info">
                <span>{{ filter.plural }} ({{ pickedFilters[filter.fieldName].length }})</span>
                <link-button (onClick)="clearFilterByFieldName(filter.fieldName)">Clear</link-button>
              </p>
              <mat-chip-list>
                <mat-basic-chip
                  *ngFor="let item of pickedFilters[filter.fieldName]"
                  [selectable]="false"
                  [removable]="true"
                  (removed)="clearSingleFilterItem(filter.fieldName, item)">
                  <span *ngIf="item.groupTitle" class="chip-prefix">{{ item.groupTitle }}:</span>
                  <span>{{ getFilterItemTitle(item.title)[0] }}</span><span *ngIf="getFilterItemTitle(item.title).length > 1" class="chips-disabled-flag">&nbsp;(Disabled)</span>
                  <button mat-icon-button matChipRemove>
                    <fa-duotone-icon [icon]="['fad', 'times-circle']"></fa-duotone-icon>
                  </button>
                </mat-basic-chip>
              </mat-chip-list>
            </li>
          </ng-container>
        </ng-container>

        <ng-container *ngFor="let filter of customFiltersData">
          <ng-container *ngIf="pickedFilters[filter.fieldName]?.length">
            <li class="picked-filters-row">
              <p class="filter-info">
                <span>{{ filter.plural }} ({{ pickedFilters[filter.fieldName].length }})</span>
                <link-button (onClick)="clearFilterByFieldName(filter.fieldName)">Clear</link-button>
              </p>
              <mat-chip-list>
                <mat-basic-chip
                  *ngFor="let item of pickedFilters[filter.fieldName]"
                  [selectable]="false"
                  [removable]="true"
                  (removed)="clearSingleFilterItem(filter.fieldName, item)">
                  <span *ngIf="item.groupTitle" class="chip-prefix">{{ item.groupTitle }}:</span>
                  <span>{{ getFilterItemTitle(item.title)[0] }}</span><span *ngIf="getFilterItemTitle(item.title).length > 1" class="chips-disabled-flag">&nbsp;(Disabled)</span>
                  <button mat-icon-button matChipRemove>
                    <fa-duotone-icon [icon]="['fad', 'times-circle']"></fa-duotone-icon>
                  </button>
                </mat-basic-chip>
              </mat-chip-list>
            </li>
          </ng-container>
        </ng-container>
      </ul>

  </div>
</div>
