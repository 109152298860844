import { ChangeDetectionStrategy, Component, inject, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import {
  ExpenseUpdateField,
  HierarchyViewMode,
  NOT_SPECIFIED_ID,
  SidebarActionMessage,
  SidebarHierarchyOption,
  SidebarObjectTypes
} from '@spending/types/expense-page.type';
import { SpendingService } from '@spending/services/spending.service';
import { SpendingSidebarService } from '@spending/services/spending-sidebar.service';
import { Configuration } from 'app/app.constants';
import { SharedCostRule } from '@shared/types/shared-cost-rule.interface';
import { BudgetSegmentAccess } from '@shared/types/segment.interface';
import { NavigationEnd, Router } from '@angular/router';
import { FilterManagementService } from '../../../../header-navigation/components/filters/filter-services/filter-management.service';
import { delay, filter, take, takeUntil } from 'rxjs/operators';
import { SelectedValue } from '@shared/types/select-groups.interface';
import { LocalStorageService } from '@common-lib/services/local-storage.service';
import { EXPENSE_PAGE_SIDEBAR_VIEW_MODE } from '@shared/constants/modes.constant';
import { BudgetObjectService } from '@shared/services/budget-object.service';
import { CustomFieldFiltersManagementService } from 'app/header-navigation/components/filters/filter-services/custom-field-filter-management.service';
import { CustomFieldsService } from 'app/budget-object-details/components/custom-fields/custom-field.service';

@Component({
  selector: 'sidebar-content',
  templateUrl: './sidebar-content.component.html',
  styleUrls: ['./sidebar-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarContentComponent implements OnInit, OnDestroy {
  private readonly configuration = inject(Configuration);
  private readonly spendingService = inject(SpendingService);
  private readonly spendingSidebarService = inject(SpendingSidebarService);
  private readonly router = inject(Router);
  private readonly filterService = inject(FilterManagementService);
  private readonly budgetObjectService = inject(BudgetObjectService);
  private readonly customFieldService = inject(CustomFieldsService);

  hierarchyViewModeTitle = {
    [HierarchyViewMode.Segment]: 'Segments',
    [HierarchyViewMode.Goal]: 'Goals',
    [HierarchyViewMode.Campaign]: 'Campaigns',
    [HierarchyViewMode.GlCode]: 'GL Codes',
    [HierarchyViewMode.Source]: 'Sources',
    [HierarchyViewMode.Status]: 'Statuses',
    [HierarchyViewMode.Timeframe]: 'Timeframes',
    [HierarchyViewMode.Vendor]: 'Vendors',
  };

  selectedViewMode$: Observable<HierarchyViewMode>;
  shownOptions$: Observable<SidebarHierarchyOption[]>;
  isAllSelected$: Observable<boolean>;
  isLoading$: Observable<boolean>;
  expenseCountsMap$: Observable<Record<number, number>>;

  hasScroll: boolean;
  searchQuery = '';

  private destroy$ = new Subject<void>();
  private objectTypes = this.configuration.OBJECT_TYPES;
  private readonly customFieldFiltersManagementService = inject(CustomFieldFiltersManagementService)
  public isCustomFieldFiltersSelected: boolean = false;
  public isCEGFiltersSelected: boolean = false;

  constructor() {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd), take(1))
      .subscribe(() => {
        const storedViewMode = LocalStorageService.getFromStorage(EXPENSE_PAGE_SIDEBAR_VIEW_MODE);
        const viewMode = this.router.getCurrentNavigation().extras?.state?.viewMode || storedViewMode;
        if (viewMode) {
          this.spendingSidebarService.setSideBarHierarchyMode(viewMode);
          this.spendingSidebarService.viewOptions$
            .pipe(delay(300), takeUntil(this.destroy$))
            .subscribe(activeOptions => {
              const getFilteredIds = (ids: SelectedValue[]): SelectedValue[] => {
                return (ids || []).filter(id => id !== NOT_SPECIFIED_ID);
              };
              const currentFilterSetValue = this.filterService.currentFilterSetValue;
              const campaigns = getFilteredIds(currentFilterSetValue.campaigns);
              const expenseBuckets = getFilteredIds(currentFilterSetValue.expenseBuckets);
              const segments = currentFilterSetValue.segments || [];
              const goals = currentFilterSetValue.goals || [];

              const getActiveOption = (objId: SelectedValue, objectType: SidebarObjectTypes): SidebarHierarchyOption => {
                return activeOptions.find(optionItem => optionItem?.id === objId && optionItem.objectType === objectType);
              };
              const getGroupForSegmentId = (objId: number): SidebarHierarchyOption => {
                return activeOptions.find(optionItem => (
                  optionItem.objectType === SidebarObjectTypes.SegmentGroup && optionItem?.groupSegmentIds.includes(objId)
                ));
              };

              let activeOption: SidebarHierarchyOption;
              if (campaigns?.length) {
                activeOption = getActiveOption(campaigns[0], SidebarObjectTypes.Campaign);
              } else if (expenseBuckets?.length) {
                activeOption = getActiveOption(expenseBuckets[0], SidebarObjectTypes.ExpenseGroup);
              } else if (segments?.length) {
                activeOption = getActiveOption(segments[0], SidebarObjectTypes.Segment);
                const segmentGroup = getGroupForSegmentId(segments[0] as number);
                if (segmentGroup && segments.length > 1 || segmentGroup?.groupSegmentIds.length === 1) {
                  activeOption = segmentGroup;
                }
              } else if (goals?.length) {
                activeOption = getActiveOption(goals[0], SidebarObjectTypes.Goal);
              }
              if (activeOption) {
                this.spendingSidebarService.remoteSelectOption(activeOption);
              }
            });
        }
      });
  }

  ngOnInit(): void {
    this.selectedViewMode$ = this.spendingSidebarService.hierarchyMode$;
    this.shownOptions$ = this.spendingSidebarService.viewOptions$;
    this.isAllSelected$ = this.spendingSidebarService.isAllSelected$;
    this.isLoading$ = this.spendingSidebarService.isLoading$;
    this.expenseCountsMap$ = this.spendingSidebarService.expenseCountsMap$;
    this.customFieldService.getCFStatus()
    .pipe(takeUntil(this.destroy$))
    .subscribe(status => {
      if(status?.isCFEnabledForExpense){
        this.setUpCustomFieldFiltersSubscriptions();
      }
    })
  }

  private setUpCustomFieldFiltersSubscriptions() {
    this.customFieldFiltersManagementService.isCustomFieldFiltersSelected$
    .pipe(takeUntil(this.destroy$))
    .subscribe((isCustomFiltersSelected: boolean) => {
      if(isCustomFiltersSelected) {
        this.filterHierarchyItems('');
        this.selectMode(HierarchyViewMode.Segment);
        this.toggleSelectAll(isCustomFiltersSelected);
      }
      else if(this.isCustomFieldFiltersSelected) {
          this.toggleSelectAll(false);
        }
          
        this.isCustomFieldFiltersSelected = isCustomFiltersSelected;
    });

    this.customFieldFiltersManagementService.isCEGFiltersSelected$
    .pipe(takeUntil(this.destroy$))
    .subscribe((isCEGFiltersSelected: boolean) => {
      this.isCEGFiltersSelected = isCEGFiltersSelected;
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  get statuslessExpenses(): boolean {
    return this.spendingService.statuslessExpenses;
  }

  filterHierarchyItems(text: string): void {
    this.searchQuery = text;
    this.spendingSidebarService.searchOptions(text);
  }

  selectMode(mode: HierarchyViewMode): void {
    this.spendingSidebarService.setSideBarHierarchyMode(mode);
  }

  toggleSelectAll(isSelected: boolean): void {
    this.spendingSidebarService.setAllSelected(isSelected);
  }

  get isSearchActive(): boolean {
    return this.searchQuery?.length > 2;
  }

  public get segments(): BudgetSegmentAccess[] {
    return this.spendingService.segments;
  }

  public get sharedCostRules(): SharedCostRule[] {
    return this.spendingService.sharedCostRules;
  }

  get isHierarchy(): boolean {
    return this.spendingSidebarService.isHierarchy;
  }

  get selectedModeTitle(): string {
    const selectedMode = this.spendingService.selectedHierarchyModeValue;
    let selectedModeTitle = this.hierarchyViewModeTitle[selectedMode];
    if (selectedMode !== HierarchyViewMode.GlCode) {
      selectedModeTitle = selectedModeTitle.toLowerCase();
    }
    return selectedModeTitle;
  }

  onDropHandle(option: SidebarHierarchyOption): void {
    const isPseudoObject = (option.id || '').toString().includes('sub');
    const isSegmentOption = option.objectType === SidebarObjectTypes.Segment;
    const rows = Object.values(this.spendingService.dragExpenses);
    const ids = Object.values(rows).map(row => row.expenseId);
    let body = { [ExpenseUpdateField[option.objectType]]: option.id };

    if (option.segmentId || option.sharedCostRuleId) {
      body[ExpenseUpdateField.Segment] = option.segmentId;
      body[ExpenseUpdateField.SplitRule] = option.sharedCostRuleId;
    }

    if (isPseudoObject) {
      const splitIds = option.id.toString().split('_');
      body = { [ExpenseUpdateField[option.objectType]]: splitIds[1] };
      body[ExpenseUpdateField.Segment] = null;
      body[ExpenseUpdateField.SplitRule] = option.sharedCostRuleId;
    }
    if (isSegmentOption) {
      body[ExpenseUpdateField.SplitRule] = null;
    }

    const isParentOption = option.objectType === SidebarObjectTypes.Campaign || option.objectType === SidebarObjectTypes.ExpenseGroup;
    const shouldOverrideSegment = isParentOption && rows
      .some(row => option.sharedCostRuleId
        ? row.expenseObject.split_rule !== option.sharedCostRuleId
        : row.expenseObject.company_budget_segment1 !== option.segmentId);
    const shouldOverrideParent = isSegmentOption && rows
      .some(row => row.expenseObject.company_budget_segment1 !== option.id);

    let undoPayloads;
    let toastMessage;
    if (this.spendingService.statuslessExpenses) {
      toastMessage = isParentOption ? SidebarActionMessage.ParentUpdated : '';

      if (shouldOverrideSegment) {
        toastMessage = SidebarActionMessage.SegmentUpdated;
      }
      if (shouldOverrideParent) {
        toastMessage = SidebarActionMessage.NoParentSet;
        body[ExpenseUpdateField.Campaign] = null;
        body[ExpenseUpdateField.ExpenseGroup] = null;
      }

      if (shouldOverrideSegment || shouldOverrideParent) {
        undoPayloads = this.budgetObjectService.prepareUndoPayloads(rows.map(row => row.expenseObject));
      }
    }

    this.spendingService.expenseBulkUpdate({ids, ...body}, toastMessage, undoPayloads);
  }
}
