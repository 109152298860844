import { Injectable, inject } from '@angular/core';
import { SpendingManagementMode } from '../types/expense-page.type';
import { TabSwitchOption } from 'app/shared/components/tab-switch/tab-switch.types';
import { Router } from '@angular/router';
import { Configuration } from 'app/app.constants';
import { BehaviorSubject, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { CustomFieldFiltersManagementService } from 'app/header-navigation/components/filters/filter-services/custom-field-filter-management.service';

@Injectable()
export class SpendingModeService {
  private _dataMode: BehaviorSubject<SpendingManagementMode> = new BehaviorSubject(null);
  public spendingDataMode$ = this._dataMode.asObservable().pipe(filter(mode => mode !== null));

  public dataModeOptions: TabSwitchOption[] = [
    {
      title: 'Expenses',
      value: SpendingManagementMode.Expenses,
      icon: ['fas', 'coins']
    },
    {
      title: 'Invoices',
      value: SpendingManagementMode.Invoices,
      icon: ['fas', 'file-invoice-dollar']
    }
  ];
  subscription: Subscription = null;

  constructor(
    private readonly config: Configuration,
    private readonly router: Router,
  ) {

    this.subscription = this.customFieldFiltersManagementService.isCustomFieldFiltersSelected$.subscribe(isSelected => {
      if (isSelected) {
        this.dataModeOptions = this.dataModeOptions.map(option => option.value === SpendingManagementMode.Invoices ? { ...option, disabled: true } : option)
      }else {
        this.dataModeOptions = this.dataModeOptions.map(option => option.value === SpendingManagementMode.Invoices ? { ...option, disabled: false } : option)
      }
    })
  }

  private readonly customFieldFiltersManagementService = inject(CustomFieldFiltersManagementService);

  public set spendingManagementMode(mode: SpendingManagementMode) {
    if (this.spendingManagementMode !== mode) {
      this._dataMode.next(mode);
    }
  }

  public get spendingManagementMode(): SpendingManagementMode {
    return this._dataMode.getValue();
  }

  public openSpendingManagementMode(mode: SpendingManagementMode) {
    this.spendingManagementMode = mode;
    this.router.navigate(
      [this.config.ROUTING_CONSTANTS.SPENDING_MANAGEMENT, mode],
      { queryParamsHandling: 'merge' }
    )
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

}
