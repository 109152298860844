
import { Injectable, inject } from '@angular/core';
import { CustomFieldsService } from '../../../../budget-object-details/components/custom-fields/custom-field.service';  // Adjust the path as necessary
import { BehaviorSubject, Observable, combineLatest, filter, take } from 'rxjs';
import { BudgetDataService } from 'app/dashboard/budget-data/budget-data.service';
import { CompanyDataService } from '@shared/services/company-data.service';
import { CustomFieldFiltersSummaryService } from './custom-field-filter-summary.service';
import { CustomFieldFilterPageRoute, CustomFieldFiltersMappingType, Filter, FilterType } from '../filters.interface';
import { SelectItem } from '@shared/types/select-groups.interface';
import { FilterManagementService } from './filter-management.service';
import { capitalizeString } from '@shared/utils/common.utils';

@Injectable({
  providedIn: 'root'
})
export class CustomFieldFiltersManagementService {

  private managePageCFSubject: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public managePageCF$: Observable<any[]> = this.managePageCFSubject.asObservable();

  private expenseCFSubject: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public expenseCF$: Observable<any[]> = this.expenseCFSubject.asObservable();

  private calendarPageCFSubject: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public calendarPageCF$: Observable<any[]> = this.calendarPageCFSubject.asObservable();

  private dashboardPageCFSubject: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public dashboardPageCF$: Observable<any[]> = this.dashboardPageCFSubject.asObservable();

  public isCustomFieldFiltersSelected = new BehaviorSubject<boolean>(false);
  public isCustomFieldFiltersSelected$ = this.isCustomFieldFiltersSelected.asObservable();

  public isCEGFiltersSelected = new BehaviorSubject<boolean>(false);
  public isCEGFiltersSelected$ = this.isCEGFiltersSelected.asObservable();


  private readonly notSpecifiedOption: SelectItem = {
    title: 'Not specified',
    value: FilterManagementService.NOT_SPECIFIED_FILTER_VALUE,
    alwaysOnTop: true
  }
  
  private readonly budgetDataService = inject(BudgetDataService);
  private readonly companyDataService = inject(CompanyDataService);
  private readonly customFieldFiltersSummaryService = inject(CustomFieldFiltersSummaryService);
    companyId: number;
    expense: any;
    budgetCompanyChangeSub: any;
    expenseCFListSubscription: any;
    managePageCFListSubscription: any;
    calendarPageCFListSubscription: any;
    dashboardPageCFListSubscription: any;

  constructor(private customFieldService: CustomFieldsService) {

   this.budgetCompanyChangeSub = combineLatest(
      [
        this.companyDataService.selectedCompany$,
        this.budgetDataService.selectedBudget$
      ]
    ).subscribe(
      ([company, _budget]) => {
        this.clearAllCustomFieldFilters();
        if(company?.id){
          this.companyId = company.id;
          this.expenseCFListSubscription =  this.customFieldService.getCFStatus().pipe(
              filter(status => status?.isCFEnabledForExpense), // Only proceed if expense CF is enabled
              take(1) // Take the first occurrence and complete
            ).subscribe(() => {
              this.loadCustomFields(CustomFieldFiltersMappingType.Expense);
              console.log('Expense CF loaded...');
            });


          this.managePageCFListSubscription =  this.customFieldService.getCFStatus().pipe(
              filter(status => status?.isCFEnabledForCampaign || status?.isCFEnabledForProgram), // Only proceed if Manage Page CF is enabled
              take(1) // Take the first occurrence and complete
            ).subscribe(() => {
              this.loadCustomFields(CustomFieldFiltersMappingType.Manage);
              console.log('Manage page CF loaded...');
            });
            
          this.calendarPageCFListSubscription =  this.customFieldService.getCFStatus().pipe(
            filter(status => status?.isCFEnabledForCampaign), // Only proceed if Calendar CF is enabled
            take(1) // Take the first occurrence and complete
          ).subscribe(() => {
            this.loadCustomFields(CustomFieldFiltersMappingType.Calendar);
            console.log('Calendar page CF loaded...');
          });
          
          this.dashboardPageCFListSubscription =  this.customFieldService.getCFStatus().pipe(
            filter(status => status?.isCFEnabledForCampaign || status?.isCFEnabledForProgram), // Only proceed if Dashboard CF is enabled
            take(1) // Take the first occurrence and complete
          ).subscribe(() => {
            this.loadCustomFields(CustomFieldFiltersMappingType.Dashboard);
            console.log('Dashboard page CF loaded...');
          });
        }
      }
    );

  }

  public loadCustomFields(mappingType: CustomFieldFiltersMappingType, companyId: number = this.companyId): void {


    this.customFieldService.fetchDropdownOptions(companyId,null,mappingType).subscribe(
     {
        next: (data: any[]) => {
            switch (mappingType) {
                case CustomFieldFiltersMappingType.Manage:
                this.managePageCFSubject.next(data);
                this.dashboardPageCFSubject.next(data);
                break;
            
                case CustomFieldFiltersMappingType.Expense:
                this.expenseCFSubject.next(data);
                break;

                case CustomFieldFiltersMappingType.Calendar:
                this.calendarPageCFSubject.next(data);
                break;

                case CustomFieldFiltersMappingType.Dashboard:
                this.dashboardPageCFSubject.next(data);
                break;
            }
         },
        error:  error => {
            console.error(`Failed to load ${mappingType} custom fields`, error);
            switch (mappingType) {
                case CustomFieldFiltersMappingType.Manage:
                this.managePageCFSubject.next([]); 
                break;
                
                case CustomFieldFiltersMappingType.Expense:
                this.expenseCFSubject.next([]);  
                break;
                
                case CustomFieldFiltersMappingType.Calendar:
                this.calendarPageCFSubject.next([]);
                break;

                case CustomFieldFiltersMappingType.Dashboard:
                this.dashboardPageCFSubject.next([]);
                break;
            }
        }
    })
  }

  public reloadManagePageCF(): void {
    this.loadCustomFields(CustomFieldFiltersMappingType.Manage);
  }

  public reloadExpenseCF(): void {
    this.loadCustomFields(CustomFieldFiltersMappingType.Expense);
  }

  public getExpenseCFValue(): any[] {
    return this.expenseCFSubject.getValue();
  }

  public getManagePageCFValue(): any[] {
    return this.managePageCFSubject.getValue();
  }

  public getCalendarPageCFValue(): any[] {
    return this.calendarPageCFSubject.getValue();
  }

  public getDashboardPageCFValue(): any[] {
    return this.dashboardPageCFSubject.getValue();
  }


  public getCustomFieldFiltersDataBasedOnActiveRoute(activeRoute: string) {
    let fields = []
    switch(activeRoute) {
      case CustomFieldFilterPageRoute.Manage:
        fields = this.getManagePageCFValue()
        break;
      case CustomFieldFilterPageRoute.Expense:
        fields = this.getExpenseCFValue()
        break;
      case CustomFieldFilterPageRoute.Calendar:
        fields = this.getCalendarPageCFValue()
        break;  
      case CustomFieldFilterPageRoute.Dashboard:
        fields = this.getDashboardPageCFValue()  
        // so on...
    }

    
   return fields.map(field => ({
      fieldName:  field.cfName,
      plural: field.cfName,
      title: field.cfName,
      type: FilterType.CustomFieldFilter,
      availableItems: Object.values(field.options).map((ov: string)  => ({ title: ov, value: field.optionValueIdMapping[ov].id })).concat({ ...this.notSpecifiedOption, title: `No ${capitalizeString(field.cfName)}` }),
      cf_id: field.id
        
    })) as Filter[];
  }

  public allOptionsSelectedForCFFilter(optionList1: unknown[], optionList2: unknown[]){
    return optionList1.length === optionList2.length && optionList1.every((value, index) => optionList2.includes(value));
  }

  public clearAllCustomFieldFilters(): void { 
    this.expenseCFListSubscription?.unsubscribe();
    this.managePageCFListSubscription?.unsubscribe();
    this.calendarPageCFListSubscription?.unsubscribe();
    this.dashboardPageCFListSubscription?.unsubscribe();
    
    this.managePageCFSubject.next([]);
    this.expenseCFSubject.next([]);
    this.calendarPageCFSubject.next([]);
    this.dashboardPageCFSubject.next([]);

    this.isCustomFieldFiltersSelected.next(false);
    this.isCEGFiltersSelected.next(false);
    this.customFieldFiltersSummaryService.resetSummaryTotal();
    
  } 

  ngOnDestroy(){
    this.budgetCompanyChangeSub?.unsubscribe();
  }
}
