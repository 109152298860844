
import { Injectable, inject } from '@angular/core';
import { CustomFieldsService } from '../../../../budget-object-details/components/custom-fields/custom-field.service';  // Adjust the path as necessary
import { BehaviorSubject, Observable, combineLatest, filter, take } from 'rxjs';
import { BudgetDataService } from 'app/dashboard/budget-data/budget-data.service';
import { CompanyDataService } from '@shared/services/company-data.service';
import { CustomFieldFiltersSummaryService } from './custom-field-filter-summary.service';
import { CustomFieldFiltersMappingType } from '../filters.interface';

@Injectable({
  providedIn: 'root'
})
export class CustomFieldFiltersManagementService {

  private managePageCFSubject: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public managePageCF$: Observable<any[]> = this.managePageCFSubject.asObservable();

  private expenseCFSubject: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public expenseCF$: Observable<any[]> = this.expenseCFSubject.asObservable();

  public isCustomFieldFiltersSelected = new BehaviorSubject<boolean>(false);
  public isCustomFieldFiltersSelected$ = this.isCustomFieldFiltersSelected.asObservable();

  public isCEGFiltersSelected = new BehaviorSubject<boolean>(false);
  public isCEGFiltersSelected$ = this.isCEGFiltersSelected.asObservable();


  private readonly budgetDataService = inject(BudgetDataService);
  private readonly companyDataService = inject(CompanyDataService);
  private readonly customFieldFiltersSummaryService = inject(CustomFieldFiltersSummaryService);
    companyId: number;
    expense: any;
    budgetCompanyChangeSub: any;
    expenseCFListSubscription: any;
    managePageCFListSubscription: any;

  constructor(private customFieldService: CustomFieldsService) {

   this.budgetCompanyChangeSub = combineLatest(
      [
        this.companyDataService.selectedCompany$,
        this.budgetDataService.selectedBudget$
      ]
    ).subscribe(
      ([company, _budget]) => {
        this.clearAllCustomFieldFilters();
        if(company?.id){
          this.companyId = company.id;
          this.expenseCFListSubscription =  this.customFieldService.getCFStatus().pipe(
              filter(status => status?.isCFEnabledForExpense), // Only proceed if expense CF is enabled
              take(1) // Take the first occurrence and complete
            ).subscribe(() => {
              this.loadCustomFields(CustomFieldFiltersMappingType.Expense);
              console.log('Expense CF loaded...');
            });


          this.managePageCFListSubscription =  this.customFieldService.getCFStatus().pipe(
              filter(status => status?.isCFEnabledForCampaign || status?.isCFEnabledForProgram), // Only proceed if expense CF is enabled
              take(1) // Take the first occurrence and complete
            ).subscribe(() => {
              this.loadCustomFields(CustomFieldFiltersMappingType.Manage);
              console.log('Manage page CF loaded...');
            }); 
        }
      }
    );

  }

  public loadCustomFields(mappingType: CustomFieldFiltersMappingType, companyId: number = this.companyId): void {


    this.customFieldService.fetchDropdownOptions(companyId,null,mappingType).subscribe(
     {
        next: (data: any[]) => {
            switch (mappingType) {
                case CustomFieldFiltersMappingType.Manage:
                this.managePageCFSubject.next(data);
                break;
            
                case CustomFieldFiltersMappingType.Expense:
                this.expenseCFSubject.next(data);
                break;
            }
         },
        error:  error => {
            console.error(`Failed to load ${mappingType} custom fields`, error);
            switch (mappingType) {
                case CustomFieldFiltersMappingType.Manage:
                this.managePageCFSubject.next([]); 
                break;
                case CustomFieldFiltersMappingType.Expense:
                this.expenseCFSubject.next([]);  
                break;
            }
        }
    })
  }

  public reloadManagePageCF(): void {
    this.loadCustomFields(CustomFieldFiltersMappingType.Manage);
  }

  public reloadExpenseCF(): void {
    this.loadCustomFields(CustomFieldFiltersMappingType.Expense);
  }

  public getExpenseCFValue(): any[] {
    return this.expenseCFSubject.getValue();
  }

  public getManagePageCFValue(): any[] {
    return this.managePageCFSubject.getValue();
  }

  public allOptionsSelectedForCFFilter(optionList1: unknown[], optionList2: unknown[]){
    return optionList1.length === optionList2.length && optionList1.every((value, index) => value === optionList2[index]);
  }

  public clearAllCustomFieldFilters(): void { 
    this.expenseCFListSubscription?.unsubscribe();
    this.managePageCFListSubscription?.unsubscribe();
    this.managePageCFSubject.next([]);
    this.expenseCFSubject.next([]);

    this.isCustomFieldFiltersSelected.next(false);
    this.isCEGFiltersSelected.next(false);
    this.customFieldFiltersSummaryService.resetSummaryTotal();
    
  } 

  public clearSummaryTotalForCustomFieldFiltersApplied() {
    this.customFieldFiltersSummaryService.resetSummaryTotal();
  }

  ngOnDestroy(){
    this.budgetCompanyChangeSub?.unsubscribe();
  }
}
