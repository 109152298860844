import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Validations } from 'app/app.validations';
import { getRequestOptions, handleBulkRequestError, withAPIV0ErrorHandling } from 'app/shared/utils/http-request.utils';
import { BudgetObjectCloneResponse } from '../../types/budget-object-clone-response.interface';
import { BulkOperationResponse } from '../../types/bulk-operation-response.interface';
import { GoalDO, GoalTypeDO, PlanGoalDO } from '../../types/goal.interface';
import { API_V0_URL, API_V2_URL } from '@common-lib/lib/injection-tokens/url.tokens';
import { ApiV0Response } from '@shared/types/api-v0-response.interface';
import { ObjectAmountsByTimeframes } from '@shared/types/object-amounts.interface';

@Injectable()
export class GoalsService {
  private readonly apiV2Url = inject(API_V2_URL);
  private readonly actionUrl = inject(API_V0_URL);
  private readonly http: HttpClient = inject(HttpClient);
  private readonly validation = inject(Validations);

  public apiPaths = {
    goal: 'goal/',
    goalType: 'goal_type/',
    goalAllocation: 'goal_allocation/',
    moveToBudget: 'move_to_other_budget/',
    multiDelete: 'multi_delete/',
    goalPlan: 'goal/plan/',
    amountsByTimeframes: 'amounts_by_timeframes/'
  };

  getGoal(goalId: number): Observable<GoalDO> {
    return this.http.get<GoalDO>(`${this.apiV2Url}${this.apiPaths.goal}${goalId}/`);
  }

  getGoals(params: object): Observable<GoalDO[]> {
    return this.http.get<GoalDO[]>(`${this.apiV2Url}${this.apiPaths.goal}`, getRequestOptions(params));
  }

  getPlanGoals(params: object): Observable<PlanGoalDO[]> {
    return this.http.get<PlanGoalDO[]>(`${this.apiV2Url}${this.apiPaths.goalPlan}`, getRequestOptions(params));
  }

  getGoalTypes(companyId: number): Observable<GoalTypeDO[]> {
    return this.http.get<GoalTypeDO[]>(
      `${this.apiV2Url}${this.apiPaths.goalType}`,
      getRequestOptions({company: companyId})
    );
  }

  createGoal(goal: Partial<GoalDO>): Observable<GoalDO> {
    return this.http.post<GoalDO>(
      `${this.apiV2Url}${this.apiPaths.goal}`,
      JSON.stringify(goal)
    );
  }

  updateGoal(goalId: number, data: Partial<GoalDO>): Observable<GoalDO> {
    return this.http.patch<GoalDO>(
      `${this.apiV2Url}${this.apiPaths.goal}${goalId}/`,
      JSON.stringify(data)
    );
  }

  deleteGoal(goalId: number): Observable<void> {
    return this.http.delete<void>(`${this.apiV2Url}${this.apiPaths.goal}${goalId}/`);
  }

  cloneGoal(goalId: number): Observable<BudgetObjectCloneResponse> {
    return this.http.post<BudgetObjectCloneResponse>(`${this.apiV2Url}${this.apiPaths.goal}${goalId}/clone/`, {});
  }

  logGoalView(goalId: number): Observable<void> {
    return this.http.post<void>(`${this.apiV2Url}${this.apiPaths.goal}${goalId}/log_view/`, null);
  }

  validateUniqueName(companyId: number, budgetId: number, name: string): Observable<ApiV0Response<void>> {
    const requestData = { name, company_id: companyId, budget_id: budgetId };
    return withAPIV0ErrorHandling(
      this.http.get<ApiV0Response<void>>(this.actionUrl + 'goals/unique/', getRequestOptions(requestData))
    )
  }

  moveToBudget(goalId: number, budgetId: number, companyId: number): Observable<any> {
    return this.http.patch(
      `${this.apiV2Url}${this.apiPaths.goal}${goalId}/${this.apiPaths.moveToBudget}`,
      {
        budget: budgetId,
        company: companyId
      }
    );
  }

  deleteMultiGoals(goalIds: number[]): Observable<BulkOperationResponse<number>> {
    const { ValiditionMessages: MESSAGES } = this.validation;

    return this.http.delete<BulkOperationResponse<number>>(
      `${this.apiV2Url}${this.apiPaths.goal}${this.apiPaths.multiDelete}`,
      {
        body: {
          ids: goalIds
        }
      }
    ).pipe(
      catchError(err => handleBulkRequestError(err, goalIds, MESSAGES.SOMETHING_WENT_WRONG))
    );
  }

  getAmountsByTimeframes(budgetId: number, ids: number[], params?: object): Observable<ObjectAmountsByTimeframes> {
    return this.http.get<ObjectAmountsByTimeframes>(
      `${this.apiV2Url}${this.apiPaths.goal}${this.apiPaths.amountsByTimeframes}`,
      getRequestOptions({ ...params, budget: budgetId, ids: ids.join(',') })
    );
  }
}
