import { Component, Input, OnInit } from '@angular/core';
import { Filter, FilterDialogConfig, FilterDialogContext, FilterDialogState, FilterPageRoute, FilterSet, FilterSetData, FilterType } from '../filters.interface';
import { SaveFilterModalComponent } from '../save-filter-modal/save-filter-modal.component';
import { FilterManagementService } from '../filter-services/filter-management.service';
import { CompanyDataService } from 'app/shared/services/company-data.service';
import { BudgetDataService } from 'app/dashboard/budget-data/budget-data.service';
import { filter, switchMap } from 'rxjs/operators';
import { UserManager } from 'app/user/services/user-manager.service';
import { UtilityService } from 'app/shared/services/utility.service';
import { AppRoutingService } from '@shared/services/app-routing.service';
import { MatDialog } from '@angular/material/dialog';
import { Subscription, combineLatest, of } from 'rxjs';
import { CustomFieldFiltersManagementService } from '../filter-services/custom-field-filter-management.service';
import { SelectItem } from '@shared/types/select-groups.interface';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'filters-management',
  templateUrl: './filters-management.component.html',
  styleUrls: ['./filters-management.component.scss']
})
export class FiltersManagementComponent implements OnInit {
  @Input() disabled = false;

  outerFiltersApplied = false;
  owner: { id: number, name: string };
  activeFilters: FilterSet;
  budgetData: { id: number, name: string };
  filtersData: Filter[];
  selectedFiltersCount: number = null;
  savedFilters: FilterSetData[];
  filtersApplied = false;
  companyUsers: {[id: string]: string} = {};
  subscriptions = [];
  errorHandler = { error: err => this.handleError(err) };
  isCEGMode = false;
  customFiltersData: Filter[] = [];
  private lastRoutePath: string | null = null;

  private readonly notSpecifiedOption: SelectItem = {
    title: 'Not specified',
    value: FilterManagementService.NOT_SPECIFIED_FILTER_VALUE,
    alwaysOnTop: true
  }
  
  private activeRouteForCustomFilters: string;
  private isCustomFieldFiltersSelected: boolean;
  private expenseCFFiltersSubscription: Subscription = null;
  private routeSubscription: Subscription = null;
  private budgetCompanyChangeSub: Subscription = null;
  private managePageCFFiltersSubscription: Subscription = null;
  private currentBudget: number = null;

  constructor(
    private readonly filterManagementService: FilterManagementService,
    private readonly companyData: CompanyDataService,
    private readonly budgetDataService: BudgetDataService,
    private readonly userManager: UserManager,
    private readonly utilityService: UtilityService,
    public readonly appRoutingService: AppRoutingService,
    private readonly dialog: MatDialog,
    private readonly customFieldFiltersManagementService: CustomFieldFiltersManagementService,
    private router: Router,
  )  { }

  ngOnInit() {
    this.subscriptions.push(
      this.filterManagementService.budgetFiltersList$.subscribe(
        filters => this.savedFilters = filters
      ),

      this.companyData.companyUsersList$.subscribe(
        users => this.companyUsers = users.reduce(
          (store, user) =>
            ({...store, [user.id]: user.user_profile_detail.name}), {}
        )
      ),

      this.filterManagementService.currentFilterSet$.subscribe(
        filters => {
          this.activeFilters = filters;
          this.updateFilterContainerStatus()
        }
      ),

      this.userManager.currentUser$
        .pipe(filter(user => user != null))
        .subscribe(user => this.owner = ({ id: user.id, name: `${user.first_name} ${user.last_name}` })),

      this.filterManagementService.externalFiltersSet$.subscribe(
        extFiltersOn => {
          if (extFiltersOn !== this.outerFiltersApplied) {
            this.outerFiltersApplied = extFiltersOn;
            this.updateFilterContainerStatus();
          }
        }
      ),

      combineLatest(
        this.filterManagementService.currentFiltersData$,
        this.budgetDataService.selectedBudget$
    ).subscribe(([filtersData, selectedBudget]) => {
      this.budgetData = selectedBudget;
      this.isCEGMode = selectedBudget?.new_campaigns_programs_structure;
      if (this.isCEGMode) {
        let index = filtersData.findIndex(obj => obj.fieldName === "campaignTypes");
        if (index !== -1) {
          let objectToMove = filtersData.splice(index, 1)[0];
          objectToMove.title = "Campaign/Expense Group Type";
          objectToMove.plural= "Campaign/Expense Group Types";
          filtersData.push(objectToMove);
        }
        this.filtersData = filtersData;
      }
      else {  
        this.filtersData = filtersData;
      }
    })
    
    );

    this.filtersApplied = this.filtersApplied || this.outerFiltersApplied;
    
      this.getCustomFieldFiltersOptionsBasedOnRoute(this.router.url);
  
      if(!this.routeSubscription) {
        this.routeSubscription = this.router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            )
            .subscribe((event: NavigationEnd) => {
 
            const currentRoutePath = this.extractRoutePath(event.urlAfterRedirects);
            
            if ( ((this.lastRoutePath !== currentRoutePath) && !currentRoutePath.includes('spending/expenses(drawer')) && !( this.activeRouteForCustomFilters === 'Manage' && currentRoutePath.includes('drawerStack')  )) {
              this.lastRoutePath = currentRoutePath;
              if(this.isCustomFieldFiltersSelected) { 
                this.clearPreviousCustomFieldFiltersSelectionFromStorage();
              }
              this.checkUsageOfCEGFilters();
              this.checkUsageOfCustomFieldFilters();
              this.getCustomFieldFiltersOptionsBasedOnRoute(event.urlAfterRedirects);
            }          
        })
      }


    if(!this.budgetCompanyChangeSub) {

      this.budgetCompanyChangeSub = 
      combineLatest(
        [
          this.companyData.selectedCompany$,
          this.budgetDataService.selectedBudget$
        ]
      ).subscribe(([company, budget]) => {
          if(company) {
            const currentBudgetChanged = this.currentBudget && this.currentBudget !== budget.id;
            this.currentBudget = budget.id;

            // Add Navigate to Expense page from All views Segments, Campaigns, Goals, And EG
            if(!currentBudgetChanged 
              && 
              (
                (this.activeFilters['campaigns']?.length === 1 && this.activeFilters['timeframes']?.length === 1 ) || 
                (this.activeFilters['goals']?.length === 1 && this.activeFilters['timeframes']?.length === 1) || 
                (this.activeFilters['segments']?.length === 1 && this.activeFilters['timeframes']?.length === 1) ||
                (this.activeFilters['expenseBuckets']?.length === 1 && this.activeFilters['timeframes']?.length === 1)
                
              )
              && this.activeRouteForCustomFilters === 'Expense'){
               // This means we have arrived to expense from Accordion view Drawer 
              this.clearPreviousCompanyCustomFieldFilters(true);
            }else {
              this.clearPreviousCompanyCustomFieldFilters();
            }
            this.getCustomFieldFiltersOptionsBasedOnRoute(this.router.url);
          }
        });
    }  

  }

  private getCustomFieldFiltersOptionsBasedOnRoute(url: string) {

    let routePath = this.extractRoutePath(url);
      if(routePath.includes(FilterPageRoute.Expenses) || routePath.includes(FilterPageRoute.Invoice)) {
        if(this.activeRouteForCustomFilters !== 'Expense' && this.activeRouteForCustomFilters !== 'Invoice' ) {
          this.clearPreviousCustomFieldFiltersSelectionFromStorage();
        }

      this.activeRouteForCustomFilters = 'Expense';
      if(routePath.includes(FilterPageRoute.Invoice)) {
        this.activeRouteForCustomFilters = 'Invoice';
      }

    }else if(routePath.includes(FilterPageRoute.Manage)){
      
      if(this.activeRouteForCustomFilters !== 'Manage') {
        this.clearPreviousCustomFieldFiltersSelectionFromStorage();
      }
      this.activeRouteForCustomFilters = 'Manage';
    }
    else{
      
      this.clearPreviousCustomFieldFiltersSelectionFromStorage();
      this.customFiltersData = [];
      this.activeRouteForCustomFilters = '';
    }

    this.enableCustomFieldFiltersSubscriptionForExpensePage();
    this.enableCustomFieldFiltersSubscriptionsForManagePage();
  }


  private enableCustomFieldFiltersSubscriptionForExpensePage(){
    
    if(!this.expenseCFFiltersSubscription) {
      this.expenseCFFiltersSubscription =  this.customFieldFiltersManagementService.expenseCF$.subscribe((fields) => {
        console.log(fields);

        if(this.activeRouteForCustomFilters === 'Expense' || this.activeRouteForCustomFilters === 'Invoice') {
          this.customFiltersData = fields.map(field => ({
            fieldName:  field.cfName,
            plural: field.cfName,
            title: field.cfName,
            type: FilterType.CustomFieldFilter,
            availableItems: Object.values(field.options).map((ov: string)  => ({ title: ov, value: field.optionValueIdMapping[ov].id })).concat({ ...this.notSpecifiedOption })
              
          })) as Filter[];


        this.checkUsageOfCEGFilters();
        this.checkUsageOfCustomFieldFilters();
        }
      });
    }
  }

  private enableCustomFieldFiltersSubscriptionsForManagePage(){

    if(!this.managePageCFFiltersSubscription) {
      this.managePageCFFiltersSubscription =  this.customFieldFiltersManagementService.managePageCF$.subscribe((fields) => {
        console.log("Mange Page CF Emitted : ", fields);

        if(this.activeRouteForCustomFilters === 'Manage') {
          this.customFiltersData = fields.map(field => ({
            fieldName:  field.cfName,
            plural: field.cfName,
            title: field.cfName,
            type: FilterType.CustomFieldFilter,
              availableItems: Object.values(field.optionValueIdMapping).map((ov: {option_value: string, id: number | string })  => ({ title: ov.option_value, value: ov.id })).concat({ ...this.notSpecifiedOption })
              
          })) as Filter[];


        this.checkUsageOfCEGFilters();
        this.checkUsageOfCustomFieldFilters();
        }
      });
    }

  }

  private clearPreviousCustomFieldFiltersSelectionFromStorage() {
      this.clearCustomFieldFiltersImmediately(this.activeFilters);
      
      // Clearing the custom field filters from the storage on logout
      if(this.router.url === '/login') {
        this.filterManagementService.filterSetStorage.remove(this.owner.id, this.budgetData?.id );
        this.activeFilters = {};
        this.filterManagementService.updateCurrentFilterSet(this.activeFilters);
        return;
      }
      if(this.router.url !== '/login' && this.owner?.id && this.budgetData?.id) {
        this.filterManagementService.filterSetStorage.save(this.owner.id, this.budgetData.id, this.activeFilters);
      }
  }

  private checkUsageOfCustomFieldFilters() {
    const isCustomFieldFiltersSelected = Object.keys(this.activeFilters).some(fieldName => {
      return this.customFiltersData.map(fd => fd.fieldName).includes(fieldName) && this.activeFilters[fieldName].length
    })

    this.isCustomFieldFiltersSelected = isCustomFieldFiltersSelected;

    this.customFieldFiltersManagementService.isCustomFieldFiltersSelected.next(isCustomFieldFiltersSelected);
  }

  private checkUsageOfCEGFilters() {
    const pickedCEGFilterList =  Object.keys(this.activeFilters).filter(item => !this.customFiltersData.map(fd => fd.fieldName).includes(item))
    const isCEGFiltersSelected = pickedCEGFilterList.some(fieldName => this.activeFilters[fieldName].length);
   
    this.customFieldFiltersManagementService.isCEGFiltersSelected.next(isCEGFiltersSelected);
  }


  private extractRoutePath(url: string): string {
    return url.split('?')[0].split('#')[0]; // Remove query parameters and fragments
  }


  private clearPreviousCompanyCustomFieldFilters(isAccordionNavigate = false) {
    this.clearCustomFieldFiltersImmediately(null, isAccordionNavigate);
    this.customFiltersData = [];
  }

  protected get isDrawerOpen(): boolean {
    return this.appRoutingService.isDrawerOpen();
  }

  protected closeDrawer(): void {
    this.appRoutingService.closeActiveDrawer();
  }

  onCloseFilterContainer() {
    this.hideContainer();
    this.updateSelectedFiltersCount();
  }

  hideContainer() {
    this.filterManagementService.toggleFilterVisibility(false);
  }

  removeFilterSet(id: number) {
    const budgetData = this.budgetData || this.budgetDataService.selectedBudgetSnapshot;
    return this.filterManagementService.deleteFilterSetData(
      id,
      budgetData.id,
      this.errorHandler.error);
  }

  updateFilterSet(filterData: FilterSetData) {
    const budgetData = this.budgetData || this.budgetDataService.selectedBudgetSnapshot;
    return this.filterManagementService.updateFilterSetData(
      filterData,
      budgetData.id,
      this.errorHandler.error);
  }

  applyFilters(filtersSet: FilterSet) {
    this.filterManagementService.updateCurrentFilterSet(filtersSet);
    this.hideContainer();
    this.updateSelectedFiltersCount();
  }

  selectActiveFilterSet(selectedFilterId: number) {
    const filterSetData = this.savedFilters.find(filter => filter.id === selectedFilterId);
    if (filterSetData && this.filterManagementService.activeFilterSetId !== selectedFilterId) {
      this.filterManagementService.selectActiveFilterSet(selectedFilterId, filterSetData.selectedFilters);
    }
  }

  saveFilterSet(filterData: FilterSetData, filtersSet: FilterSet) {
    const budgetData = this.budgetData || this.budgetDataService.selectedBudgetSnapshot;
    return this.filterManagementService.saveFilterSetData(
      { ...filterData, selectedFilters: filtersSet },
      budgetData.id,
      this.errorHandler.error);
  }

  updateFilterContainerStatus() {
    const filtersApplied = Object.keys(this.activeFilters).some(
      fieldName => this.activeFilters[fieldName].length > 0
    );
    this.updateSelectedFiltersCount();
    this.filtersApplied = filtersApplied || this.outerFiltersApplied;
  }

  calcSelectedFilters() {
    if (this.activeFilters == null || typeof this.activeFilters !== 'object') {
      return 0;
    }

    return Object.keys(this.activeFilters).reduce((count, fieldName) => (
      this.activeFilters[fieldName]
        ? count + this.activeFilters[fieldName].length
        : count
    ), 0);
  }

  setSelectedFiltersCount(count) {
    this.selectedFiltersCount = count;
  }

  updateSelectedFiltersCount() {
    const totalCount = this.calcSelectedFilters();
    this.setSelectedFiltersCount(totalCount);
  }

  clearFiltersImmediately() {
    this.closeDrawer();
    const emptyFiltersSet: FilterSet = {};
    this.filtersData.forEach(filter => {
      emptyFiltersSet[filter.fieldName] = [];
    });
    this.clearCustomFieldFiltersImmediately(emptyFiltersSet);
  }

  clearCustomFieldFiltersImmediately(filterSet?: FilterSet, isAccordionNavigate = false) {
    const emptyFiltersSet: FilterSet = filterSet  || {};  
    (this.customFiltersData || []).forEach(filter => {
      emptyFiltersSet[filter.fieldName] = [];
    }); 

    if(!isAccordionNavigate) {
      this.applyFilters(emptyFiltersSet);
    }
    this.clearCustomFieldFiltersSelectionState();
    this.filterManagementService.notifyFiltersCleared();
  }

  clearCustomFieldFiltersSelectionState() {
    this.customFieldFiltersManagementService.isCustomFieldFiltersSelected.next(false);
    this.customFieldFiltersManagementService.isCEGFiltersSelected.next(false);
  }

  openSaveFilterModal(filterSet: FilterSet) {
    this.hideContainer();
    const dialogContext: FilterDialogContext = FilterDialogConfig[FilterDialogState.Save];

    dialogContext.context = {
      budgetData: this.budgetData || this.budgetDataService.selectedBudgetSnapshot,
      owner: this.owner,
      selectedFilterId: this.filterManagementService.activeFilterSetId,
      favouriteFilter: this.savedFilters.find(item => item.isFavourite)
    };
    dialogContext.state = FilterDialogState.Save;
    dialogContext.submitAction.handler = (
      details: FilterSetData,
      unfavouriteId?: number) => {
      const updateFavourite$ = unfavouriteId
        ? this.filterManagementService.resetFavouriteStatus(unfavouriteId, details.budgetId)
        : of([]);
      updateFavourite$.pipe(
        switchMap(() => this.saveFilterSet(details, filterSet))
      ).subscribe(this.errorHandler);
    }

    this.dialog.open(SaveFilterModalComponent, {
      width: dialogContext.width,
      data: dialogContext,
      autoFocus: false,
      panelClass: 'filter-dialog'
    });
  }

  openSavedFiltersModal() {
    this.closeDrawer();
    const dialogContext: FilterDialogContext = FilterDialogConfig[FilterDialogState.Select];

    dialogContext.context = {
      onSelectActive: (selectedFilterId) => this.selectActiveFilterSet(selectedFilterId),
      onRemove: (id) => { this.removeFilterSet(id).subscribe(this.errorHandler) },
      onUpdateFilterSet: (filterData: FilterSetData, unfavouriteId?: number) => {
        const onUpdate$ = this.updateFilterSet(filterData);
        const updateFavourite$ = unfavouriteId
          ? this.filterManagementService.resetFavouriteStatus(unfavouriteId, filterData.budgetId)
          : of([]);
        updateFavourite$.pipe(
          switchMap(() => onUpdate$)
        ).subscribe(this.errorHandler);
      },
      budgetData: this.budgetData || this.budgetDataService.selectedBudgetSnapshot,
      owner: this.owner,
      savedFilters : this.savedFilters,
      selectedFilterId: this.filterManagementService.activeFilterSetId,
      companyUsers: this.companyUsers,
      favouriteFilter: this.savedFilters.find(item => item.isFavourite)
    };

    this.dialog.open(SaveFilterModalComponent, {
      width: dialogContext.width,
      data: dialogContext,
      autoFocus: false,
      panelClass: 'filter-dialog'
    });
  }

  handleError(error, message = 'Request has failed') {
    console.error(message + '. ' + JSON.stringify(error));
    if (error && !error.message) {
      error.message = message;
    }
    this.utilityService.handleError(error);
  }

  ngOnDestroy() {
    this.expenseCFFiltersSubscription?.unsubscribe();
    this.managePageCFFiltersSubscription?.unsubscribe();
    this.budgetCompanyChangeSub?.unsubscribe();
  }
}
