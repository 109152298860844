<mat-form-field
  appearance="outline"
  class="outline filter-control no-label no-validate"
  [class.active]="selectedOptions?.length"
  [ngClass]="'ut-header-filter-' + filter.fieldName"
>
  <mat-select
    [formControl]="selectControl"
    [placeholder]="filter.title + ' ('+ selectedGroupsLength +')'"
    (closed)="updateSelectedOptions()"
    (opened)="updateAllSelectionState()"
    (valueChange)="handleSelectionChange()"
    multiple
    [disableOptionCentering]="true"
    [panelClass]="{
      'filter-select': true,
      'group-mode': hasGroups,
      'control-has-search': !filter.hideSearch
    }"
  >
    <mat-select-trigger>
      {{ filter.title }} ({{selectedOptions?.length}})
    </mat-select-trigger>

    <div class="sticky-head">
      <search-control
        *ngIf="!filter.hideSearch"
        [placeholder]="'Search ' + filter.plural"
        (textChanged)="filterItemsByText($event, filter.skipGroupsSelection)"
      ></search-control>
      <div class="mat-options-on-top" *ngIf="showedOptions[showedOptions.length - 1] as option">
        <mat-option *ngIf="option?.alwaysOnTop"
                    [disabled]="filter.type === FilterType.CustomFieldFilter && allSelectionState === CheckboxValue.Active"
                    [ngStyle]=" filter.type === FilterType.CustomFieldFilter && allSelectionState === CheckboxValue.Active ? { 'opacity': '0.4' } : {}"
                    [value]="option.value">{{ checkDisabledTag(option.title) }}
        </mat-option>
      </div>
      <div class="mat-option select-all">
        <arrow-toggler
          *ngIf="hasGroups"
          [active]="!groupsCollapseState.allCollapsed"
          (change)="toggleAllGroupsCollapse(!$event, showedOptions)">
        </arrow-toggler>
        <mat-checkbox color="primary"
                      [disabled]="noOptionSelected"
                      [checked]="allSelectionState === CheckboxValue.Active"
                      [indeterminate]="allSelectionState === CheckboxValue.Indeterminate"
                      (change)="toggleAllSelection($event.checked)"
                      [disableRipple]="true">
          All {{ filter.plural }}
        </mat-checkbox>
      </div>
    </div>

    <ng-container *ngFor="let option of searchFilteredOptions">

      <ng-container *ngIf="option.children?.length">
        <div class="mat-option select-group"
             [ngClass]="{ 'visually-hidden': option.hidden }"
        >
          <arrow-toggler [active]="!groupsCollapseState.collapsed[option.value]"
                         (change)="toggleGroupCollapse($event, option.value)"></arrow-toggler>
          <mat-checkbox
            color="primary"
            [checked]="groupsSelectionState[option.value]?.allSelected"
            [indeterminate]="groupsSelectionState[option.value]?.partialSelected"
            (change)="toggleGroupSelection($event.checked, option)"
            [disableRipple]="true"
          >
            <b>{{ option.title }}</b> ({{ option.children.length }})
          </mat-checkbox>
        </div>
        <mat-option *ngFor="let childOption of option.children"
                    (click)="updateGroupSelectionState(option)"
                    class="group-option"
                    [ngClass]="{ 'visually-hidden': childOption.hidden || !groupsCollapseState.collapsed[option.value] }"
                    [value]="childOption.value">{{ checkDisabledTag(childOption.title) }}</mat-option>

      </ng-container>

      <mat-option *ngIf="!option.children?.length && !option.alwaysOnTop"
                  [ngClass]="{'visually-hidden': option.hidden}"
                  [value]="option.value">{{ checkDisabledTag(option.title) }}</mat-option>
    </ng-container>
  </mat-select>
</mat-form-field>
