import { inject, Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, combineLatest, forkJoin, merge, Observable, of, Subject } from 'rxjs';
import { filter, finalize, map, skip, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { BudgetDataService } from '../../dashboard/budget-data/budget-data.service';
import { Budget, BudgetTimeframesType } from '@shared/types/budget.interface';
import { AppRoutingService } from '@shared/services/app-routing.service';
import { Configuration } from 'app/app.constants';
import {
  BudgetTimeframeBrief,
  CreateCegItemTemplateEvent,
  ManageCegDataMode,
  ManageCegTableActionDataSource,
  ManageCEGTableContextData,
  ManageCegTableRow,
  ManageCegTableSelectionState,
  ManageCegViewMode,
  ManagePageExportParams,
  ManageTablePerformanceColumnName,
  ManageTableTotalValues,
  PresentationTimeframe
} from '@manage-ceg/types/manage-ceg-page.types';
import { ManageCegPageModeService } from '@manage-ceg/services/manage-ceg-page-mode.service';
import { BudgetTimeframe } from '@shared/types/timeframe.interface';
import { ManageCegTableDataService } from '@manage-ceg/services/manage-ceg-table-data.service';
import { UserManager } from '../../user/services/user-manager.service';
import { UserDataService } from '@shared/services/user-data.service';
import { BudgetObjectType } from '@shared/types/budget-object-type.interface';
import { createDeepCopy, getIdToNameMap } from '@shared/utils/common.utils';
import { CompanyDataService } from '@shared/services/company-data.service';
import { ManageTableHelpers } from '../../manage-table/services/manage-table-helpers';
import { RecordInteractionService } from '@manage-ceg/services/record-interaction.service';
import { BudgetObjectDialogService } from '@shared/services/budget-object-dialog.service';
import { SummaryBarItem } from '@shared/components/budget-summary-bar/budget-summary-bar.types';
import { Currency } from '@shared/types/currency.interface';
import { BudgetSummaryBarHelpers } from '@shared/components/budget-summary-bar/budget-summary-bar.helpers';
import { FilterName, FilterSet } from 'app/header-navigation/components/filters/filters.interface';
import { CloneableRowTypes } from '../../manage-table/components/manage-table/manage-table.constants';
import { CheckboxValue } from '@shared/enums/checkbox-value.enum';
import { HierarchyDialogContext } from '../../manage-table/components/hierarchy-select-modal/hierarchy-select-modal.types';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { HierarchySelectModalComponent } from '../../manage-table/components/hierarchy-select-modal/hierarchy-select-modal.component';
import { HierarchySelectItem } from '@shared/components/hierarchy-select/hierarchy-select.types';
import { BulkActionTargets } from '@shared/types/bulk-action-targets.type';
import { ManageTableBasicAction } from '@shared/manage-table-actions/manage-table-basic-action';
import { BudgetObjectSegmentData } from '@shared/types/budget-object-segment-data.interface';
import { ExpenseCostAdjustmentDataService } from '../../metric-integrations/expense-cost-adjustment/expense-cost-adjustment-data.service';
import { LightCampaign } from '@shared/types/campaign.interface';
import { LightProgram } from '@shared/types/program.interface';
import { BudgetSegmentAccess } from '@shared/types/segment.interface';
import { SharedCostRule } from '@shared/types/shared-cost-rule.interface';
import { Goal } from '@shared/types/goal.interface';
import { SegmentGroup } from '@shared/types/segment-group.interface';
import { CompanyDO } from '@shared/types/company.interface';
import { AddMetricDialogComponent } from '../../manage-table/components/add-metric-dialog/add-metric-dialog.component';
import { MetricService } from '@shared/services/backend/metric.service';
import { UtilityService } from '@shared/services/utility.service';
import { MetricType } from '@shared/types/budget-object-metric.interface';
import { ProductDO } from '@shared/services/backend/product.service';
import { ManageCegTableDataMutationService } from '@manage-ceg/services/manage-ceg-table-data-mutation.service';
import { FilterManagementService, ParamsDef } from '../../header-navigation/components/filters/filter-services/filter-management.service';
import { BudgetObjectDetailsManager } from '../../budget-object-details/services/budget-object-details-manager.service';
import { ActiveToast } from 'ngx-toastr';
import { ObjectAccessManagerService } from '@shared/services/object-access-manager.service';
import { BudgetPlanObjects } from '@shared/types/budget-plan-objects.type';
import { CampaignService } from '@shared/services/backend/campaign.service';
import { ProgramService } from '@shared/services/backend/program.service';
import { CompanyUserDO } from '@shared/types/company-user-do.interface';
import { ManageTableGrandTotalsService } from '@shared/services/backend/manage-table-grand-totals.service';
import {
  calculatePresentationSum,
  convertTfAmountsDOToBudgetTotals,
  getTotalsForTimeframes
} from '@manage-ceg/services/manage-ceg-table-row-data/amounts-loader.helpers';
import { DeleteObjectsResult, UpdateObjectsStatusResult } from '@manage-ceg/services/manage-ceg-page-api.service';
import { ManageCegPageTagsService } from '@manage-ceg/services/manage-ceg-page-tags.service';
import { BudgetAllocationActionsService } from '../../budget-allocation/services/budget-allocation-actions.service';
import { ManageTableActionHistoryService } from '@shared/services/manage-table-action-history.service';
import { BudgetAllocationAction } from 'app/budget-allocation/budget-allocation-gestures-actions/budget-allocation-action.types';
import { ManageCEGTableDataBuilderInputs } from '@manage-ceg/types/manage-ceg-table-data-builder.types';
import { SegmentMenuHierarchyService } from '@shared/services/segment-menu-hierarchy.service';
import { LocationService } from 'app/budget-object-details/services/location.service';
import { BudgetObjectService } from '@shared/services/budget-object.service';
import { ManageTableChangeSegmentAction } from '@shared/manage-table-actions/manage-table-change-segment.action';
import { ManageTableChangeParentAction } from '@shared/manage-table-actions/manage-table-change-parent.action';
import { ManageTableRowType } from '@shared/enums/manage-table-row-type.enum';
import { ObjectMode } from '@shared/enums/object-mode.enum';
import { BudgetService } from '@shared/services/backend/budget.service';
import { ManageCegTableHelpers } from '@manage-ceg/services/manage-ceg-table-helpers';
import { messages, objectCounter } from 'app/budget-object-details/messages';
import { BudgetObject, BudgetObjectEvent } from 'app/budget-object-details/types/budget-object-event.interface';
import { ExpenseEventHandler } from '@manage-ceg/services/budget-object-event-handler/expense-event-handler';
import { ProgramEventHandler } from '@manage-ceg/services/budget-object-event-handler/program-event-handler';
import { CampaignEventHandler } from '@manage-ceg/services/budget-object-event-handler/campaign-event-handler';
import { GoalEventHandler } from '@manage-ceg/services/budget-object-event-handler/goal-event-handler';
import { BudgetObjectEventHandler } from '@manage-ceg/types/budget-object-event-handler.types';
import { MetricMappingChange } from 'app/budget-object-details/types/budget-object-change.interface';
import { MetricMappingDetailsService } from 'app/budget-object-details/services/metric-mapping-details.service';
import { HierarchyViewMode } from '@spending/types/expense-page.type';
import { BackNavigationContext } from '../../manage-table/services/manage-page.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { UpdateObjectsResult } from '../../manage-table/services/manage-page-api.service';
import { ManageCegTableConfigurationService } from '@manage-ceg/services/manage-ceg-table-configuration.service';
import { EXPORT_TYPES, ExportDataService } from '../../dashboard/export-data.service';
import { CEGStatus } from '@shared/enums/ceg-status.enum';
import { AmountsDO } from '@shared/types/object-amounts.interface';


@Injectable()
export class ManageCegPageService implements OnDestroy {
  private readonly config = inject(Configuration);
  private readonly appRoutingService = inject(AppRoutingService);
  private readonly userDataService = inject(UserDataService);
  private readonly userManager = inject(UserManager);
  private readonly budgetDataService = inject(BudgetDataService);
  private readonly tableDataService = inject(ManageCegTableDataService);
  private readonly companyDataService = inject(CompanyDataService);
  private readonly recordInteractionService = inject(RecordInteractionService);
  private readonly dialogService = inject(BudgetObjectDialogService);
  private readonly matDialog = inject(MatDialog);
  private readonly expenseCostAdjustmentDataService = inject(ExpenseCostAdjustmentDataService);
  private readonly tagsManager = inject(ManageCegPageTagsService);
  private readonly metricService = inject(MetricService);
  private readonly utilityService = inject(UtilityService);
  private readonly dataMutationService = inject(ManageCegTableDataMutationService);
  private readonly filterManagementService = inject(FilterManagementService);
  private readonly budgetObjectDetailsManager = inject(BudgetObjectDetailsManager);
  private readonly managePageModeService = inject(ManageCegPageModeService);
  private readonly objectAccessManager = inject(ObjectAccessManagerService);
  private readonly campaignService = inject(CampaignService);
  private readonly programService = inject(ProgramService);
  private readonly actionsManager: BudgetAllocationActionsService<ManageCegTableActionDataSource> = inject(BudgetAllocationActionsService);
  private readonly historyManager: ManageTableActionHistoryService<BudgetAllocationAction<any>> = inject(ManageTableActionHistoryService);
  private readonly segmentMenuService = inject(SegmentMenuHierarchyService);
  private readonly locationService = inject(LocationService);
  private readonly manageTableGrandTotalsService = inject(ManageTableGrandTotalsService);
  private readonly budgetService = inject(BudgetService);
  private readonly metricMappingDetailsService = inject(MetricMappingDetailsService);
  private readonly routingService = inject(AppRoutingService);
  private readonly router = inject(Router);
  private readonly activatedRoute = inject(ActivatedRoute);
  private readonly manageTableConfigurationService = inject(ManageCegTableConfigurationService);

  private readonly destroy$ = new Subject<void>();

  private allowedSegmentBreakdownFilters = [FilterName.Segments, FilterName.Timeframes];
  private isAdminUser: boolean;
  private currentCompanyUser: CompanyUserDO = null;
  private timeframesAll: Record<BudgetTimeframesType, BudgetTimeframeBrief[]>;
  private _budgetTimeframes$ = new BehaviorSubject<BudgetTimeframeBrief[]>([]);
  private viewMode: ManageCegViewMode;
  public budgetTimeframes$ = this._budgetTimeframes$.asObservable().pipe(filter(tf => !!tf.length));
  public summaryBarItems: SummaryBarItem[][] = null;
  public summaryBarLoading$ = new BehaviorSubject<boolean>(true);
  public company: CompanyDO;
  public budgetCurrency: Currency;
  private currentFilters: FilterSet;
  private readonly OBJECT_TYPES = this.config.OBJECT_TYPES;
  private segmentGroups: SegmentGroup[] = [];
  private budgetObjectsLoadingState = {
    goals: false,
    campaigns: false,
    expGroups: false,
  };
  private metrics: MetricType[] = [];
  private products: ProductDO[] = [];
  private backNavToast: ActiveToast<any>;
  private budgetPlanObjects: BudgetPlanObjects;
  private objectsContainerByRowType = {};

  public budgetObjectTypes: BudgetObjectType[] = [];
  public objectTypeNameMap: Record<number, string> = {};
  public timeframes: BudgetTimeframe[] = [];
  public filteredTimeframes: BudgetTimeframe[] = [];
  public hierarchyItems: HierarchySelectItem[];
  public segments: BudgetSegmentAccess[] = [];
  public sharedCostRules: SharedCostRule[] = [];
  public editPermission = false;
  public budget: Budget;
  public reflectFiltersInLocation = true;
  public segmentBreakdownRestrictedByFilters = false;
  private budgetObjectEventHandlers: Partial<Record<BudgetObject, BudgetObjectEventHandler>> = {};
  private readonly campaignEventHandler: CampaignEventHandler;
  private readonly programEventHandler: ProgramEventHandler;
  private readonly goalEventHandler: GoalEventHandler;

  public static mapTimeframesToIds(timeframes: BudgetTimeframeBrief[]): (string | number)[] {
    return timeframes
      .filter(tf => tf.isVisible)
      .map(tf => tf.id);
  }

  constructor() {
    this.initSubscriptions();

    const loadPlanObjects = () => {
      const { viewMode, segments, sharedCostRules, campaigns, isPowerUser } = this.tableDataService.tableDataInputs;
      return this.loadPlanObjects$(viewMode, segments, sharedCostRules, campaigns, isPowerUser);
    };

    this.programEventHandler = new ProgramEventHandler(this.tableDataService, this.config, this.budgetDataService, loadPlanObjects);
    this.campaignEventHandler = new CampaignEventHandler(this.tableDataService, this.config,  this.budgetDataService, loadPlanObjects);
    this.goalEventHandler = new GoalEventHandler(this.tableDataService, this.config, this.budgetDataService);

    this.budgetObjectEventHandlers = {
      [BudgetObject.Expense]: new ExpenseEventHandler(this.tableDataService, this.config),
      [BudgetObject.Program]: this.programEventHandler,
      [BudgetObject.Campaign]: this.campaignEventHandler,
      [BudgetObject.Goal]: this.goalEventHandler
    };

    this.metricMappingDetailsService.metricMappingChanged$
      .pipe(takeUntil(this.destroy$))
      .subscribe(change => this.handleMetricMappingChange(change));

    merge(this.activatedRoute.url, this.activatedRoute.queryParams, this.appRoutingService.triggerInitBackNavToast$)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.initBackNavToast())

    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => this.setTableTimeframeFromDrawerTimeframe());
  }

  private initBackNavToast(): void {
    const prevLocation = this.router.getCurrentNavigation()?.extras.state?.backNavToastFor;
    if (prevLocation) {
      if (this.backNavToast) {
        this.hideBackNavToast();
      }
      this.backNavToast = this.utilityService.showToastrNav(prevLocation);
    }
  }

  get isAdmin(): boolean {
    return this.isAdminUser;
  }

  private getSelectedItems(): ManageCegTableSelectionState {
    return this.recordInteractionService.selectionState;
  }

  private initSubscriptions(): void {
    this.companyDataService.selectedCompanyDO$.pipe(
      filter(company => company != null),
      takeUntil(this.destroy$)
    ).subscribe(company => this.onSelectedCompanyChanged(company));

    this.userManager.currentCompanyUser$.pipe(
      filter(user => user != null),
      takeUntil(this.destroy$)
    ).subscribe(user => {
      this.isAdminUser = this.userDataService.isAdmin(user);
    });

    this.managePageModeService.presentationTimeframeIds$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(selectedTimeframeIds => {
      const timeframeMode = this.managePageModeService.presentationTimeframeMode;
      this.applyPresentationTimeframes(timeframeMode, selectedTimeframeIds);
    });

    combineLatest([
      this.budgetTimeframes$.pipe(map(ManageCegPageService.mapTimeframesToIds)),
      this.tableDataService.grandTotalBudget$.pipe(filter(value => !!value))
    ]).pipe(
      takeUntil(this.destroy$)
    ).subscribe(([timeframeIds, grandTotal]) => {
      this.tableDataService.grandTotalForActiveTimeframes$.next(getTotalsForTimeframes(timeframeIds, grandTotal));
    });

    this.userDataService.editPermission$
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(editPermission => this.editPermission = editPermission);

    this.companyDataService.campaignTypesList$
      .pipe(takeUntil(this.destroy$))
      .subscribe((budgetObjectType: BudgetObjectType[]) => {
        this.budgetObjectTypes = budgetObjectType;
        this.objectTypeNameMap = getIdToNameMap(this.budgetObjectTypes);
      });

    this.budgetObjectDetailsManager.getMetricTypes()
      .pipe(takeUntil(this.destroy$))
      .subscribe(metrics => this.metrics = metrics);

    this.companyDataService.products$
      .pipe(takeUntil(this.destroy$))
      .subscribe(products => this.products = products);

    this.dataMutationService.grandTotalUpdateTrigger$
      .pipe(takeUntil(this.destroy$))
      .subscribe(dataInputs => this.getGrandTotals(dataInputs));

    this.dataMutationService.allocationUpdateTrigger$
      .pipe(takeUntil(this.destroy$))
      .subscribe(rows => this.handleAllocationUpdate(BudgetObject.Campaign, rows));

    this.initBudgetDataListeners();

    this.budgetObjectDetailsManager.budgetObjectEvent$
      .pipe(takeUntil(this.destroy$))
      .subscribe(budgetObjectEvent => this.handleBudgetObjectEvent(budgetObjectEvent));
  }

  private applyPresentationTimeframes(timeframeMode, selectedTimeframeIds) {
    Object.entries(this.timeframesAll).forEach(([tfGroupId, timeframesList]) => {
      timeframesList.forEach(tf =>
        tf.isVisible = timeframeMode === tfGroupId && !selectedTimeframeIds?.length
          || timeframeMode === tfGroupId && selectedTimeframeIds?.includes(tf.id)
      );
    });
    this._budgetTimeframes$.next([
      ...this.timeframesAll[BudgetTimeframesType.Month],
      ...this.timeframesAll[BudgetTimeframesType.Quarter],
      ...this.timeframesAll[BudgetTimeframesType.Year],
    ]);

    this.managePageModeService.saveTimeframeMode(this.budget.id);
  }

  private objectListChange$(): Observable<[LightCampaign[], LightProgram[], Goal[], BudgetSegmentAccess[]]> {
    return combineLatest([
      this.budgetDataService.lightCampaignList$,
      this.budgetDataService.lightProgramList$,
      this.budgetDataService.goalList$,
      this.budgetDataService.segmentList$
    ]);
  }

  private initBudgetDataListeners() {
    const currentFilters$ =
      this.filterManagementService.budgetFiltersInit$.pipe(
        switchMap(() => this.filterManagementService.currentFilterSet$),
        skip(1),
        tap(filterSet => this.onCurrentFiltersChange(filterSet))
      );

    let prevFilters = this.currentFilters;

    this.objectListChange$().pipe(
      skip(1),
      tap(() => this.summaryBarLoading$.next(true)),
      takeUntil(this.destroy$)
    ).subscribe(([campaigns, expGroups, goals, segments]) => {
      this.summaryBarLoading$.next(false);
      this.summaryBarItems = BudgetSummaryBarHelpers.getSummaryBarItems({
        totalObjects: {
          goals: goals?.length || 0,
          segments: segments?.length || 0,
          campaigns: this.getCampaignCountForSegmentView(this.viewMode, campaigns),
          expGroups: expGroups?.length || 0,
        },
        filteredObjects: this.budgetPlanObjects,
        isFilterMode: this.tableDataService.isFilteredMode,
        configuration: this.config,
      });
    })

    this.budgetDataService.selectedBudget$.pipe(
      tap(budget => this.onSelectedBudgetChanged(budget)),
      switchMap(
        budget => combineLatest([this.initTableData$(budget), currentFilters$])
      ),
      switchMap((result: [ManageCEGTableContextData, FilterSet]) => {
        this.summaryBarLoading$.next(true);
        const [data, filterSet] = result;
        const filtersChanged =
          prevFilters == null && filterSet != null && Object.keys(filterSet).length > 0 ||
          prevFilters != null && prevFilters !== filterSet;

        prevFilters = filterSet;

        return this.getPlanObjectsList$(data, filtersChanged).pipe(
          tap(() => this.getGrandTotals(data)),
          map(([planObjects, expGroups, campaigns]) => [{ ...data, campaigns, expGroups }, planObjects])
        );
      }),
      takeUntil(this.destroy$)
    ).subscribe({
      next: ([data, planObjects]: [ManageCEGTableContextData, BudgetPlanObjects]) => this.onPlanObjectsReady(data, planObjects),
      error: error => {
        this.utilityService.handleError({ message: 'Failed to load table data'} );
        console.error(error);
        this.tableDataService.setLoading(false);
      }
    });
  }

  private initTableData$(budget: Budget): Observable<ManageCEGTableContextData> {
    const timeframes$ = this.budgetDataService.timeframeList$.pipe(
      tap(data => {
        this.timeframes = data;
        this.applyBudgetTimeframes(data, budget);
      })
    );

    const pageModes$ =
      this.managePageModeService.pageModes$.pipe(
        filter(modes => modes?.dataMode != null && modes?.viewModeChange?.value != null)
      );

    const campaigns$ = this.budgetDataService.lightCampaignList$.pipe(
      take(1),
      tap(() => this.budgetObjectsLoadingState.campaigns = true)
    );

    const expGroups$ = this.budgetDataService.lightProgramList$.pipe(
      take(1),
      tap(() => this.budgetObjectsLoadingState.expGroups = true)
    );

    const goals$ = this.budgetDataService.goalList$.pipe(
      take(1),
      tap(() => this.budgetObjectsLoadingState.goals = true)
    );

    const segments$ = this.budgetDataService.segmentList$.pipe(
      tap(data => this.segments = data)
    );

    const segmentGroups$ = this.budgetDataService.segmentGroupList$.pipe(
      tap(data => this.segmentGroups = data)
    );

    const sharedCostRules$ = this.budgetDataService.sharedCostRuleList$.pipe(
      tap(data => this.sharedCostRules = data)
    );

    const currentUser$ = this.userManager.currentCompanyUser$.pipe(
      filter(user => user != null),
      tap(user => {
        this.isAdminUser = this.userDataService.isAdmin(user);
        this.currentCompanyUser = user;
      })
    );

    return combineLatest([
      timeframes$,
      pageModes$,
      campaigns$,
      expGroups$,
      goals$,
      segments$,
      segmentGroups$,
      sharedCostRules$,
      currentUser$
    ]).pipe(
      filter(() => Object.values(this.budgetObjectsLoadingState).every(state => state)),
      map(
        data => {
          const [
            timeframes,
            pageModes,
            campaigns,
            expGroups,
            goals,
            segments,
            segmentGroups,
            sharedCostRules,
            currentCompanyUser,
          ] = data;

          return  {
            viewModeChange: pageModes.viewModeChange,
            dataMode: pageModes.dataMode,
            timeframes,
            pageModes,
            campaigns,
            expGroups,
            goals,
            segments,
            segmentGroups,
            sharedCostRules,
            currentCompanyUser
          };
        })
    );
  }

  private getGrandTotals(dataInputs: Partial<ManageCEGTableContextData>): void {
    this.summaryBarLoading$.next(true);

    const loadTotals$ = this.getGrandTotalsLoader(dataInputs.dataMode)?.(dataInputs) || of(null);

    loadTotals$.pipe(
      takeUntil(this.destroy$),
      finalize(() => this.summaryBarLoading$.next(false))
    ).subscribe({
      error: err => this.utilityService.handleError(err)
    });
  }

  private getGrandTotalsLoader(dataMode: ManageCegDataMode): (dataInputs: Partial<ManageCEGTableContextData>) => Observable<any> {
    const loaders = {
      [ManageCegDataMode.Budget]: dataInputs => this.getGrandTotalsForBudgetMode(dataInputs),
      [ManageCegDataMode.Performance]: () => this.getGrandTotalsForPerformanceMode()
    };
    return loaders[dataMode];
  }

  private getGrandTotalsForBudgetMode(dataInputs: ManageCEGTableContextData): Observable<any> {
    const activeViewMode = this.managePageModeService.viewMode;
    const filterParamsObj = this.getCommonParamsForObjectsRequest(activeViewMode, dataInputs.segments, dataInputs.campaigns);

    return this.manageTableGrandTotalsService.getBudgetGrandTotals(this.budget.id, activeViewMode, filterParamsObj).pipe(
      tap(
        totals => {
          const budgetAllocations =
            Object.entries(totals).reduce(
              (allocations: Record<PresentationTimeframe, ManageTableTotalValues>, [tfId, amounts]) => {
                allocations[tfId] = convertTfAmountsDOToBudgetTotals(amounts as AmountsDO);
                return allocations;
              },
              {} as Record<PresentationTimeframe, ManageTableTotalValues>
            );

          const presentationAllocations = calculatePresentationSum(budgetAllocations, this.timeframesAll);
          this.tableDataService.setBudgetGrandTotals({ ...budgetAllocations, ...presentationAllocations });
        }
      )
    );
  }

  private getGrandTotalsForPerformanceMode(): Observable<any> {
    return this.budgetService.getEstimatedBusinessValue(this.budget.id).pipe(
      map(businessValueData => {
        const { all_campaigns, return_on_marketing_plan, budget_details } = (businessValueData?.results || {});

        return {
          [ManageTablePerformanceColumnName.TargetReturn]: [all_campaigns?.target_return],
          [ManageTablePerformanceColumnName.CurrentReturn]: [all_campaigns?.current_return],
          [ManageTablePerformanceColumnName.LowForecast]: [all_campaigns?.low_forecast],
          [ManageTablePerformanceColumnName.HighForecast]: [all_campaigns?.high_forecast],
          [ManageTablePerformanceColumnName.TargetRoi]: [null, return_on_marketing_plan?.target_roi],
          [ManageTablePerformanceColumnName.CurrentRoi]: [null, return_on_marketing_plan?.current_roi],
          [ManageTablePerformanceColumnName.Owner]: [ManageCegTableHelpers.getInitials(budget_details?.budget_owner_name)]
        };
      }),
      tap(totals => this.tableDataService.setPerformanceGrandTotals(totals))
    );
  }

  private getFilteredByAccessObjects<T extends LightProgram | LightCampaign>(
    objects: T[],
    segments: BudgetSegmentAccess[],
    sharedCostRules: SharedCostRule[],
    isPowerUser: boolean
  ): T[] {
    return isPowerUser ?
      objects :
      objects
        .filter(obj =>
          this.objectAccessManager.hasAccessBySegmentData(
            { split_rule: obj.splitRuleId, company_budget_segment1: obj.budgetSegmentId },
            segments,
            sharedCostRules
          )
        );
  }


  private getPlanObjectsList$(
    tableData: ManageCEGTableContextData,
    filtersChanged: boolean
  ): Observable<[BudgetPlanObjects, LightProgram[], LightCampaign[]]> {
    this.tableDataService.setLoading(true);

    const { viewModeChange, campaigns, expGroups, segments, sharedCostRules, currentCompanyUser } = tableData;
    const shouldLoadPlanObjects =
      filtersChanged ||
      viewModeChange.value === ManageCegViewMode.Segments ||
      viewModeChange.prevValue === ManageCegViewMode.Segments;

    const needToGetAllPseudoObjects = viewModeChange.value === ManageCegViewMode.Segments && sharedCostRules.length > 0;
    const segmentIds = segments.map(seg => seg.id).join(',');
    const isPowerUser = this.userDataService.isPowerUser(currentCompanyUser);

    const getSegmentedObjects$ = <T extends LightCampaign | LightProgram>(
      objectsLoader: (companyId: number, budgetId: number, status: string, params: object) => Observable<T[]>,
      objectStatus: string,
      currentSegmentedObjects: T[]
    ) => {
      const needToGetAllSegmentedPseudoObjects = needToGetAllPseudoObjects && currentSegmentedObjects.some(obj => obj.splitRuleId != null);
      const filterParams = { company_budget_segment1_ids: segmentIds };

      return needToGetAllSegmentedPseudoObjects || !isPowerUser ?
        objectsLoader(
          this.company.id,
          this.budget.id,
          objectStatus,
          needToGetAllSegmentedPseudoObjects ? { ...filterParams, include_pseudo_objects: true } : filterParams
        ).pipe(
          map(objects => this.getFilteredByAccessObjects(objects, segments, sharedCostRules, isPowerUser))
        ) :
        of([...currentSegmentedObjects]);
    };

    const allPlanObjects$ = forkJoin([
      getSegmentedObjects$(
        this.budgetDataService.getLightPrograms.bind(this.budgetDataService),
        this.config.programStatusNames.active,
        expGroups
      ),
      getSegmentedObjects$(
        this.budgetDataService.getLightCampaigns.bind(this.budgetDataService),
        this.config.campaignStatusNames.active,
        campaigns
      )
    ]);

    const planObjects$ =
      shouldLoadPlanObjects ?
        this.loadPlanObjects$(viewModeChange.value, segments, sharedCostRules, campaigns, isPowerUser) :
        of(this.budgetPlanObjects || { campaigns: [...campaigns], expGroups: [...expGroups] });

    return forkJoin([planObjects$, allPlanObjects$]).pipe(
      tap(([planObjects]) => this.budgetPlanObjects = planObjects),
      map(([planObjects, allPlanObjects]) => [planObjects, ...allPlanObjects])
    );
  }

  private loadPlanObjects$(
    viewMode: ManageCegViewMode,
    segments: BudgetSegmentAccess[],
    sharedCostRules: SharedCostRule[],
    campaigns: LightCampaign[],
    isPowerUser: boolean
  ): Observable<BudgetPlanObjects> {
    const filterParamsObj = this.getCommonParamsForObjectsRequest(viewMode, segments, campaigns);
    const hasPONumberFilter = !!filterParamsObj['po_numbers'];

    const programs$ =
      this.budgetDataService.getLightPrograms(
        this.company.id,
        this.budget.id,
        this.config.programStatusNames.active,
        filterParamsObj
      ).pipe(
        map(objects => this.getFilteredByAccessObjects(objects, segments, sharedCostRules, isPowerUser)),
      );

    const campaigns$ = hasPONumberFilter
      ? of([])
      : this.budgetDataService.getLightCampaigns(
        this.company.id,
        this.budget.id,
        this.config.campaignStatusNames.active,
        filterParamsObj
      ).pipe(
        map(objects => this.getFilteredByAccessObjects(objects, segments, sharedCostRules, isPowerUser)),
      );

    return forkJoin({ expGroups: programs$, campaigns: campaigns$ });
  }

  private getCommonParamsForObjectsRequest(
    viewMode: ManageCegViewMode,
    allSegments: BudgetSegmentAccess[],
    campaigns: LightCampaign[]
  ): object {
    const isViewBySegment = viewMode === ManageCegViewMode.Segments;
    const requestData = {
      'filtering_behaviour': 'children_agnostic' // For new world only
    };
    const requestParamsDef: ParamsDef = {
      'company_budget_allocation_ids': { defaultValue: () => this.timeframes.map(tf => tf.id)  },
      'goal_ids': { filterName: FilterName.Goals },
      'tag_ids': { filterName: FilterName.Tags },
      'owner_ids': { filterName: FilterName.Owners },
      'campaign_ids': {
        filterName: FilterName.Campaigns,
        defaultValue: () => this.filterManagementService.getFilterCampaignIds(campaigns)
      },
      'object_type_ids': { filterName: FilterName.CampaignTypes },
      'program_ids': { filterName: FilterName.ExpenseBuckets },
      'expense_type_ids': { filterName: FilterName.ExpenseTypes },
      'gl_code_ids': { filterName: FilterName.GlCodes },
      'vendor_ids': { filterName: FilterName.Vendors },
      'split_rule_ids': { filterName: FilterName.SharedCostRules },
      'source': { filterName: FilterName.ExpenseSource },
      'metric_ids': { filterName: FilterName.Metrics },
      'company_budget_segment1_ids': {
        filterName: FilterName.Segments,
        defaultValue: () => isViewBySegment ?
          allSegments?.map(segment => segment.id) :
          this.filterManagementService.getDefaultSegments(allSegments)
      },
      'include_pseudo_objects': { defaultValue: () => isViewBySegment ? 'true' : null },
      'po_numbers': { filterName: FilterName.PONumber },
      'amount_status': { filterName: FilterName.CEGStatus }
    };
    this.filterManagementService.setParamsFromFilters(requestData, requestParamsDef, true, undefined, true);
    return requestData;
  }

  private onPlanObjectsReady(data: ManageCEGTableContextData, planObjects: BudgetPlanObjects): void {
    const {
      timeframes,
      viewModeChange,
      dataMode,
      campaigns,
      goals,
      segments,
      segmentGroups,
      sharedCostRules,
      currentCompanyUser
    } = data;

    const expGroups = this.currentFilters.metrics?.length ? [] : data.expGroups;
    this.viewMode = viewModeChange.value
    this.tableDataService.setFilteredMode(
      Object.values(this.currentFilters).some(fv => fv.length)
    );

    this.currentCompanyUser = currentCompanyUser;
    this.recordInteractionService.resetSelection();

     this.setHierarchyItems({
      viewMode: this.viewMode,
      campaigns,
      expGroups,
      goals,
      segmentGroups,
      segments,
      sharedCostRules,
    });

    this.objectsContainerByRowType = {
      [ManageTableRowType.Goal]: this.budgetDataService.goalsSnapshot,
      [ManageTableRowType.Campaign]: this.budgetDataService.lightCampaignsSnapshot,
      [ManageTableRowType.ExpenseGroup]: this.budgetDataService.lightProgramsSnapshot,
    };

    this.summaryBarItems = BudgetSummaryBarHelpers.getSummaryBarItems({
      totalObjects: {
        goals: goals?.length || 0,
        segments: segments?.length || 0,
        campaigns: this.getCampaignCountForSegmentView(this.viewMode, campaigns),
        expGroups: expGroups?.length || 0,
      },
      filteredObjects: planObjects,
      isFilterMode: this.tableDataService.isFilteredMode,
      configuration: this.config,
    });

    this.recordInteractionService.resetSelection();
    this.recordInteractionService.resetToggling();

    this.tableDataService.initTable({
      isFilterMode: this.tableDataService.isFilteredMode,
      timeframes,
      filteredTimeframes: this.filteredTimeframes,
      viewMode: viewModeChange.value,
      dataMode,
      campaigns,
      expGroups,
      goals,
      segmentGroups,
      segments,
      sharedCostRules,
      budget: this.budget,
      planObjects,
      isPowerUser: this.userDataService.isPowerUser(currentCompanyUser),
    });
    this.tagsManager.loadTags();
  }

  private onCurrentFiltersChange(filterSet: FilterSet): void {
    if (!FilterManagementService.hasSelectedFilters(filterSet)) {
      this.hideBackNavToast();
    }
    this.currentFilters = { ...filterSet };
    if (this.reflectFiltersInLocation) {
      this.appRoutingService.updateCurrentFiltersInRouting(
        this.company?.id,
        this.budget?.id,
        this.currentFilters
      );
    }

    this.segmentBreakdownRestrictedByFilters =
      Object.keys(filterSet)
        .filter(key => !this.allowedSegmentBreakdownFilters.includes(key as FilterName))
        .some(key => filterSet[key]?.length);
  }

  private onSelectedCompanyChanged(companyDO: CompanyDO): void {
    this.company = companyDO;
    this.companyDataService.loadCompanyData(companyDO.id);
    this.budgetCurrency = {
      code: companyDO.currency,
      name: companyDO.currency,
      symbol: companyDO.currency_symbol
    };
  }

  private onSelectedBudgetChanged(budget: Budget): void {
    if (!budget?.new_campaigns_programs_structure) {
      setTimeout(() => {
        this.appRoutingService.openPlanDetail(
          [this.config.OBJECT_TYPES.segment], { queryParamsHandling: 'preserve' }
        );
      });

      return;
    }

    this.budget = budget;
    this.loadBudgetObjects(budget);
    this.budgetObjectDetailsManager.loadBudgetCurrencyExchangeRates();
  }

  private loadBudgetObjects(budget: Budget, withGoals = false): void {
    if (!budget.id || !budget.company) {
      return;
    }

    this.budgetObjectsLoadingState = {
      goals: !withGoals,
      campaigns: false,
      expGroups: false,
    };

    if (withGoals) {
      this.budgetDataService.loadGoals(
        budget.company,
        budget.id,
        this.config.goalStatusNames.active,
        error => this.utilityService.handleError(error)
      );
    }

    this.budgetDataService.loadLightCampaigns(
      budget.company,
      budget.id,
      this.config.campaignStatusNames.active,
      error => this.utilityService.handleError(error)
    );

    this.budgetDataService.loadLightPrograms(
      budget.company,
      budget.id,
      this.config.programStatusNames.active,
      error => this.utilityService.handleError(error)
    );
  }

  private applyBudgetTimeframes(tfs: BudgetTimeframe[], budget: Budget): void {
    this.updateAllTimeframes(tfs);
    const budgetType: BudgetTimeframesType = budget.type as BudgetTimeframesType;
    const storageConfig = this.managePageModeService.storageTimeframeConfig;
    const mode = storageConfig?.budgetId === this.budget.id ? storageConfig.mode : budgetType;
    const ids = storageConfig?.budgetId === this.budget.id ? storageConfig.ids : [];

    if (storageConfig?.budgetId !== this.budget.id) {
      this.managePageModeService.removeTimeframeConfig();
    }

    this.managePageModeService.updatePresentationTimeframeOptions(budgetType);
    this.managePageModeService.setPresentationTimeframeMode(mode, ids);
  }

  private updateAllTimeframes(budgetTimeframes: BudgetTimeframe[]): void {
    const isMonthlyBudget = budgetTimeframes.length === 12;
    const generateBriefTimeframes = (tf: BudgetTimeframe): BudgetTimeframeBrief => ({
      id: tf.id,
      shortName: isMonthlyBudget ? tf.name.substring(0, 3) : tf.name,
      name: tf.name,
      locked: tf.locked,
      isVisible: false,
      isOriginal: true
    });
    const defaultQuarterTimeframes: BudgetTimeframeBrief[] = [
      { id: PresentationTimeframe.Q1, shortName: 'Q1', isVisible: false },
      { id: PresentationTimeframe.Q2, shortName: 'Q2', isVisible: false },
      { id: PresentationTimeframe.Q3, shortName: 'Q3', isVisible: false },
      { id: PresentationTimeframe.Q4, shortName: 'Q4', isVisible: false },
    ];
    const defaultYearTimeframes: BudgetTimeframeBrief[] = [
      { id: PresentationTimeframe.Year, shortName: 'Fiscal Year', isVisible: false },
    ];
    this.timeframesAll = {
      [BudgetTimeframesType.Month]: isMonthlyBudget ? budgetTimeframes.map(generateBriefTimeframes) : [],
      [BudgetTimeframesType.Quarter]: budgetTimeframes.length === 4 ? budgetTimeframes.map(generateBriefTimeframes) :
        isMonthlyBudget ? defaultQuarterTimeframes :
          [],
      [BudgetTimeframesType.Year]: budgetTimeframes.length === 1 ? budgetTimeframes.map(generateBriefTimeframes) : defaultYearTimeframes,
    };
    this.tableDataService.timeframesAll = this.timeframesAll;
  }

  private filterSelectItems(selection: ManageCegTableSelectionState): HierarchySelectItem[] {
    const currentMode = this.managePageModeService.viewMode;
    let hierarchyItems: HierarchySelectItem[] = createDeepCopy(this.hierarchyItems);

    const someCampaignSelected = !!selection.campaigns.size;
    const parentCampaignSelected = this.hasParentCampaignSelection(selection.campaigns);
    const topLevelParentId = -1;

    const resetChildren = (option: HierarchySelectItem) => {
      option.children = [];
      return option;
    };

    const mapIdsToObjectsArray = (idSet, objectsArray) => {
      return [...idSet].map( id => objectsArray.find(c => c.id === id));
    };

    const disableItemsInTree = (items: HierarchySelectItem[], idsList) => {
      items.forEach(item => {
        if (idsList.includes(item.objectId)) {
          item.notClickable = true;
        }
        if (item.children?.length) {
          disableItemsInTree(item.children, idsList);
        }
      });
    };

    const removeSelectedItemsFromTree = (items: HierarchySelectItem[], idsSet: Set<number>) => {
      items = items.filter(i => !idsSet.has(i.objectId));
      items.forEach(item => {
        if (item.children?.length) {
          item.children = removeSelectedItemsFromTree(item.children, idsSet);
        }
      });
      return items;
    };

    const getParentIds = (objects, key, altKey?): number[] => {
      return objects.reduce((arr, camp) => {
        // campaign can be child for: 1) Campaign 2) Goal 3) Segment
        const parentId = camp?.[key] || camp?.[altKey] || topLevelParentId;
        if (parentId && !arr.includes(parentId)) {
          arr.push(parentId);
        }
        return arr;
      }, []);
    };

    const selectedCampaigns = mapIdsToObjectsArray(selection.campaigns, this.budgetDataService.lightCampaignsSnapshot);
    const selectedExpGroups = mapIdsToObjectsArray(selection.expGroups, this.budgetDataService.lightProgramsSnapshot);
    const hasSegmentlessCampaign = selectedCampaigns.some(campaign => BudgetObjectService.isSegmentlessObject(campaign));

    const altCampParentKey = currentMode === ManageCegViewMode.Goals ? 'goalId' :
      currentMode === ManageCegViewMode.Segments ? 'budgetSegmentId' : null;
    const parentIds = [
      ...getParentIds(selectedCampaigns, 'parentCampaign', altCampParentKey),
      ...getParentIds(selectedExpGroups, 'campaignId'),
      ...getParentIds(selectedCampaigns, 'budgetSegmentId'),
      ...getParentIds(selectedExpGroups, 'budgetSegmentId'),
    ];

    const clearCampaigns = (items: HierarchySelectItem[]) => {
      items = items.filter(el => el.objectType !== this.OBJECT_TYPES.campaign);
      items.forEach(i => {
        i.children = (i.children || []).filter(el => el.objectType !== this.OBJECT_TYPES.campaign);
        i.children = clearCampaigns(i.children);
      });
      return items;
    };

    const clearChildCampaigns = (items: HierarchySelectItem[] = []) => {
      items.forEach(i => {
        if (i.objectType === this.OBJECT_TYPES.campaign) {
          i.children = [];
        } else {
          i.children = clearChildCampaigns(i.children);
        }
      });
      return items;
    };

    if (currentMode === ManageCegViewMode.Segments) {
      if (parentCampaignSelected) {
        // remove all campaigns
        hierarchyItems = clearCampaigns(hierarchyItems);
      } else if (someCampaignSelected) {
        // remove children from campaigns
        hierarchyItems = clearChildCampaigns(hierarchyItems);
      }
    }

    if (currentMode === ManageCegViewMode.Goals) {
      if (parentCampaignSelected || hasSegmentlessCampaign) {
        // remove all campaigns
        hierarchyItems = hierarchyItems
          .filter(el => el.objectType === this.OBJECT_TYPES.goal)
          .map(resetChildren);
      } else if (someCampaignSelected) {
        // remove children from campaigns
        hierarchyItems = clearChildCampaigns(hierarchyItems);
      }
    }

    if (currentMode === ManageCegViewMode.Campaigns) {
      if (someCampaignSelected) {
        hierarchyItems.forEach(resetChildren);
      }
    }

    disableItemsInTree(hierarchyItems, parentIds);
    return removeSelectedItemsFromTree(hierarchyItems, selection.campaigns);
  }

  private showCloseItemsDialog(targets: BulkActionTargets, saveToHistory = true): void {
    const itemsToClose = ManageTableHelpers.getBulkTargetsTotalCount(targets);
    const dialogTitle = messages.CLOSE_MULTIPLE_OBJECTS_TITLE.replace(objectCounter, itemsToClose.toString());

    this.dialogService.openConfirmationDialog({
      title: dialogTitle,
      content: messages.CLOSE_OBJECT_MSG,
      submitAction: {
        label: 'OK',
        handler: () => {
          const action = new ManageTableBasicAction({
            execute: () => {
              this.tableDataService.setLoading(true);
              this.recordInteractionService.resetSelection();
              this.dataMutationService.updateObjectsMode(
                targets,
                ObjectMode.Closed,
                () => {
                  this.tableDataService.setLoading(false);
                  this.tableDataService.triggerRefreshTable();
                }
              );
            },
            undo: () => {
              this.executeReopenItems(targets, false);
            }
          });
          // undo actions
          this.actionsManager.executeAction(action);
          if (saveToHistory) {
            this.historyManager.pushAction(action);
          }
        }
      },
      cancelAction: {
        label: 'Cancel',
        handler: null
      }
    },
    {
      width: '480px'
    });
  }

  private executeReopenItems(targets: BulkActionTargets, saveToHistory = true): void {
    const action = new ManageTableBasicAction({
      execute: () => {
        this.tableDataService.setLoading(true);
        this.recordInteractionService.resetSelection();
        this.dataMutationService.updateObjectsMode(
          targets,
          ObjectMode.Open,
          (result) => {
            const resultantCampaigns = result?.campaigns?.success || [];
            const resultantExpGroups = result?.expGroups?.success || [];
            const resultantTargets = [...resultantCampaigns, ...resultantExpGroups];
            const tableDataSnapshot = [...this.budgetDataService.lightCampaignsSnapshot, ...this.budgetDataService.lightProgramsSnapshot];
            resultantTargets.map(program => {
              tableDataSnapshot.filter(item => item.id === program.id)[0].mode = ObjectMode.Open;
            })
            this.tableDataService.triggerRefreshTable();
            this.tableDataService.setLoading(false);
          }
        );
      },
      undo: () => {
        this.showCloseItemsDialog(targets, false);
      }
    });
    // undo actions
    this.actionsManager.executeAction(action);
    if (saveToHistory) {
      this.historyManager.pushAction(action);
    }
  }

  private showDeleteItemsDialog(targets: BulkActionTargets): void {
    const itemsToDelete = ManageTableHelpers.getBulkTargetsTotalCount(targets);
    this.expenseCostAdjustmentDataService.setIntegrationExpenses(this.budgetDataService.lightProgramsSnapshot, this.budgetObjectTypes);

    const deleteHandler = () => {
      this.tableDataService.setLoading(true);
      this.dataMutationService.deleteObjects$(targets).pipe(
        switchMap(res => this.onItemsDeleted(res)),
        finalize(() => this.tableDataService.setLoading(false))
      ).subscribe(
        {
          error: err => {
            console.log(`Error occurred when deleting objects on Manage CEG page`);
            console.error(err);
          }
        }
      );
    };
    const dialogMessage = `Are you sure you want to delete ${itemsToDelete === 1 ? 'this object' : 'these objects'}
      <br>You cannot undo this action.`;

    this.dialogService.openDeleteEntityDialog(deleteHandler, null, { title: '', message: dialogMessage });
  }

  private onItemsDeleted(res: DeleteObjectsResult): Observable<any> {    
    this.recordInteractionService.resetSelection();
    const applyRemovedPrograms$ =
      res.expGroups?.success?.length ?
        this.programEventHandler.handleBulkDelete(res.expGroups.success) :
        of(null);

    const applyRemovedCampaigns$ =
      res.campaigns?.success?.length ?
        this.campaignEventHandler.handleBulkDelete(res.campaigns.success) :
        of(null);

    const applyRemovedGoals$ =
      res.goals?.success?.length ?
        this.goalEventHandler.handleBulkDelete(res.goals.success) :
        of(null);

    return forkJoin([applyRemovedPrograms$, applyRemovedCampaigns$, applyRemovedGoals$]);
  }

  private onObjectDuplicated(record: ManageCegTableRow, createdObject: Goal | LightCampaign | LightProgram): void {
    if (!createdObject) {
      return;
    }

    const afterDuplicatedHandlers = {
      [ManageTableRowType.ExpenseGroup]: () => this.programEventHandler.handleProgramCreated(createdObject as LightProgram),
      [ManageTableRowType.Campaign]: () => this.onCampaignDuplicated(createdObject as LightCampaign),
      [ManageTableRowType.Goal]: () => this.goalEventHandler.handleGoalCreated(createdObject as Goal)
    };

    (afterDuplicatedHandlers[record.type]?.() || of(null))
      .pipe(
        finalize(() => this.tableDataService.setLoading(false))
      )
      .subscribe()
  }

  private setHierarchyItems(params: Partial<ManageCEGTableDataBuilderInputs>): void {
    const { viewMode, campaigns, goals, segmentGroups, segments, sharedCostRules } = params;

    if (viewMode === ManageCegViewMode.Segments) {
      this.hierarchyItems = this.segmentMenuService.prepareDataForSegmentMenu(
        {
          segments,
          groups: segmentGroups,
          rules: sharedCostRules,
          campaigns,
        });
      this.hierarchyItems.forEach(segmentItem => {
        if (segmentItem.objectType === this.OBJECT_TYPES.segmentsGroup) {
          segmentItem.notClickable = true;
        }
      });
    } else {
      const { items: locationHierarchyItems } = this.locationService.createLocationHierarchyItems({
        goals: viewMode === ManageCegViewMode.Campaigns ? [] : goals,
        campaigns,
        programs: [],
        currentLocation: null,
        segments,
        rules: sharedCostRules,
        isPowerUser: false,
      }, true);
      if (viewMode === ManageCegViewMode.Goals) {
        locationHierarchyItems.sort((a, b) => {
          return a.objectType === this.OBJECT_TYPES.goal && b.objectType !== a.objectType ? -1 : 1;
        });
      }
      if (viewMode === ManageCegViewMode.Campaigns) {
        locationHierarchyItems.sort((a, b) => {
          return a.title.localeCompare(b.title);
        });
      }
      this.hierarchyItems = locationHierarchyItems;
    }
  }

  private moveItems(targets: BulkActionTargets, moveTarget: HierarchySelectItem, movedItemsCount?: number): void {
    const { segment, campaign, goal, sharedCostRule } = this.OBJECT_TYPES;

    const getExecuteResultHandler = (getAction: () => BudgetAllocationAction<any>) => {
      return (results: UpdateObjectsResult, childrenSegmentInheritance: boolean) => {
        if (results) {
          if (!childrenSegmentInheritance) {
            this.historyManager.pushAction(getAction());
          }
          this.applyMoveItemsResult(results);
        } else {
          this.tableDataService.setLoading(false);
        }
      };
    };

    const undoResultHandler = (results: any) => {
      if (results) {
        this.applyMoveItemsResult(results);
      } else {
        this.tableDataService.setLoading(false);
      }
    };

    if ([sharedCostRule, segment].includes(moveTarget.objectType)) {
      this.moveToSegmentOrSharedCostRule(targets, moveTarget, getExecuteResultHandler, undoResultHandler);
    } else if ([campaign, goal].includes(moveTarget.objectType)) {
      this.moveToParentObject(moveTarget, targets, movedItemsCount, getExecuteResultHandler, undoResultHandler);
    }
  }

  private moveToSegmentOrSharedCostRule(
    targets: BulkActionTargets,
    moveTarget: HierarchySelectItem,
    getExecuteResultHandler:
      (getAction: () => BudgetAllocationAction<any>) => (results: UpdateObjectsResult, childrenSegmentInheritance: boolean) => void,
    undoResultHandler: (results: any) => void
  ): void {
    const { segment, sharedCostRule } = this.OBJECT_TYPES;

    const segmentData: BudgetObjectSegmentData = {
      budgetSegmentId: moveTarget.objectType === segment ? moveTarget.objectId : null,
      sharedCostRuleId: moveTarget.objectType === sharedCostRule ? moveTarget.objectId : null
    };

    const action = new ManageTableChangeSegmentAction<ManageCegTableDataService, ManageCegTableDataMutationService>({
      executeResultHandler: getExecuteResultHandler(() => action),
      undoResultHandler,
      bulkTargets: targets,
      segmentData,
      dataService: this.tableDataService,
      mutationService: this.dataMutationService,
    });

    this.actionsManager.executeAction(action);
  }

  private moveToParentObject(
    moveTarget: HierarchySelectItem,
    targets: BulkActionTargets,
    movedItemsCount: number,
    getExecuteResultHandler:
      (getAction: () => BudgetAllocationAction<any>) => (results: UpdateObjectsResult, childrenSegmentInheritance: boolean) => void,
    undoResultHandler: (results: any) => void
  ): void {
    const parentData = {
      objectId: moveTarget.objectId,
      objectType: moveTarget.objectType,
      segmentData: moveTarget.segmentData
    };
    const undoCallbacks = [];
    const action = new ManageTableChangeParentAction<ManageCegTableDataService, ManageCegTableDataMutationService>({
      executeResultHandler: getExecuteResultHandler(() => action),
      undoResultHandler,
      dataService: this.tableDataService,
      mutationService: this.dataMutationService,
      bulkTargets: targets,
      parentData,
      timeframes: this.timeframes,
      suppressTfAllocations: this.budget.suppress_timeframe_allocations,
      movedItemsCount,
      undoCallbacks
    });

    this.actionsManager.executeAction(action);
  }

  private setTableTimeframeFromDrawerTimeframe(): void {
    const showByTimeframe = this.router.getCurrentNavigation()?.extras.state?.showByTimeframe;
    if (showByTimeframe) {
      const params = this.router.routerState.root.snapshot.queryParams;
      const { timeframes } = params?.filter ? JSON.parse(params.filter) : [];
      if (timeframes?.length) {
        this.managePageModeService.setPresentationTimeframeIds(timeframes);
      }
    }
  }

  private getCampaignCountForSegmentView(viewMode: ManageCegViewMode, campaigns: LightCampaign[]): number {
    return viewMode === ManageCegViewMode.Segments && campaigns.length ?
      campaigns.filter(campaign => !!campaign.budgetSegmentId).length :
      campaigns?.length || 0;
  }

  public moveItem(record: ManageCegTableRow, moveTarget: HierarchySelectItem, movedItemsCount?: number): void {
    if (!record || !this.editPermission) {
      return;
    }

    this.moveItems(ManageTableHelpers.getBulkTargetsFromRecord(record), moveTarget, movedItemsCount);
  }

  public hasParentCampaignSelection(campaignIdsSet: Set<number>): boolean {
    return this.budgetDataService.lightCampaignsSnapshot.some(
      camp => camp.parentCampaign && campaignIdsSet.has(camp.parentCampaign)
    );
  }

  public hideBackNavToast(): void {
    if (this.backNavToast) {
      this.utilityService.hideToastr(this.backNavToast.toastId);
    }
    this.backNavToast = null;
  }

  public changeSelectedItemsObjectType(objectTypeId: number, objectTypeName: string): void {
    if (!this.editPermission) {
      return;
    }

    const selection = this.getSelectedItems();
    const targets = ManageTableHelpers.getBulkTargetsFromSelection(selection);
    const submitHandler = () => {
      this.dataMutationService.updateObjectsType(
        targets,
        objectTypeId,
        () => this.tableDataService.setLoading(false)
      );
    };
    const dialogMessage = `Change type of selected objects to <b>"${objectTypeName}"</b>`;

    this.dialogService.openConfirmationDialog({
      content: dialogMessage,
      title: 'Change Type',
      submitAction: {
        label: 'OK',
        handler: submitHandler
      },
      cancelAction: {
        label: 'Cancel',
        handler: null
      }
    });
  }

  public duplicateSelectedItems(): void {
    if (!this.editPermission) {
      return;
    }
    // For duplicating - selection must contain one object only
    const { records } = this.getSelectedItems();

    for (const entry of Object.entries(records)) {
      const [ recordId, selection ] = entry;
      const record = this.tableDataService.getRecordById(recordId);

      if (!CloneableRowTypes.includes(record.type) || selection.value !== CheckboxValue.Active) {
        continue;
      }

      this.duplicateItem(record);
      break;
    }
  }

  public duplicateItem(record: ManageCegTableRow): void {
    if (!record || !this.editPermission) {
      return;
    }

    this.dataMutationService.duplicateObject(record, createdObject => this.onObjectDuplicated(record, createdObject));
  }

  public filterIntegratedItems(items) {
    return items.map(obj => {
        if (obj.children && obj.children.length > 0) {
            obj.children = this.filterIntegratedItems(obj.children);
        }
        if (!('campaign_integrated_source' in obj) || !obj.campaign_integrated_source) {
            return obj;
        }
    }).filter(Boolean);
  }

  public openHierarchySelection(record?: ManageCegTableRow): void {
    this.tableDataService.setLoading(true);
    this.budgetDataService.getLightCampaigns(
      this.company.id,
      this.budget.id,
      this.config.campaignStatusNames.active
    ).pipe(
      takeUntil(this.destroy$)
    ).subscribe({
      next: campaigns => {
        const { items: locationHierarchyItems } = this.locationService.createLocationHierarchyItems({
          goals: [],
          campaigns,
          programs: [],
          currentLocation: null,
          segments : this.segments,
          rules: [],
          isPowerUser: false,
        }, true);
        locationHierarchyItems.sort((a, b) => {
          return a.title.localeCompare(b.title);
        });
        const filteredArray = this.filterIntegratedItems(locationHierarchyItems);
        this.hierarchyItems = filteredArray;
        const selection = this.recordInteractionService.selectionState;
        const hierarchyItems = this.filterSelectItems(selection);
        const dialogContextData: HierarchyDialogContext = {
          title: 'Move to',
          selectItems: hierarchyItems,
          allowGroupSelection: true,
          submitAction: {
            label: 'Move',
            handler: null,
          },
        };
        const dialogConfig: MatDialogConfig = {
          width: '480px',
          panelClass: ['reset-paddings', 'hierarchy-select-modal'],
          data: dialogContextData
        };
        this.tableDataService.setLoading(false);
        const dialogRef = this.matDialog.open(HierarchySelectModalComponent, dialogConfig);
    
        dialogRef.afterClosed()
          .pipe(
            tap(option => {
              if (!option && record) {
                this.recordInteractionService.deselectRecord(record);
              }
            }),
            filter(option => !!option)
          )
          .subscribe((selectedOption: HierarchySelectItem) => {
            this.moveSelectedItems(selectedOption);
          });
      },
      error: err => {
        this.tableDataService.setLoading(false);
        if (err) {
          console.error(err);
        }
      }
    });
  }

  public closeItems(record?: ManageCegTableRow): void {
    if (!this.editPermission) {
      return;
    }
    let selection: ManageCegTableSelectionState;
    if (!record) {
      selection = this.getSelectedItems();
    } else {
      const bulkFromRecord = ManageTableHelpers.getBulkTargetsFromRecord(record);
      selection = {
        goals: new Set(bulkFromRecord.goals),
        campaigns: new Set(bulkFromRecord.campaigns),
        expGroups: new Set(bulkFromRecord.expGroups)
      } as ManageCegTableSelectionState;
    }
    this.addChildObjectsForClose(selection);
    this.showCloseItemsDialog(ManageTableHelpers.getBulkTargetsFromSelection(selection));
  }

  private addChildObjectsForClose(selection: ManageCegTableSelectionState): void {
    const { campaigns } = selection;
    const campaignsList = this.budgetDataService.lightCampaignsSnapshot;
    const expGroupsList = this.budgetDataService.lightProgramsSnapshot;

    const addChildIds = (
      selectionField: string,
      selectedIds: Set<number>,
      objectList: LightCampaign[] | LightProgram[],
      parentIdField: string
    ): void => {
      selection[selectionField] = new Set();
      objectList.forEach(object => {
        if (selectedIds.has(object[parentIdField])) {
          selection[selectionField].add(object['objectId']);
        }
      });
    };

    addChildIds('childCampaigns', campaigns, campaignsList, 'parentCampaign');
    addChildIds('childExpGroups', new Set([...campaigns, ...selection.childCampaigns]), expGroupsList, 'campaignId');
  }

  public reopenItems(record?: ManageCegTableRow) {
    if (!this.editPermission) {
      return;
    }
    const targets = record ?
      ManageTableHelpers.getBulkTargetsFromRecord(record) :
      ManageTableHelpers.getBulkTargetsFromSelection(this.getSelectedItems());

    this.executeReopenItems(targets);
  }

  public addTagsToSelectedItems(): void {
    if (!this.editPermission) {
      return;
    }

    const selection = this.getSelectedItems();
    this.tagsManager.openAddTagsDialog(selection, this.company.id);
  }

  public removeTagsFromSelectedItems(): void {
    if (!this.editPermission) {
      return;
    }

    const selection = this.getSelectedItems();
    this.tagsManager.openRemoveTagsDialog(selection, this.company.id);
  }

  public openMetricList(): void {
    const campaigns: any = Array.from(this.recordInteractionService.selectionState.campaigns);
    const metrics$ = this.budgetObjectDetailsManager.getUnassignedCampaignMetrics(
      this.company.id,
      campaigns,
      this.products,
      this.metrics,
      this.config.OBJECT_TYPES.campaign
    );
    const dialogContextData = {
      title: 'Add Metrics',
      isAdmin: this.isAdmin,
      metrics$
    };
    const dialogConfig: MatDialogConfig = {
      width: '480px',
      panelClass: ['add-metric-dialog'],
      data: dialogContextData
    };
    const dialogRef = this.matDialog.open(AddMetricDialogComponent, dialogConfig);

    dialogRef.afterClosed()
      .pipe(
        filter(options => !!options),
        takeUntil(this.destroy$)
      )
      .subscribe((selectedOption: string[]) => {
        const body = { campaigns, metric_masters: selectedOption };
        let metricMappingIds = [];
        const action = new ManageTableBasicAction({
          execute: () => {
            this.tableDataService.setLoading(true);
            this.recordInteractionService.resetSelection();
            this.metricService.bulkAssignMetricToCampaign(body)
              .pipe(
                finalize(() => this.tableDataService.setLoading(false)),
                takeUntil(this.destroy$)
              )
              .subscribe((mappingIds: number[]) => {
                metricMappingIds = mappingIds;
                if (mappingIds?.length) {
                  const message = `${campaigns.length} ${campaigns.length > 1 ? 'campaigns' : 'campaign'} updated successfully`;
                  this.utilityService.showCustomToastr(message);
                }
              }, error => this.utilityService.handleError(error));
          },
          undo: () => {
            if (!metricMappingIds.length) {
              return;
            }
            this.metricService.bulkDeleteMetricMapping(metricMappingIds)
              .pipe(takeUntil(this.destroy$))
              .subscribe( {
                error: err => this.utilityService.handleError(err)
              });
          }
        });
        // undo action
        this.actionsManager.executeAction(action, () => this.historyManager.pushAction(action));
      });
  }

  public deleteItems(record?: ManageCegTableRow): void {
    if (!this.editPermission) {
      return;
    }
    const targets = record ?
      ManageTableHelpers.getBulkTargetsFromRecord(record) :
      ManageTableHelpers.getBulkTargetsFromSelection(this.getSelectedItems());

    this.showDeleteItemsDialog(targets);
  }

  public exportData(): void {
    const exportParams: ManagePageExportParams = {
      view_mode: this.managePageModeService.viewMode?.slice(0, -1), // expecting segment/goal/campaign
      timeframe_mode: this.managePageModeService.presentationTimeframeMode?.toLowerCase()
    }
    const tableConfig = this.manageTableConfigurationService.manageTableViewConfig;
    const { viewMode } = this.managePageModeService;
    const requestParams = ManageCegTableHelpers.mapTableConfigToExportParams(exportParams, tableConfig, viewMode);

    const downloadFile = (data: BufferSource | Blob | string, fileType: EXPORT_TYPES, fileNameLabel: string): void => {
      const companyName = this.companyDataService.selectedCompanySnapshot.name;
      const budgetName = this.budgetDataService.selectedBudgetSnapshot.name;
      const exportFileName = ExportDataService.getExportedFileName(
        companyName,
        budgetName,
        fileNameLabel
      ) + `.${fileType}`;
      ExportDataService.downloadDataAsFile(data, exportFileName, fileType);
    }
    this.tableDataService.setLoading(true);
    this.budgetDataService.exportManagePage(
      this.budget.id, requestParams)
      .pipe(
        finalize(() => this.tableDataService.setLoading(false))
      )
      .subscribe({
        next: ((data) => downloadFile(data.file, EXPORT_TYPES.XLSX, 'report')),
        error: error => this.utilityService.handleError(error)
      });
  }

  public changeStatus(status: CEGStatus): void {
    const selection = this.getSelectedItems();
    const { campaigns: campaignIds, expGroups: programIds } = ManageTableHelpers.getBulkTargetsFromSelection(selection);
    this.tableDataService.setLoading(true);

    this.dataMutationService.changeObjectStatus(campaignIds, programIds, status)
      .pipe(
        finalize(() => this.recordInteractionService.resetSelection()),
        takeUntil(this.destroy$),
        switchMap((result: UpdateObjectsStatusResult) => {
          const updatedCampaignIds = result?.campaigns?.success || [];
          const onCampaignsUpdated$ =
            updatedCampaignIds?.length ? this.campaignEventHandler.handleBulkAmountStatusChanged(updatedCampaignIds, status) : of([]);

          const updatedProgramIds = result?.expGroups?.success || [];
          const onProgramsUpdated$ =
            updatedProgramIds?.length ? this.programEventHandler.handleBulkAmountStatusChanged(updatedProgramIds, status) : of([]);

           return forkJoin([onCampaignsUpdated$, onProgramsUpdated$]);
        }),
        tap(() => {
          this.tableDataService.triggerRefreshTable();
          this.tableDataService.setLoading(false);
        })
      )
      .subscribe({
          error: () => {
            this.utilityService.handleError({ message: 'Failed to change status' });
          }
        }
      );
  }

  public moveSelectedItems(targetObject: HierarchySelectItem, movedItemsCount?: number): void {
    if (!this.isAdminUser) {
      return;
    }
    const selection = this.getSelectedItems();
    const bulkTargets = ManageTableHelpers.getBulkTargetsFromSelection(selection);

    const checkParentCampaign = (expenseGroups, campaigns, entities: BulkActionTargets) => {
      expenseGroups.forEach(group => {
        if (entities.expGroups.includes(group.id) && entities.campaigns.includes(group.campaignId)) {
          const expIndex = entities.expGroups.indexOf(group.id);
          entities.expGroups.splice(expIndex, 1);
        }
      });

      campaigns.forEach(campaign => {
        if (entities.campaigns.includes(campaign.id) && entities.campaigns.includes(campaign.parentCampaign)) {
          const campaignIndex = entities.campaigns.indexOf(campaign.id);
          entities.campaigns.splice(campaignIndex, 1);
        }
      });
      return entities;
    };

    const updatedBulkTargets = checkParentCampaign(
      this.budgetDataService.lightProgramsSnapshot, this.budgetDataService.lightCampaignsSnapshot, bulkTargets
    );
    this.moveItems(updatedBulkTargets, targetObject, movedItemsCount);
  }

  public createNewItemTemplate(createItemTemplateEvent: CreateCegItemTemplateEvent): void {
    if (!this.editPermission) {
      return;
    }
    this.dataMutationService.createNewItemTemplate(createItemTemplateEvent);
  }

  public saveNewItemTemplateWithName(name: string): void {
    this.dataMutationService.saveNewItemTemplate(
      name,
      this.currentCompanyUser.user,
      this.company.id,
      this.budget,
      this.timeframes
    );
  }

  public validateUniqueObjectName(objectType: string, name: string): Observable<{ status: string; message: string }> {
    const serviceByObjectType = {
      [ManageTableRowType.Campaign]: this.campaignService,
      [ManageTableRowType.ExpenseGroup]: this.programService,
    };
    return serviceByObjectType[objectType].validateUniqueName(this.company.id, this.budget.id, name);
  }

  public get newItemCreationActive(): boolean {
    return !!this.dataMutationService.newItemTemplate;
  }

  public openCampaignMetricDetails(mappingId: number): void {
    this.appRoutingService.openCampaignMetricDetails(mappingId);
  }

  public openExpenseList(filters: FilterSet, viewMode: HierarchyViewMode): void {
    this.reflectFiltersInLocation = false;
    const pageContext: BackNavigationContext = {
      pageName: 'Manage page',
      route: this.managePageModeService.getCurrentLocation(false)
    };
    this.routingService.openExpenseListFromManageCEGPage(filters, pageContext, viewMode);
  }

  private handleBudgetObjectEvent(budgetObjectEvent: BudgetObjectEvent): void {
    const handleEvent$ =
      this.budgetObjectEventHandlers[budgetObjectEvent?.targetObject]?.handle(
        budgetObjectEvent.eventType,
        budgetObjectEvent.context
      );

    this.execBudgetObjectEventHandling(
      handleEvent$,
      err => {
        console.log(`handleBudgetObjectEvent(): Failed to handle the budget object event: ${ JSON.stringify(budgetObjectEvent) }`);
        if (err) {
          console.error(err);
        }
      }
    );
    this.updatePerformanceColumnDataOnBudgetObjectEvent(budgetObjectEvent);
  }

  public handleAllocationUpdate(targetObject: BudgetObject, rowsToUpdate: ManageCegTableRow[]): void {
    const handleEvent$ =
      this.budgetObjectEventHandlers[targetObject]?.handleAllocationUpdate(rowsToUpdate);

    this.execBudgetObjectEventHandling(
      handleEvent$,
      err => {
        console.log(`handleAllocationUpdate(): Failed to handle the allocation update`);
        if (err) {
          console.error(err);
        }
      }
    );
  }

  private execBudgetObjectEventHandling(handleEvent$: Observable<boolean>, errorCb?: (err: any) => void) {
    handleEvent$?.pipe(
      takeUntil(this.destroy$),
      tap(() => this.dataMutationService.updateGrandTotal()),
      filter(updated => updated)
    ).subscribe({
      next: () => {
        this.tableDataService.refreshAllRootRowsLoaded();
        this.tableDataService.triggerRefreshTable();
      },
      error: err => errorCb?.(err)
    });
  }

  private handleMetricMappingChange(change: MetricMappingChange): void {
    const { mappingId, isKeyMetric, mapId, metricId, mappingType } = change;

    if (mappingType !== this.config.OBJECT_TYPES.campaign) {
      return;
    }

    const campaigns = this.budgetDataService.lightCampaignsSnapshot;
    const targetCampaign = campaigns.find(campaign => campaign.id === mapId);

    if (!targetCampaign) {
      return;
    }

    if (isKeyMetric) {
      targetCampaign.keyMetric = mappingId;
      this.tableDataService.updatePerformanceColumnDataForCampaigns([targetCampaign]);
    } else if (targetCampaign?.keyMetric === mappingId) {
      targetCampaign.keyMetric = null;
      this.tableDataService.removePerformanceColumnDataForCampaign(targetCampaign.id);
      this.tableDataService.triggerRefreshTable();
    }

    const parentCampaignId = targetCampaign.parentCampaign;
    const parentCampaign = parentCampaignId ? campaigns.find(campaign => campaign.id === parentCampaignId) : null;

    if (!parentCampaign?.keyMetric || !metricId) {
      return;
    }

    // Implicitly reload parent campaign's key metric data if any
    this.tableDataService.updatePerformanceColumnDataForCampaigns([parentCampaign]);

    this.execBudgetObjectEventHandling(
      this.campaignEventHandler?.handleMetricMappingChange(targetCampaign),
      err => {
        console.log(`Failed to handle metric mapping change: ${JSON.stringify(change)}`);
        if (err) {
          console.error(err);
        }
      }
    );
  }

  private updatePerformanceColumnDataForCampaign(updateCampaign: LightCampaign): void {
    if (updateCampaign?.keyMetric) {
      this.tableDataService.updatePerformanceColumnDataForCampaigns([updateCampaign]);
      const parentCampaignId = updateCampaign.parentCampaign;
      const parentCampaign =
        parentCampaignId ?
          this.budgetDataService.lightCampaignsSnapshot.find(campaign => campaign.id === parentCampaignId) :
          null;

      if (parentCampaign?.keyMetric) {
        // Implicitly reload parent campaign's key metric data if any
        this.tableDataService.updatePerformanceColumnDataForCampaigns([parentCampaign]);
      }
    } else if (updateCampaign && !updateCampaign?.keyMetric) {
      this.tableDataService.removePerformanceColumnDataForCampaign(updateCampaign.id);
    }
  }

  private updatePerformanceColumnDataOnBudgetObjectEvent(budgetObjectEvent: BudgetObjectEvent): void {
    const targetCampaign = this.getCampaignForPerformanceColumnDataUpdate(budgetObjectEvent);
    const prevCampaign = this.getCampaignForPerformanceColumnDataUpdate(budgetObjectEvent, true);

    this.updatePerformanceColumnDataForCampaign(targetCampaign);
    if (prevCampaign != null && prevCampaign.id !== targetCampaign?.id) {
      this.updatePerformanceColumnDataForCampaign(prevCampaign);
    }
  }

  private getCampaignForPerformanceColumnDataUpdate(budgetObjectEvent: BudgetObjectEvent, isPrevParent = false): LightCampaign {
    let targetCampaign: LightCampaign;
    const parentObject = isPrevParent ? budgetObjectEvent.context?.prevParentObject : budgetObjectEvent.context?.parentObject;
    const campaigns = this.budgetDataService.lightCampaignsSnapshot;
    const programs = this.budgetDataService.lightProgramsSnapshot;

    if (budgetObjectEvent.targetObject === BudgetObject.Expense) {
      if (parentObject?.type === this.config.OBJECT_TYPES.campaign) {
        targetCampaign = campaigns.find(campaign => campaign.id === parentObject?.id);
      } else if (parentObject?.type === this.config.OBJECT_TYPES.program) {
        const targetProgram = programs.find(program => program.id === parentObject?.id);
        targetCampaign = targetProgram?.campaignId ? campaigns.find(campaign => campaign.id === targetProgram.campaignId) : null;
      }
    } else if (budgetObjectEvent.targetObject === BudgetObject.Program) {
      if (parentObject?.type === this.config.OBJECT_TYPES.campaign) {
        targetCampaign = campaigns.find(campaign => campaign.id === parentObject?.id);
      }
    } else if (budgetObjectEvent.targetObject === BudgetObject.Campaign) {
      targetCampaign = campaigns.find(campaign => campaign.id === budgetObjectEvent.context?.objectId);
    }

    return targetCampaign;
  }

  private onCampaignDuplicated(createdCampaign: LightCampaign): Observable<any> {
    this.updatePerformanceColumnDataForCampaign(createdCampaign);
    return this.campaignEventHandler.handleCampaignCreated(createdCampaign);
  }

  private applyMoveItemsResult(results: UpdateObjectsResult): void {
    const updatedCampaigns = (results?.campaigns?.success || []).map(campaignDO => this.budgetDataService.convertCampaignDO(campaignDO));
    const applyUpdatedCampaigns$ = updatedCampaigns.length ? this.campaignEventHandler.handleBulkUpdate(updatedCampaigns) : of(false);

    const updatedPrograms = (results?.expGroups?.success || []).map(programDO => this.budgetDataService.convertProgram(programDO));
    const applyUpdatedPrograms$ = updatedPrograms.length ? this.programEventHandler.handleBulkUpdate(updatedPrograms) : of(false);

    applyUpdatedCampaigns$.pipe(
      takeUntil(this.destroy$),
      switchMap(() => applyUpdatedPrograms$),
      filter(updated => updated),
      finalize(() => this.tableDataService.setLoading(false))
    ).subscribe({
      next: () => {
        this.recordInteractionService.resetSelection();
        this.tableDataService.refreshAllRootRowsLoaded();
        this.tableDataService.triggerRefreshTable();
      },
      error: err => {
        console.log(`applyMoveItemsResult(): Failed to update the manage CEG table after moveItems action`);
        if (err) {
          console.error(err);
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.hideBackNavToast();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
