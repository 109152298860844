import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { getRequestOptions } from 'app/shared/utils/http-request.utils';
import { Observable } from 'rxjs';
import { API_V2_URL } from '@common-lib/lib/injection-tokens/url.tokens';

export interface BudgetTimeframeData {
  data: BudgetTimeframeDataItem[];
  message: string;
  status: string;
}

export interface BudgetTimeframeDataItem {
  remaining_allocated: number;
  budget_remaining_amount: number;
  remaining_child_allocated: number;
  closed: number;
  committed: number;
  company_budget_alloc_id: number;
  company_budget_alloc_name: string;
  locked: boolean;
  overdue: number;
  planned: number;
  total: number;
  under_budget: number;
  spent?: number;
}

@Injectable()
export class WidgetService {
  private readonly apiV2Url = inject(API_V2_URL);
  private readonly http = inject(HttpClient);

  getBudgetHierarchyGraphData(data) {
    return this.http.get(this.apiV2Url + 'graph_hierarchy/', getRequestOptions(data));
  }

  getBudgetHierarchyCsvData(data) {
    const options = getRequestOptions(data);
    options['responseType'] = 'text';
    return this.http.get(this.apiV2Url + 'graph_hierarchy_csv/', options);
  }

  getTimeframeGraphDetails(data): Observable<BudgetTimeframeData> {
    return this.http.get<BudgetTimeframeData>(this.apiV2Url + 'graph_timeline/', getRequestOptions(data));
  }

  getTimeframeCsvData(data) {
    const options = getRequestOptions(data);
    options['responseType'] = 'text';
    return this.http.get(this.apiV2Url + 'graph_timeline_csv/', options);
  }

  getSpendByTypeGraphDetails(data) {
    return this.http.get(this.apiV2Url + 'graph_type/', getRequestOptions(data));
  }

  getSpendByTypeCsvData(data) {
    const options = getRequestOptions(data);
    options['responseType'] = 'text';
    return this.http.get(this.apiV2Url + 'graph_type_csv/', options);
  }
}
