import { BehaviorSubject, Observable, of } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { BudgetAllocationAction, BudgetAllocationActionParams, UndoCallback } from './budget-allocation-action.types';
import { roundDecimal } from 'app/shared/utils/common.utils';
import { BudgetAllocationBinaryActionTarget } from '../types/budget-allocation-binary-action-target.interface';
import { ManageTableRowType } from '@shared/enums/manage-table-row-type.enum';

interface MoveActionParams<TActionDataSource> extends BudgetAllocationActionParams {
  setAmount: (value: number, dataSource: TActionDataSource, isLast?: boolean) => Observable<boolean> | void;
  getAmount: (dataSource: TActionDataSource) => number;
  actionTarget: BudgetAllocationBinaryActionTarget<TActionDataSource>;
  undoCallbacks?: UndoCallback[];
  onExecutedTrigger?: BehaviorSubject<boolean>;
  handleResultValueChange?: (toDataSource: TActionDataSource, fromDataSource: TActionDataSource) => boolean;
}

export class BudgetAllocationMoveAction<TActionDataSource> extends BudgetAllocationAction<MoveActionParams<TActionDataSource>> {
  private initialValues = {
    from: null,
    to: null
  };
  private resultValues = {
    from: null,
    to: null
  };
  private differenceValue: number;

  constructor(params: MoveActionParams<TActionDataSource>) {
    super();
    this.actionParams = params;
    this.prepareActionData();
  }

  private prepareExecuteMessage(value: number, currency: string): string {
    return `You have moved <b>${currency} ${this.formatValue(value)}</b> to cover this amount`;
  }

  private prepareActionData() {
    const { actionTarget, currency, getAmount, handleResultValueChange } = this.actionParams;
    const { to: toState, from: fromState } = actionTarget;

    this.differenceValue = toState.overspend
      ? Math.min(fromState.difference, toState.difference)
      : fromState.difference;

    const fromCurrentValue = getAmount(fromState.dataSource);
    const fromResultValue = roundDecimal(fromCurrentValue - this.differenceValue, 2);
    const toCurrentValue = getAmount(toState.dataSource);
    const toResultValue = handleResultValueChange(toState.dataSource, fromState.dataSource) ? toCurrentValue : roundDecimal(toCurrentValue + this.differenceValue, 2);

    this.onExecutedMessage = this.prepareExecuteMessage(this.differenceValue, currency);
    this.initialValues = {
      from: fromCurrentValue,
      to: toCurrentValue
    };
    this.resultValues = {
      from: fromResultValue,
      to: toResultValue
    };
  }

  public execute(): void {
    const { setAmount, actionTarget, onExecutedTrigger } = this.actionParams;
    const { to: toState, from: fromState } = actionTarget;

    if (!setAmount || !fromState || !toState) {
      return;
    }

    const draggableCellRowTypes = [ManageTableRowType.ExpenseGroup, ManageTableRowType.Campaign];
    const draggedRecordType = (fromState.dataSource as any).record?.type;
    const droppedRecordType = (toState.dataSource as any).record?.type;
    // Doesn't allow drop of available budget from Campaing/EG to Segment
    if (draggableCellRowTypes.includes(draggedRecordType) && droppedRecordType === ManageTableRowType.Segment) {
      return;
    }

    setAmount(this.resultValues.from, fromState.dataSource);
    setAmount(this.resultValues.to, toState.dataSource, true);

    const trigger$ = onExecutedTrigger ? onExecutedTrigger.asObservable() || of(true): of(true);

    trigger$
      .pipe(
        filter(value => value != null),
        take(1)
      )
      .subscribe(
        result => {
          if (!result) {
            return;
          }
          this.onExecuted?.(
            this.onExecutedMessage,
            this.undo.bind(this)
          );
        }
      );
  }

  public undo(viaSnackbar?: boolean): void {
    const { setAmount, actionTarget, undoCallbacks } = this.actionParams;
    const { to: toState, from: fromState } = actionTarget;

    if (!setAmount || !fromState || !toState) {
      return;
    }
    setAmount(this.initialValues.from, fromState.dataSource);
    setAmount(this.initialValues.to, toState.dataSource, true);
    if (this.onUndone) {
      this.onUndone(viaSnackbar);
      this.runCallbacks(undoCallbacks).subscribe();
    }
  }
}
