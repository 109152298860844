import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { UtilityService } from 'app/shared/services/utility.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Modal } from 'ngx-modialog-7/plugins/bootstrap';
import { ProgramTypeDO } from 'app/shared/types/program.interface';
import { BudgetObjectDialogService } from '../../shared/services/budget-object-dialog.service';
import { Configuration } from 'app/app.constants';
import { TagService } from '../../shared/services/backend/tag.service';
import { CompanyDataService } from '../../shared/services/company-data.service';

@Component({
  selector: 'tab-tags',
  templateUrl: './tab-tags.component.html',
  styleUrls: ['./tab-tags.component.scss']
})
export class TagsTabComponent implements OnChanges, OnDestroy {
  @Input() companyId: number;
  private readonly destroy$ = new Subject<void>();
  tagsList: ProgramTypeDO[];
  appliedSorting = true;
  SearchPlaceHolder = 'Search Tags';
  NoDataText = '';

  public gridColumns  = [
    { name: 'name', label: 'Tag', sortable: true, width: '45%' },
    { name: 'usage_count', label: 'Count', sortable: true, width: '40%' }
  ];

  enableSwitchTooltip = this.config.tagsDescriptions.enableTooltip;

  constructor(
    private readonly utilityService: UtilityService,
    private readonly service: TagService,
    public readonly modal: Modal,
    private readonly dialogService : BudgetObjectDialogService,
    private config : Configuration,
    private companyDataService : CompanyDataService
  ) {
    this.NoDataText = this.config.attributesAndTagsLabel.TAG;
   }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.companyId) {
      this.getTagsList(this.companyId);
    }
  }

  getTagsList(companyId: number) {
    this.utilityService.showLoading(true);
    this.service.getNewTags({ company: companyId})
    .pipe(
      takeUntil(this.destroy$)
    )
    .subscribe(
      data => this.onTagsListLoaded(data),
      error => this.utilityService.handleError(error)
    );
  }

  addTag(data) {
    this.utilityService.showLoading(true);
    data.company = this.companyId;
    this.service.createTag(data)
    .pipe(
      takeUntil(this.destroy$)
    ).subscribe(
      () => this.onTagListEvent(this.config.tagsDescriptions.created),
      error => this.utilityService.handleError(error)
    );
  }

  updateTag(data) {
    this.utilityService.showLoading(true);
    this.service.updateTag(data.name, data.id, data.company)
    .pipe(
      takeUntil(this.destroy$)
    ).subscribe(
      () => this.onTagListEvent(this.config.tagsDescriptions.updated),
      error => this.utilityService.handleError(error)
    );
  }

  deleteTag(row) {
    const deleteHandler = () => {
    this.utilityService.showLoading(true);
    this.service.removeTagById(row.id)
    .pipe(
      takeUntil(this.destroy$)
    ).subscribe(
      () => this.onTagListEvent(this.config.tagsDescriptions.deleted)
    );
    };
    const dialogMessage = `You will permanently lose '${row.name}' wherever it has been used.`;
    this.dialogService.openDeleteEntityDialog(deleteHandler, null, { title: 'Delete Tag?', message: dialogMessage });    
  }

  onTagListEvent(msg : string) {
    this.utilityService.showToast({ Title: '', Message: msg, Type: 'success' });
    this.utilityService.showLoading(false);
    this.getTagsList(this.companyId);
  }

  onTagsListLoaded(data) {
    this.tagsList = data;
    this.companyDataService.tags.next(this.companyDataService.currentTagList = data)
    this.utilityService.showLoading(false);
  }


}
