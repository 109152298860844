import { Component, ElementRef, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Budget } from 'app/shared/types/budget.interface';
import { FilterName, FilterSet } from 'app/header-navigation/components/filters/filters.interface';
import { UtilityService } from 'app/shared/services/utility.service';
import { BudgetTimeframe } from 'app/shared/types/timeframe.interface';
import { UserDataService } from 'app/shared/services/user-data.service';
import { Validations } from 'app/app.validations';
import { catchError, skip, filter, switchMap, takeUntil, tap, take, map } from 'rxjs/operators';
import { CompanyDataService } from 'app/shared/services/company-data.service';
import { combineLatest, forkJoin, Observable, Subject } from 'rxjs';
import { FilterManagementService, ParamsDef } from 'app/header-navigation/components/filters/filter-services/filter-management.service';
import { BudgetDataService } from '../../../dashboard/budget-data/budget-data.service';
import { Campaign, CampaignDO, LightCampaign } from 'app/shared/types/campaign.interface';
import { AppRoutingService } from 'app/shared/services/app-routing.service';
import { Configuration } from 'app/app.constants';
import { CalendarEvent } from '../calendar-event/calendar-event.type';
import { BudgetObjectDetailsManager } from 'app/budget-object-details/services/budget-object-details-manager.service';
import { Metric } from 'app/budget-object-details/components/details-metrics/details-metrics.type';
import { MonthLabel } from '../calendar-header/calendar-header.type';
import { EventTooltipData } from '../event-tooltip/event-tooltip.type';
import { BudgetSegmentAccess } from 'app/shared/types/segment.interface';
import { AppDataLoader } from 'app/app-data-loader.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { CampaignService } from 'app/shared/services/backend/campaign.service';
import { ORDERED_SHORT_MONTHS_NAMES } from 'app/shared/utils/date.utils';
import { MetricType } from 'app/shared/types/budget-object-metric.interface';
import { HttpStatusCode } from '@angular/common/http';
import { sortMetricsList } from 'app/shared/utils/common.utils';
import { ProductDO } from 'app/shared/services/backend/product.service';
import { getTodayFixedDate } from '@shared/utils/budget.utils';
import { CompanyService } from '@shared/services/backend/company.service';

export enum CalendarTimeframe {
  ThisMonth = 'this_month',
  ThisQuarter = 'this_quarter'
}

@Component({
  selector: 'app-marketing-calendar',
  templateUrl: './marketing-calendar.component.html',
  styleUrls: ['./marketing-calendar.component.scss'],
  providers: [AppDataLoader]
})
export class MarketingCalendarComponent implements OnInit, OnDestroy {
  public readonly utilityService = inject(UtilityService);
  public readonly userDataService = inject(UserDataService);
  public readonly validations = inject(Validations);
  public readonly configuration = inject(Configuration);
  private readonly companyDataService = inject(CompanyDataService);
  private readonly campaignService = inject(CampaignService);
  private readonly companyService = inject(CompanyService);
  private readonly filterManagementService = inject(FilterManagementService);
  private readonly budgetDataService = inject(BudgetDataService);
  private readonly appRoutingService = inject(AppRoutingService);
  private readonly budgetObjectDetailsManager = inject(BudgetObjectDetailsManager);
  private readonly appDataLoader = inject(AppDataLoader);
  private readonly activatedRoute = inject(ActivatedRoute);
  private readonly router = inject(Router);

  @ViewChild('containerRef') containerRef: ElementRef;
  private destroy$ = new Subject<void>();

  editPermission = false;
  isAdmin = false;
  currentUser: any;
  companyCode: string;
  companyId: number;

  selectedBudget: Budget;
  budgetTimeframeList: BudgetTimeframe[] = [];
  budgetSegmentList: BudgetSegmentAccess[] = [];
  campaigns: Campaign[];
  calendarEvents: CalendarEvent[];
  metricTypes: MetricType[] = [];
  metrics = new WeakMap<LightCampaign, Metric[]>();
  fullCampaigns = new WeakMap<LightCampaign, Campaign>();
  monthsLabels: MonthLabel[];

  currentFilters: FilterSet = {};

  showEmptyMsg = false;
  currencySymbol = '';
  tooltipTrigger = new Subject<EventTooltipData>();
  budgetStartDate: Date;
  budgetEndDate: Date;
  todayPercentage: number;
  currentMonth: string;
  todayDateLabel: string;
  budgetFiscalYearLabel: string;
  sortingTypeSelectOptions = [
    { title: 'Most Recent First', value: 'recent' },
    { title: 'Oldest First', value: 'oldest' }
  ];
  sortingType = 'recent';
  timeframeQueryParam: string;

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit() {
    this.budgetObjectDetailsManager.getMetricTypes()
      .pipe(takeUntil(this.destroy$))
      .subscribe(types => this.metricTypes = types);

    const company$ =
      this.companyDataService.selectedCompanyDO$.pipe(
        filter(cmp => cmp != null),
        tap(company => {
          this.companyId = company.id;
          this.currencySymbol = company.currency_symbol;
          this.companyDataService.loadCompanyData(this.companyId, error => this.utilityService.handleError(error));
          this.companyDataService.loadMetrics(this.companyId, error => this.utilityService.handleError(error));
          this.companyDataService.loadProducts(this.companyId, error => this.utilityService.handleError(error));
        }),
        switchMap(company => this.companyService.getCompanyInfo(company.id))
      );

    company$.pipe(
      catchError(error => {
        this.utilityService.handleError(error);
        return company$;
      }),
      takeUntil(this.destroy$)
    ).subscribe(response => this.onCompanyInfoLoaded(response));

    this.budgetDataService.selectedBudget$
      .pipe(
        tap(newSelectedBudget => this.onSelectNewBudget(newSelectedBudget)),
        switchMap(() =>
          combineLatest([
            this.budgetDataService.timeframeList$,
            this.budgetDataService.segmentList$,
            this.filterManagementService.budgetFiltersList$.pipe(
              switchMap(() => this.filterManagementService.budgetFiltersInit$),
              switchMap(() => this.filterManagementService.currentFilterSet$.pipe(skip(1)))
            )
          ])
        ),
        takeUntil(this.destroy$)
      ).subscribe(budgetData => this.onUpdateBudgetData(...budgetData));

    this.userDataService.editPermission$
      .pipe(takeUntil(this.destroy$))
      .subscribe(editPermission => this.editPermission = editPermission);

    this.budgetObjectDetailsManager.budgetObjectChanged$
      .pipe(
        takeUntil(this.destroy$),
        filter(change => change.objectType === this.configuration.OBJECT_TYPES.campaign)
      ).subscribe(
        () => this.loadCampaigns()
      );

    this.activatedRoute.queryParamMap.pipe(takeUntil(this.destroy$))
      .subscribe(this.onQueryParamsChanged);

    this.appDataLoader.init();
  }

  setFiltersByCampaignDate(budgetId: number): void {
    if (this.timeframeQueryParam) {
      const currentCampaigns$ =
        this.timeframeQueryParam === CalendarTimeframe.ThisMonth ?
          this.campaignService.getCurrentMonthCampaigns(budgetId) :
          this.campaignService.getCurrentQuarterCampaigns(budgetId);

      currentCampaigns$.subscribe({
        next: campaigns => this.onCampaignsByDateLoaded(campaigns),
        error: error => this.utilityService.handleError(error)
      });
    }
  }

  private onCampaignsByDateLoaded(campaigns: CampaignDO[]): void {
    this.timeframeQueryParam = '';
    const campaignsIds = campaigns.map(item => item.id || item.campaign_id);
    this.filterManagementService.updateCurrentFilterSet({ [FilterName.Campaigns]: campaignsIds });
  }

  private onUpdateBudgetData(tfList: BudgetTimeframe[], segments: BudgetSegmentAccess[], filterSet: FilterSet): void {
    this.budgetTimeframeList = tfList;
    this.budgetSegmentList = segments;
    this.currentFilters = { ...filterSet };
    this.appRoutingService.updateCurrentFiltersInRouting(
      this.companyId,
      this.selectedBudget.id,
      this.currentFilters
    );
    this.loadCampaigns();
  }

  private onCompanyInfoLoaded(data) {
    if (Number(data.status) === HttpStatusCode.Ok) {
      this.companyCode = data.data.company_code;
    } else {
      this.utilityService.handleError(data);
    }
  }

  private onSelectNewBudget(newSelectedBudget: Budget) {
    this.selectedBudget = newSelectedBudget;
    this.defineTimeLineData();
    if (newSelectedBudget != null) {
      this.showEmptyMsg = false;

      // For filters!
      this.budgetDataService.loadLightCampaigns(
        this.companyId,
        this.selectedBudget.id,
        this.configuration.campaignStatusNames.active,
        error => this.utilityService.handleError(error)
      );

      // For filters!
      this.budgetDataService.loadLightPrograms(
        this.companyId,
        this.selectedBudget.id,
        this.configuration.programStatusNames.active,
        error => this.utilityService.handleError(error)
      );

      this.budgetStartDate = new Date(this.selectedBudget.budget_from);
      this.budgetEndDate = new Date(this.selectedBudget.budget_to);

      this.utilityService.showLoading(true);
    } else {
      this.showEmptyMsg = true;
    }
    this.defineMonthsLabels();
    this.budgetFiscalYearLabel = `FY ${this.budgetEndDate.getUTCFullYear().toString().substring(2)}`;
  }

  loadCampaigns(): void {
    if (this.selectedBudget) {
      if (this.timeframeQueryParam) {
        this.setFiltersByCampaignDate(this.selectedBudget.id);
      } else {
          this.budgetDataService.getLightCampaigns(
            this.companyId,
            this.selectedBudget.id,
            this.configuration.campaignStatusNames.active,
            this.getRequestDataForCurrentFilters()
          ).pipe(
            takeUntil(this.destroy$)
          ).subscribe({
            next: campaigns => this.onCampaignsLoaded(campaigns),
            error: error => this.utilityService.handleError(error)
          });
      }
    }
  }

  private onCampaignsLoaded(campaigns) {
    this.campaigns = campaigns;
    this.calendarEvents = (this.campaigns || [])
      .map(campaign => this.rawCampaignToCalendarEvent(campaign))
      .sort(this.sortByDate);
    this.utilityService.showLoading(false);
  }

  private rawCampaignToCalendarEvent(campaign: LightCampaign): CalendarEvent {
    return {
      id: campaign.id || campaign.subCampaignId,
      name: campaign.name,
      mode: campaign.mode,
      startDate: campaign.startDate,
      endDate: campaign.endDate,
      metrics$: this.getMetricsForCampaign(campaign),
      fullCampaign$: this.getFullCampaign(campaign)
    };
  }

  private getMetricsForCampaign(campaign: LightCampaign): Observable<Metric[]> {
    return new Observable(subscriber => {
      if (this.metrics.has(campaign)) {
        subscriber.next(this.metrics.get(campaign));
        subscriber.complete();
      } else {
        forkJoin([
          this.budgetObjectDetailsManager.getMetricMappings(
            this.companyId,
            campaign.id || campaign.subCampaignId,
            this.configuration.OBJECT_TYPES.campaign
          ),
          this.budgetObjectDetailsManager.getProducts$().pipe(take(1))
        ]).pipe(takeUntil(this.destroy$))
          .subscribe({
            next: ([metrics, products]) => {
              this.setProductsForMetrics(metrics, products);
              sortMetricsList(metrics);
              this.metrics.set(campaign, metrics);
              subscriber.next(metrics);
              subscriber.complete();
            },
            error: error => subscriber.error(error)
          });
      }
    });
  }

  private getFullCampaign(campaign: LightCampaign): Observable<Campaign> {
    return new Observable<Campaign>(observer => {
      if (this.fullCampaigns.has(campaign)) {
        observer.next(this.fullCampaigns.get(campaign));
        observer.complete();
      } else {
        this.campaignService.getCampaign(campaign.id).pipe(
          takeUntil(this.destroy$),
          map(campaignDO => this.budgetDataService.convertCampaignDO(campaignDO))
        ).subscribe({
          next: fullCampaign => {
            this.fullCampaigns.set(campaign, fullCampaign);
            observer.next(fullCampaign);
          },
          error: error => observer.error(error)
        })
      }
    });
  }

  private setProductsForMetrics(metrics: Metric[], products: ProductDO[]): void {
    metrics.forEach(metric => {
      if (metric.productId) {
        const product = products.find(prod => prod.id === metric.productId);
        metric.productName = product?.name;
        metric.productOrder = product?.order;
      }
    });
  }

  private getRequestDataForCurrentFilters() {
    const requestData = {
      'company': this.companyId,
      'budget': this.selectedBudget && this.selectedBudget.id,
      'include_pseudo_objects': true
    };
    const requestParamsDef: ParamsDef = {
      'company_budget_segment1_ids': {
        filterName: FilterName.Segments,
        defaultValue: () => this.filterManagementService.getDefaultSegments(this.budgetSegmentList)
      },
      'goal_ids': { filterName: FilterName.Goals },
      'tag_ids': { filterName: FilterName.Tags },
      'owner_ids': { filterName: FilterName.Owners },
      'campaign_ids': { filterName: FilterName.Campaigns },
      'campaign_type_ids': { filterName: FilterName.CampaignTypes },
      'program_ids': { filterName: FilterName.ExpenseBuckets },
      'expense_type_ids': { filterName: FilterName.ExpenseTypes },
      'gl_code_ids': { filterName: FilterName.GlCodes },
      'vendor_ids': { filterName: FilterName.Vendors },
      'split_rule_ids': { filterName: FilterName.SharedCostRules },
      'metric_ids': { filterName: FilterName.Metrics },
      'po_numbers': { filterName: FilterName.PONumber },
      'amount_status': { filterName: FilterName.CEGStatus }
    };
    this.filterManagementService.setParamsFromFilters(requestData, requestParamsDef, true);
    return requestData;
  }

  addCampaign = () => {
    this.appRoutingService.openCampaignCreation();
  };

  onChangeSortingType(event) {
    this.sortingType = event;
    this.calendarEvents.sort(this.sortByDate)
    this.calendarEvents = [ ...this.calendarEvents];
  }

  sortByDate = (currEl: CalendarEvent, followingEl: CalendarEvent) => {
    const currStartDate = currEl.startDate && currEl.startDate.getTime();
    const followingStartDate = followingEl.startDate && followingEl.startDate.getTime();
    const currentElName = currEl.name;
    const followingElName = followingEl.name;
    let result = (followingStartDate || 0) - (currStartDate || 0);
    if (result === 0) {
      result = currentElName.localeCompare(followingElName);
    }
    return  this.sortingType === 'recent' ? result : -result;
  };

  defineMonthsLabels() {
    const startMonthIndex = this.budgetStartDate.getUTCMonth();
    let currentYear = this.budgetStartDate.getUTCFullYear();
    const months = [...ORDERED_SHORT_MONTHS_NAMES];
    const restOfMonths = months.splice(0, startMonthIndex);
    this.monthsLabels = [ ...months, ...restOfMonths ]
      .map(el => {
        const labelObj = {
          id: `${currentYear}-${el}`,
          month: el,
          year: currentYear.toString().substring(2)
        };
        if (el === 'dec') { currentYear++ ; }
        return labelObj;
      }
    )
  }

  defineTimeLineData() {
    const todayFixedDate = getTodayFixedDate(this.selectedBudget);
    const todayDate = todayFixedDate ? todayFixedDate : new Date();
    const todayMonth = todayDate.getMonth();
    const todayMonthName = ORDERED_SHORT_MONTHS_NAMES[todayMonth] || '';
    const todayYear = todayDate.getFullYear();
    const totalDiffDays = new Date(todayYear, todayMonth + 1, 0).getDate();
    const todayDiffDays = todayDate.getDate() - 1;
    this.todayPercentage = Math.round((todayDiffDays / totalDiffDays) * 100);
    this.currentMonth = `${todayYear}-${todayMonthName}`;
    this.todayDateLabel = `${todayMonthName} ${todayDate.getDate()}, ${todayYear}`;
  }

  displayTooltip(e, data: CalendarEvent) {
    this.tooltipTrigger.next({
      show: true,
      event: data,
      targetElement: e.target,
      containerRef: this.containerRef
    });
  }

  hideTooltip() {
    this.tooltipTrigger.next({
      show: false,
      event: null,
      containerRef: this.containerRef
    });
  }

  onQueryParamsChanged = (queryParamMap: ParamMap) => {
    const timeframe = queryParamMap.get('timeframe');
    const params = {...this.activatedRoute.snapshot.queryParams};
    if (timeframe) {
      this.timeframeQueryParam = timeframe;
      delete params.timeframe;
      this.router.navigate([], { queryParams: params })
    } else if (this.timeframeQueryParam) {
      this.loadCampaigns()
    }
  };
}
