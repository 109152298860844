<div class="sidebar-content-wrapper" *ngIf="selectedViewMode$ | async as selectedViewMode">
  <ng-container *ngIf="{ isLoading: isLoading$ | async } as data">
    <div class="sidebar-header">
      <search-control
        *ngIf="!isCustomFieldFiltersSelected"
        [placeholder]="'Search ' + hierarchyViewModeTitle[selectedViewMode]"
        [disabled]="data.isLoading"
        (textChanged)="filterHierarchyItems($event)"
      ></search-control>

      <div class="d-flex view-mode-container">
        <view-mode-select-btn
          [selectedMode]="selectedViewMode"
          [disabled]="data.isLoading || isCustomFieldFiltersSelected"
          [statuslessExpenses]="statuslessExpenses"
          (onSelectMode)="selectMode($event)"
        ></view-mode-select-btn>
        <select-all-btn
          *ngIf="!isSearchActive"
          [isAllSelected]="isCustomFieldFiltersSelected ? true : (isAllSelected$ | async)"
          [viewModeTitle]="selectedModeTitle"
          [disabled]="data.isLoading || isCustomFieldFiltersSelected"
          (onAllSelectedToggle)="toggleSelectAll($event)"
        ></select-all-btn>
      </div>
    </div>

    <loader [isVisible]="data.isLoading"></loader>
    <ng-container *ngIf="!data.isLoading">
      <sidebar-hierarchy-options-list
        *ngIf="isHierarchy; else flatList"
        class="options-list-wrapper"
        [ngClass]="{ 'no-pointer-events': isCustomFieldFiltersSelected }"
        [hideGroupCount]="isCustomFieldFiltersSelected"
        [hierarchyOptions]="shownOptions$ | async"
        [searchQuery]="searchQuery"
        [segments]="segments"
        [sharedCostRules]="sharedCostRules"
        [isAllSelected]="isAllSelected$ | async"
        [expenseCountsMap]="expenseCountsMap$ | async"
        (onDrop)="onDropHandle($event)"
      ></sidebar-hierarchy-options-list>
      <ng-template #flatList>
        <sidebar-flat-options-list
          class="options-list-wrapper"
          isScrolled
          [class.has-shadow]="hasScroll"
          [flatOptions]="shownOptions$ | async"
          [viewMode]="selectedViewMode"
          [searchQuery]="searchQuery"
          [isAllSelected]="isAllSelected$ | async"
          [expenseCountsMap]="expenseCountsMap$ | async"
          (onDrop)="onDropHandle($event)"
          (isScrolledChanged)="hasScroll = $event"
        ></sidebar-flat-options-list>
      </ng-template>
    </ng-container>
  </ng-container>
</div>
