<div class="wrapper">
  <div class="sidebar-container"
       [ngClass]="{
          'freeze-animations': !sidebarStateInited,
          'collapsed': sidebarCollapsed
        }">
    <div class="toggler" (click)="setSidebarCollapsingState()">
      <fa-duotone-icon class="arrow" [icon]="['fad', 'arrow-alt-from-left']"></fa-duotone-icon>
    </div>
    <sidebar-content></sidebar-content>
  </div>
  <article class="content" [ngClass]="{ 'full-screen': sidebarCollapsed }">
    <page-header [pageName]="pageTitle[spendingManagementMode]">
      <fa-icon slot="icon" [icon]="pageIcon[spendingManagementMode]"></fa-icon>
      <div slot="name">
        <tab-switch
          *ngIf="hasInvoiceAccess"
          [options]="spendingManagementModeOptions"
          [selectedValue]="spendingManagementMode"
          [customHandle]="true"
          (onChange)="handleSpendingManagementModeChange($event)"
        ></tab-switch>
      </div>
    </page-header>

    <div class="content-inner">
      <div class="d-flex jc-between mb-1">
        <div class="d-flex ai-center">
          <search-control
            placeholder="Search Expenses"
            *ngIf="!searchHidden"
            [debounceTime]="650"
            [disabled]="searchDisabled"
            [currentSearch]="currentTableSearch"
            (textChanged)="tableSearchChange($event)"
          ></search-control>
          <upload-status-btn
            *ngIf="spendingManagementMode === spendingManagementModes.Invoices"
            [failedFilesNumber]="invoiceUploadManager.failedFilesNumber$ | async"
            [uploadingFilesNumber]="invoiceUploadManager.uploadingFilesNumber$ | async"
            (viewInvoices)="viewNewInvoices()"
          ></upload-status-btn>
          <add-object-button
            *ngIf="spendingManagementMode === spendingManagementModes.Expenses"
            matTooltip="Create an Expense"
            matTooltipShowDelay="1000"
            matTooltipClass="dark-theme-tooltip simple"
            matTooltipPosition="above"
            [isDisabled]="isAddExpenseAllow"
            [iconConfig]="['fas', 'coins']"
            (createObjectClick)="openExpenseDrawer()">
          </add-object-button>
        </div>
        <div class="d-flex ai-center">
          <mat-paginator
            *ngIf="totalRecordsCount > paginationParams.limit"
            class="pl-paginator mr-1"
            [length]="totalRecordsCount"
            [pageSize]="paginationParams.limit"
            [pageIndex]="paginationParams.page"
            hidePageSize
            aria-label="Select page"
            (page)="changePage($event)">
          </mat-paginator>
          <invoice-upload-btn
            *ngIf="spendingManagementMode === spendingManagementModes.Invoices"
            type="basic"
            [disabled]="invoiceUploadManager.isUploadInProgress$ | async"
          ></invoice-upload-btn>
          <spending-manage-menu
            [editPermission]="!readOnlyMode"
            [hasSelectedExpenses]="hasSelection"
          ></spending-manage-menu>
        </div>
      </div>
      <div
        class="relative"
        [ngClass]="{
          'drop-area': spendingManagementMode === spendingManagementModes.Invoices,
          'drag-active': dragActive,
          'banner-shown': isSelectAllBannerShown
        }"
        (dragenter)="onDragEnter($event)"
        (dragleave)="onDragLeave($event)"
        (drop)="onDrop($event)"
      >
        <select-all-banner
          *ngIf="isSelectAllBannerShown"
          [viewMode]="spendingManagementMode"
          [isTotalSelected]="expensePageSelection.isTotalSelected$ | async"
          [pageSelectionNumber]="selectedItemsNumber"
          [totalSelectionNumber]="totalRecordsCount"
          (toggleTotalSelected)="toggleTotalSelected($event)"
        ></select-all-banner>

        <ng-container *ngIf="spendingManagementMode === spendingManagementModes.Invoices">
          <invoice-upload-overlay *ngIf="!(invoiceUploadManager.isUploadInProgress$ | async)"></invoice-upload-overlay>
        </ng-container>

        <router-outlet></router-outlet>

        <select-hierarchy-item-banner
          *ngIf="!sidebarHasSelection"
          class="empty-placeholder"
          [blurred]="sidebarLoading$ | async"
          [itemType]="itemName[spendingManagementMode]"
          [@infoBanner]></select-hierarchy-item-banner>

        <loader [isVisible]="(tableDataLoading$ | async) || (isActionInProgress$ | async)" [@fade]></loader>
      </div>
    </div>
  </article>
</div>

<budget-summary-bar
  [summaryItems]="summaryBarItems"
  [summaryBarLoading$]="summaryBarLoading"
  [summaryBarViewMode]="summaryBarViewMode"
></budget-summary-bar>
