import { inject, Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { ManageTableUpdateAction } from '@shared/types/manage-table-update-action';
import { ManageCegTableActionDataSource, ManageCegTableAllocationUpdatePayload } from '../types/manage-ceg-page.types';
import { ManageCegTableDataMutationService } from './manage-ceg-table-data-mutation.service';
import { BudgetAllocationActionsService } from '../../budget-allocation/services/budget-allocation-actions.service';
import { BudgetAllocationTopupAction } from '../../budget-allocation/budget-allocation-gestures-actions/budget-allocation-topup-action';
import { BudgetAllocationCellGesturesEvent } from '../../budget-allocation/components/budget-allocation-cell/budget-allocation-cell.types';
import { BudgetAllocationMoveAction } from '../../budget-allocation/budget-allocation-gestures-actions/budget-allocation-move-action';
import { ManageCegTableDataService } from '@manage-ceg/services/manage-ceg-table-data.service';
import { ManageCegPageService } from '@manage-ceg/services/manage-ceg-page.service';
import { BudgetAllocationAction } from '../../budget-allocation/budget-allocation-gestures-actions/budget-allocation-action.types';
import { ManageTableActionHistoryService } from '@shared/services/manage-table-action-history.service';

@Injectable()
export class ManageCegAllocationService implements OnDestroy {
  private readonly dataMutationService = inject(ManageCegTableDataMutationService);
  private readonly actionsManager: BudgetAllocationActionsService<ManageCegTableActionDataSource> = inject(BudgetAllocationActionsService);
  private readonly tableDataService = inject(ManageCegTableDataService);
  private readonly managePageService = inject(ManageCegPageService);
  private readonly historyManager: ManageTableActionHistoryService<BudgetAllocationAction<any>> = inject(ManageTableActionHistoryService);

  private readonly destroy$ = new Subject<void>();

  private get currencySymbol(): string {
    return this.managePageService.budgetCurrency?.symbol;
  }

  handleAllocationChange(dataSource: ManageCegTableActionDataSource, amount: number): void {
    const undoCallbacks = [];
    const action = new ManageTableUpdateAction<ManageCegTableActionDataSource>({
      context: { dataSource, amount },
      setAmount: (value, ds) => {
        const payload = ManageCegTableDataMutationService.prepareAllocationsUpdatePayload(ds, value, {});
        return this.dataMutationService.updateAllocations(payload, undoCallbacks);
      },
      getAmount: this.tableDataService.getDataSourceValue,
      currency: this.currencySymbol,
      undoCallbacks
    });

    this.executeAction(action);
  }

  handleSegmentAllocationChange(dataSource: ManageCegTableActionDataSource, amount: number): void {
    const action = new ManageTableUpdateAction<ManageCegTableActionDataSource>({
      context: { dataSource, amount },
      setAmount: (value, ds) => {
        const payload = ManageCegTableDataMutationService.prepareAllocationsUpdatePayload(ds, value, {});
        return this.dataMutationService.updateSegmentAllocation(payload);
      },
      getAmount: this.tableDataService.getDataSourceValue
    });

    this.executeAction(action);
  }

  handleDoubleClick(dataSource: ManageCegTableActionDataSource, gestureEvent: BudgetAllocationCellGesturesEvent): void {
    const actionMessageTrigger = new Subject<void>();
    const undoCallbacks = [];
    const action = new BudgetAllocationTopupAction<ManageCegTableActionDataSource>({
      actionTarget: {
        dataSource,
        difference: gestureEvent.difference,
        surplus: gestureEvent.surplus,
        overspend: gestureEvent.overspend
      },
      setAmount: (value, ds) => {
        const payload = ManageCegTableDataMutationService.prepareAllocationsUpdatePayload(ds, value, {});
        return this.dataMutationService.updateAllocations(
          payload,
          undoCallbacks
        ).pipe(
          filter(result => result != null),
          takeUntil(this.destroy$),
          tap(result => {
            if (result) {
              actionMessageTrigger.next();
            }
          })
        );
      },
      getAmount: this.tableDataService.getDataSourceValue,
      currency: this.currencySymbol,
      undoCallbacks
    });

    this.executeActionWithUndone(action, actionMessageTrigger);
  }

  handleSegmentDoubleClick(dataSource: ManageCegTableActionDataSource, gestureEvent: BudgetAllocationCellGesturesEvent): void {
    const actionMessageTrigger = new Subject<void>();
    const action = new BudgetAllocationTopupAction<ManageCegTableActionDataSource>({
      actionTarget: {
        dataSource,
        difference: gestureEvent.difference,
        surplus: gestureEvent.surplus,
        overspend: gestureEvent.overspend
      },
      setAmount: (value, ds) => {
        const payload = ManageCegTableDataMutationService.prepareAllocationsUpdatePayload(ds, value, {});
        return this.dataMutationService.updateSegmentAllocation(payload).pipe(
          filter(result => result != null),
          takeUntil(this.destroy$),
          tap(result => {
            if (result) {
              actionMessageTrigger.next();
            }
          })
        );
      },
      getAmount: this.tableDataService.getDataSourceValue,
      currency: this.currencySymbol
    });

    this.executeActionWithUndone(action, actionMessageTrigger);
  }

  handleDrop(dataSource: ManageCegTableActionDataSource, gestureEvent: BudgetAllocationCellGesturesEvent): void {
    let payload: ManageCegTableAllocationUpdatePayload = {};
    const actionMessageTrigger = new Subject<void>();
    const undoCallbacks = [];
    const onExecutedTrigger = new BehaviorSubject<boolean>(null);
    this.actionsManager.trackDrop(dataSource, gestureEvent);

    const action = new BudgetAllocationMoveAction<ManageCegTableActionDataSource>({
      actionTarget: this.actionsManager.getDndTargets(),
      setAmount: (value, ds, isLast = false) => {
        payload = ManageCegTableDataMutationService.prepareAllocationsUpdatePayload(ds, value, payload);
        if (isLast) {
          this.dataMutationService.updateAllocations(
            payload,
            undoCallbacks
          ).pipe(
            filter(result => result != null),
            takeUntil(this.destroy$)
          ).subscribe(
            res => {
              if (res) {
                actionMessageTrigger.next();
              }
              onExecutedTrigger.next(res);
            }
          );
          payload = {};
        }
      },
      getAmount: this.tableDataService.getDataSourceValue,
      currency: this.currencySymbol,
      undoCallbacks,
      onExecutedTrigger
    });

    this.executeActionWithUndone(action, actionMessageTrigger);
  }

  handleSegmentDrop(dataSource: ManageCegTableActionDataSource, gestureEvent: BudgetAllocationCellGesturesEvent): void {
    let payload: ManageCegTableAllocationUpdatePayload = {};
    const actionMessageTrigger = new Subject<void>();
    const onExecutedTrigger = new BehaviorSubject<boolean>(null);
    this.actionsManager.trackDrop(dataSource, gestureEvent);

    const actionTargets = this.actionsManager.getDndTargets();
    const action = new BudgetAllocationMoveAction<ManageCegTableActionDataSource>({
      actionTarget: actionTargets,
      setAmount: (value, ds, isLast = false) => {
        payload = ManageCegTableDataMutationService.prepareAllocationsUpdatePayload(ds, value, payload);
        if (isLast) {
          this.dataMutationService.updateSegmentAllocation(payload).pipe(
            filter(result => result != null),
            takeUntil(this.destroy$)
          ).subscribe(res => {
            if (res) {
              actionMessageTrigger.next();
            }
            onExecutedTrigger.next(res);
          });
          payload = {};
        }
      },
      getAmount: this.tableDataService.getDataSourceValue,
      currency: this.currencySymbol,
      onExecutedTrigger
    });

    this.executeActionWithUndone(action, actionMessageTrigger);
  }

  private executeAction<T>(action: BudgetAllocationAction<T>): void {
    this.actionsManager.executeAction(action, () => this.historyManager.pushAction(action));
  }

  private executeActionWithUndone<T>(action: BudgetAllocationAction<T>, actionMessageTrigger: Subject<void>): void {
    this.actionsManager.executeAction(
      action,
      () => this.historyManager.pushAction(action),
      viaSnackbar => {
        if (viaSnackbar) {
          this.historyManager.popAction();
        }
      },
      actionMessageTrigger
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
