import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { UtilityService } from 'app/shared/services/utility.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Modal } from 'ngx-modialog-7/plugins/bootstrap';
import { ProgramTypeDO } from 'app/shared/types/program.interface';
import { BudgetObjectDialogService } from '../../shared/services/budget-object-dialog.service';
import { Configuration } from 'app/app.constants';
import { VendorsService } from '../../shared/services/backend/vendors.service';
import { CompanyDataService } from '../../shared/services/company-data.service';

@Component({
  selector: 'tab-vendors',
  templateUrl: './tab-vendors.component.html',
  styleUrls: ['./tab-vendors.component.scss']
})
export class VendorsTabComponent implements OnChanges, OnDestroy {
  @Input() companyId: number;
  private readonly destroy$ = new Subject<void>();
  vendorsList: ProgramTypeDO[];
  appliedSorting = true;
  SearchPlaceHolder = 'Search Vendor Types';
  NoDataText = '';

  public gridColumns  = [
    { name: 'name', label: 'Vendor', sortable: true, width: '40%' },
    { name: 'is_enabled', label: 'Enabled', sortable: true, width: '25%' },
    { name: 'usage_count', label: 'Count', sortable: true, width: '20%' }
  ];

  enableSwitchTooltip = this.config.vendorsDescriptions.enableTooltip;

  constructor(
    private readonly utilityService: UtilityService,
    private readonly service: VendorsService,
    public readonly modal: Modal,
    private readonly dialogService : BudgetObjectDialogService,
    private config : Configuration,
    private companyDataService : CompanyDataService

  ) { 
    this.NoDataText = this.config.attributesAndTagsLabel.VENDOR;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.companyId) {
      this.getVendorsList(this.companyId);
    }
  }

  getVendorsList(companyId: number) {
    this.utilityService.showLoading(true);
    this.service.getVendors({ company: companyId })
    .pipe(
      takeUntil(this.destroy$)
    )
    .subscribe(
      data => this.onVendorsListLoaded(data),
      error => this.utilityService.handleError(error)
    );
  }
  
  updateStatusChange(data) {
    this.utilityService.showLoading(true);
    this.service.updateVendors(data.id, data)
    .pipe(
      takeUntil(this.destroy$)
    ).subscribe(
      () => this.onVendorListEvent(this.config.vendorsDescriptions.updated),
      error => this.utilityService.handleError(error)
    );
  }

  addVendor(data) {
    this.utilityService.showLoading(true);
    data.is_custom = true;
    data.company = this.companyId;
    this.service.addVendors(data)
    .pipe(
      takeUntil(this.destroy$)
    ).subscribe(
      () => this.onVendorListEvent(this.config.vendorsDescriptions.created),
      error => this.utilityService.handleError(error)
    );
  }

  updateVendor(data) {
    this.utilityService.showLoading(true);
    this.service.updateVendors(data.id, data)
    .pipe(
      takeUntil(this.destroy$)
    ).subscribe(
      () => this.onVendorListEvent(this.config.vendorsDescriptions.updated),
      error => this.utilityService.handleError(error)
    );
  }

  deleteVendor(row) {
    const deleteHandler = () => {
    this.utilityService.showLoading(true);
    this.service.deleteVendors(row.id)
    .pipe(
      takeUntil(this.destroy$)
    ).subscribe(
      () => this.onVendorListEvent(this.config.vendorsDescriptions.deleted)
    );
    };
    const dialogMessage = `You will permanently lose '${row.name}' wherever it has been used.`;

    this.dialogService.openDeleteEntityDialog(deleteHandler, null, { title: 'Delete Vendor?', message: dialogMessage });

    
  }

  onVendorListEvent(msg : string) {
    this.utilityService.showToast({ Title: '', Message: msg, Type: 'success' });
    this.utilityService.showLoading(false);
    this.getVendorsList(this.companyId);
  }

  onVendorsListLoaded(data) {
    this.vendorsList = data;
    this.companyDataService.vendors$.next(data);
    this.utilityService.showLoading(false);
  }


}
