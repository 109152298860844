import { inject, Injectable } from '@angular/core';
import { API_V2_URL } from '@common-lib/lib/injection-tokens/url.tokens';
import { HttpClient } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { getRequestOptions } from 'app/shared/utils/http-request.utils';
import { ManageCegViewMode } from '@manage-ceg/types/manage-ceg-page.types';
import { AmountsDO } from '@shared/types/object-amounts.interface';
import { BudgetDataService } from 'app/dashboard/budget-data/budget-data.service';
import { UserManager } from 'app/user/services/user-manager.service';

@Injectable({
  providedIn: 'root'
})
export class ManageTableGrandTotalsService {
  private readonly actionV2Url = inject(API_V2_URL);
  private readonly http = inject(HttpClient);
  private readonly budgetDataService = inject(BudgetDataService);
  private readonly userManager= inject(UserManager);

  private static urlByManageViewMode(viewMode: ManageCegViewMode): string {
    return viewMode === ManageCegViewMode.Segments ? 'grand_total_of_segments' : 'grand_total_of_campaigns_and_programs';
  }

  public getBudgetGrandTotals(budgetId: number, viewMode: ManageCegViewMode, params: object): Observable<Record<string, AmountsDO>> {
    let url = `${this.actionV2Url}budget/${budgetId}/${ManageTableGrandTotalsService.urlByManageViewMode(viewMode)}/`;
    
    // API endpoint if Custom Field Expense Filters are applied
      
    if(params && params['custom_fields']) {

      url = `${this.actionV2Url}budget/${budgetId}/${ManageTableGrandTotalsService.urlByManageViewMode(viewMode)}_new/`;

      const userInfo = this.userManager.getCurrentUser();
      let companyId = this.budgetDataService.selectedBudgetStorage.getSelectedCompany(userInfo.id);

      let grandTotalFiltersPayload = { ...params, budget: budgetId, company: companyId };

      if(!Object.keys(params['custom_fields']).length) {
        delete grandTotalFiltersPayload['custom_fields']
      }

      let grandTotalForCustomFieldFilterPayload = { ...grandTotalFiltersPayload };

      if('short' in params) {
        grandTotalForCustomFieldFilterPayload['short'] = String(params['short']);
      }

      if('include_nested' in params) {
        grandTotalForCustomFieldFilterPayload['include_nested'] = String(params['include_nested']);
      }

      if('include_pseudo_objects' in params) {
        grandTotalForCustomFieldFilterPayload['include_pseudo_objects'] = String(params['include_pseudo_objects']);
      }
          
      return this.http.post<Record<string, AmountsDO>>(
        url,
        grandTotalForCustomFieldFilterPayload
      ).pipe(
        map((response) => {         
          return response;
        })
      );
    }
    
    return this.http.get<Record<string, AmountsDO>>(url, getRequestOptions(params));
  }
}
