export type SelectedValue = number | string;

export interface SelectItem {
  title: string;
  groupTitle?: string;
  value: SelectedValue;
  hidden?: boolean;
  disabled?: boolean;
  children?: SelectItem[];
  alwaysOnTop?: boolean;
  isEnabled?: boolean;
}

export interface SelectGroupSelectionState {
  allSelected: boolean;
  partialSelected: boolean;
}

export interface GroupsSelectionState {
  [groupId: number]: SelectGroupSelectionState
}

export interface SelectGroupsCollapseState {
  collapsed: { [groupId: number]: boolean };
  allCollapsed: boolean;
}
