import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Configuration } from 'app/app.constants';
import { BudgetDataService } from 'app/dashboard/budget-data/budget-data.service';
import { CalendarTimeframe } from 'app/marketing-calendar/components/marketing-calendar/marketing-calendar.component';
import { AppRoutingService } from 'app/shared/services/app-routing.service';
import { HistoryItem, HistoryService, ObjectOperationLogs } from 'app/shared/services/history.service';
import { UserManager } from 'app/user/services/user-manager.service';
import { Budget, BudgetTimeframesType } from 'app/shared/types/budget.interface';
import { BehaviorSubject } from 'rxjs';
import { FilterManagementService } from '../filters/filter-services/filter-management.service';
import { FilterName } from '../filters/filters.interface';
import { MenuPanelItem } from '../menu-panel/menu-panel.type';
import { LocalStorageService } from '@common-lib/services/local-storage.service';
import { CARD_TABLE_VIEW_MODE } from 'app/shared/constants/modes.constant';
import { PlanPageViewMode } from 'app/shared/enums/plan-page-view-mode.enum';
import { ManageTableViewMode } from 'app/manage-table/types/manage-table-view-mode.type';
import { AuditLogOperation } from 'app/shared/enums/audit-log-operation.enum';
import { UserDataService } from '@shared/services/user-data.service';
import { SpendingManagementMode } from '@spending/types/expense-page.type';
import { InvoiceLiveTracking } from '@shared/services/invoice-live-tracking';

interface ManagingMenuItem extends MenuPanelItem {
  action?: (event: ManagingMenuItem) => void;
  node?: 'Campaign' | 'Program' | 'Expense';
}

@Injectable({
  providedIn: 'root'
})
export class ManagingMenuService {
  private currentBudget: Budget;
  private readonly _menuItems = new BehaviorSubject(null);
  public readonly menuItems$ = this._menuItems.asObservable();
  public anyFiltersApplied = false;

  objectTypes = this.config.OBJECT_TYPES;
  routes = this.config.ROUTING_CONSTANTS;

  rootItems: {[key: string]: MenuPanelItem} = {
    [this.objectTypes.campaign]: {
      label: 'Campaigns',
      faIcon: ['far', 'rocket-launch'],
      customCssClass: 'ut-campaign'
    },
    [this.objectTypes.program]: {
      label: 'Expense Groups',
      faIcon: ['fas', 'briefcase'],
      customCssClass: 'ut-program'
    },
    [this.objectTypes.expense]: {
      label: 'Expenses',
      faIcon: ['fas', 'coins'],
      customCssClass: 'ut-expense'
    },
    [this.objectTypes.goal]: {
      label: 'Goals',
      faIcon: ['fas', 'bullseye-arrow'],
      customCssClass: 'ut-goal'
    },
    [this.objectTypes.invoice]: {
      label: 'Invoices',
      faIcon: ['fas', 'file-invoice-dollar'],
      asyncBadge: this.invoiceLiveTracking.invoiceTotalCount$,
      hidden: false,
      action: () => this.showInvoices()
    }
  };

  constructor(
    private config: Configuration, private router: Router,
    private filterManagementService: FilterManagementService,
    private userManager: UserManager, private budgetDataService: BudgetDataService,
    private historyService: HistoryService, private readonly appRouting: AppRoutingService,
    private readonly userDataService: UserDataService,
    private readonly invoiceLiveTracking: InvoiceLiveTracking
  ) {}

  private getActivePlanPageView() {
    return LocalStorageService.getFromStorage(CARD_TABLE_VIEW_MODE);
  }

  public generateManagingMenu() {
    this.currentBudget = this.budgetDataService.selectedBudgetSnapshot;
    const { campaign, program, expense, goal, invoice } = this.objectTypes;
    const menuItems = [ expense, campaign, program, goal, invoice ].map(
      objType => {
        const item = { ...this.rootItems[objType] };
        if (!item.action) {
          item.children = this.addChildren(objType)
        }
        if (item.label === this.objectTypes.invoice) {
          item.hidden = !this.invoiceLiveTracking.hasInvoiceAccess;
        }
        return item;
      }
    );
    this._menuItems.next(menuItems);
  }

  addChildren(objType) {
    const children: ManagingMenuItem[] = [];
    (objType === this.objectTypes.goal ? this.goalItems : this.commonItems)
      .forEach(getter => {
        const item = getter(objType);
        if (item) {
          children.push({ ...item, node: objType});
        }
      });
    return children;
  }

  get commonItems(): ((type: string) => ManagingMenuItem)[] {
    return [
      this.withCurrentFiltersItem,
      this.allObjectsItem,
      this.recentlyViewedItem,
      this.recentlyAddedItem,
      this.thisQuarterItem,
      this.thisMonthItem,
      this.overdueExpensesItem,
      this.myObjectsItem
     ]
  }

  get goalItems(): ((type: string) => ManagingMenuItem)[] {
    return [
      this.withCurrentFiltersItem,
      this.allObjectsItem,
     ]
  }

  allObjectsItem = objType => {
    const { label, faIcon } = this.rootItems[objType];
    return {
      label: `All ${label}`,
      faIcon,
      action: event => this.showAllObjects(event.node),
      node: objType,
      customCssClass: 'ut-all'
    }
  };
  recentlyViewedItem = objType => {
    const children = this._getHistoryItems(objType, AuditLogOperation.Viewed, this.historyService.objectOperationLogsData);
    const item = {
      label: 'Recently Viewed',
      faIcon: ['fas', 'history'],
      children,
      customCssClass: 'ut-recently-viewed',
      panelClass: 'recently-viewed'
    };
    return children && children.length ? item : null;
  };
  recentlyAddedItem = objType => {
    const children = this._getHistoryItems(objType, AuditLogOperation.Created, this.historyService.objectOperationLogsData);
    const item = {
      label: 'Recently Added',
      faIcon: ['fas', 'layer-plus'],
      children,
      customCssClass: 'ut-recently-added',
      panelClass: 'recently-added',
    };
    return children && children.length ? item : null;
  };
  thisQuarterItem = objType => {
    const { byExpenseTimeframes, byCampaignDate } =
      this._defineTimeframeFilterAction(objType, CalendarTimeframe.ThisQuarter);
    const item: ManagingMenuItem = {
      label: 'This Quarter',
      faIcon: ['fas', 'calendar-week'],
      node: objType,
      customCssClass: 'ut-this-quarter'
    };
    if (byCampaignDate && byExpenseTimeframes) {
      item.children = this._getCampaignFilterItems(CalendarTimeframe.ThisQuarter, objType)
    } else {
      item.action = byCampaignDate
        ? event => this.showByCampaignDate(CalendarTimeframe.ThisQuarter)
        : event => this.showThisQuarter(event.node);
    }
    return byCampaignDate || byExpenseTimeframes ? item : null;
  };
  thisMonthItem = objType => {
    const { byExpenseTimeframes, byCampaignDate } =
      this._defineTimeframeFilterAction(objType, CalendarTimeframe.ThisMonth);
    const item: ManagingMenuItem = {
      label: 'This Month',
      faIcon: ['fas', 'calendar-days'],
      node: objType,
      customCssClass: 'ut-this-month'
    };
    if (byCampaignDate && byExpenseTimeframes) {
      item.children = this._getCampaignFilterItems(CalendarTimeframe.ThisMonth, objType)
    } else {
      item.action = byCampaignDate
        ? event => this.showByCampaignDate(CalendarTimeframe.ThisMonth)
        : event => this.showThisMonth(event.node);
    }
    return byCampaignDate || byExpenseTimeframes ? item : null;
  };
  overdueExpensesItem = objType => {
    return objType === this.objectTypes.expense
      && this.currentBudget
      && this.currentBudget.type !== BudgetTimeframesType.Year
      ? {
          label: 'Overdue Expenses',
          faIcon: ['fas', 'alarm-exclamation'],
          action: event => this.showOverdueExpenses(event.node),
        customCssClass: 'ut-overdue-expenses'
        }
      : null
  };
  withCurrentFiltersItem = objType => {
    return {
        label: `View with Current Filter`,
        faIcon: ['fas', 'sliders'],
        disabled: !this.anyFiltersApplied,
        action: event => this.navigateAndApplyFilters(event.node),
        customCssClass: 'ut-with-filter'
      };
  };
  myObjectsItem = objType => {
    if (!this.userDataService.userEditPermission) {
      return;
    }
    const { label } = this.rootItems[objType];
    return {
      label: `My ${label}`,
      faIcon: ['fas', 'user'],
      action: event => this.showMyObjects(event.node),
      customCssClass: 'ut-my-items'
    }
  };

  showMyObjects(objectType) {
    const currentUser = this.userManager.getCurrentUser();
    this.navigateAndApplyFilters(objectType, {[FilterName.Owners]: [currentUser.id]});
  }

  showAllObjects(objectType) {
    const activeObjType = this.defineLastPlanPageView(objectType);
    this.navigateAndApplyFilters(activeObjType, {});
  }

  showThisQuarter(objType) {
    const currentQuarter = this.budgetDataService.currentQuarter.map(tf => tf.id);
    const activeObjType = this.defineLastPlanPageView(objType, true);
    this.navigateAndApplyFilters(activeObjType, {[FilterName.Timeframes]: currentQuarter});
  }

  showThisMonth(objType) {
    const currentMonth = this.budgetDataService.currentTimeframe.id;
    const activeObjType = this.defineLastPlanPageView(objType, true);
    this.navigateAndApplyFilters(activeObjType, {[FilterName.Timeframes]: [currentMonth]});
  }

  showByCampaignDate(calendarTimeframe: CalendarTimeframe) {
    this.router.navigate(
      [this.routes.CALENDAR],
      { queryParams: { timeframe: calendarTimeframe }, queryParamsHandling: 'merge' }
    );
  }

  showOverdueExpenses(objType) {
    const { planned, committed } = this.config.statusNames;
    const overdueTfIds = (this.budgetDataService.overdueTimeframes || []).map(el => el.id);
    this.navigateAndApplyFilters(objType, {
      [FilterName.Timeframes]: overdueTfIds,
      [FilterName.Statuses]: [ planned, committed ]
    });
  }

  showObjectDetails(objType, objId) {
    switch (objType) {
      case this.objectTypes.campaign: {
        return this.appRouting.openCampaignDetails(objId);
      }
      case this.objectTypes.program: {
        return this.appRouting.openProgramDetails(objId);
      }
      case this.objectTypes.expense: {
        return this.appRouting.openExpenseDetails(objId);
      }
    }
  }

  navigateAndApplyFilters(objectType, filters?) {
    const navigate = () => {
      const planPageViewMode = LocalStorageService.getFromStorage<string>(CARD_TABLE_VIEW_MODE);
      if (objectType === this.objectTypes.expense) {
        if (this.appRouting.getCurrentActivatedPrimaryRoutePath() !== this.routes.SPENDING_MANAGEMENT) {
          this.router.navigate([this.routes.SPENDING_MANAGEMENT], {queryParamsHandling: 'preserve'});
        }
      } else if (objectType === this.objectTypes.program) {
        this.appRouting.openPlanDetail([objectType], {}, planPageViewMode);
      } else {
        const activeObjectType = this.defineLastPlanPageView(objectType);
        this.appRouting.openPlanDetail([activeObjectType], {}, planPageViewMode);
      }
    };

    if (filters) {
      this.filterManagementService.updateCurrentFilterSet(filters, () => navigate());
    } else {
      navigate();
    }
  }

  private _defineTimeframeFilterAction(objectType, menuOption) {
    const budgetType = this.currentBudget && this.currentBudget.type;
    let byExpenseTimeframes = false;
    const byCampaignDate = objectType === this.objectTypes.campaign;
    if (this.budgetDataService.currentTimeframe) {
      if (budgetType && menuOption === CalendarTimeframe.ThisQuarter) {
        byExpenseTimeframes = budgetType !== BudgetTimeframesType.Year;
      } else if (budgetType && menuOption === CalendarTimeframe.ThisMonth) {
        byExpenseTimeframes = budgetType === BudgetTimeframesType.Month;
      }
    }
    return { byExpenseTimeframes, byCampaignDate }
  }

  private _getCampaignFilterItems(timeframe, objType) {
    return [
      {
        label: 'By Campaign Date',
        faIcon: ['far', 'rocket-launch'],
        action: event => this.showByCampaignDate(timeframe),
        node: objType
      },
      {
        label: 'By Expense Timeframe',
        faIcon: ['fas', 'coins'],
        action: timeframe === CalendarTimeframe.ThisQuarter
          ? event => this.showThisQuarter(event.node)
          : event => this.showThisMonth(event.node),
        node: objType
      }
    ]
  }

  private _getHistoryItems(objType: string, operation: AuditLogOperation, history: ObjectOperationLogs<HistoryItem>) {
    const { faIcon } = this.rootItems[objType];
    const historyItems = history?.[operation]?.[objType] || [];

    return historyItems.map(entry => {
      return {
        label: entry.object.name,
        faIcon,
        node: objType,
        action: event => this.showObjectDetails(event.node, entry.object.id)
      }
    })
  }

  private defineLastPlanPageView(objectType: string, isSchedule?: boolean): string {
    const activePlanPageView = this.getActivePlanPageView();
    return objectType === this.objectTypes.campaign
      && activePlanPageView === PlanPageViewMode.TABLE
      && isSchedule ? ManageTableViewMode.CampaignsExpenses
      : objectType;
  }

  private showInvoices(): void {
    this.router.navigate(
      [this.config.ROUTING_CONSTANTS.SPENDING_MANAGEMENT, SpendingManagementMode.Invoices]);
  }
}
