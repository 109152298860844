import { Component, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, concat, of, Subject, switchMap } from 'rxjs';
import { catchError, debounceTime, filter, startWith, takeUntil, tap } from 'rxjs/operators';
import { Configuration } from 'app/app.constants';
import { BudgetDataService } from 'app/dashboard/budget-data/budget-data.service';
import { HistoryService } from 'app/shared/services/history.service';
import { MenuPanelItem } from '../menu-panel/menu-panel.type';
import { ManagingMenuService } from './managing-menu.service';
import { FilterManagementService } from '../filters/filter-services/filter-management.service';
import { InvoiceLiveTracking } from '@shared/services/invoice-live-tracking';

@Component({
  selector: 'managing-menu',
  templateUrl: './managing-menu.component.html',
})
export class ManagingMenuComponent implements OnInit, OnDestroy {
  private readonly managingMenu = inject(ManagingMenuService);
  private readonly budgetDataService = inject(BudgetDataService);
  private readonly historyService = inject(HistoryService);
  private readonly filterManagementService = inject(FilterManagementService);
  private readonly invoiceLiveTracking = inject(InvoiceLiveTracking);

  private readonly destroy$ = new Subject<void>();
  items: MenuPanelItem[];
  @Input() disabled = false;
  @Input() activeRoute: string;
  @Input() editPermission: boolean;

  public get hasInvoices(): boolean {
    return this.invoiceLiveTracking.hasInvoices;
  }

  ngOnInit(): void {
    this.managingMenu.menuItems$.subscribe(items => this.items = items);

    const budget$ =
      this.budgetDataService.selectedBudgetSnapshot ?
        concat(
          of(this.budgetDataService.selectedBudgetSnapshot),
          this.budgetDataService.selectedBudget$
        ) :
        this.budgetDataService.selectedBudget$;

    budget$.pipe(
      switchMap(() =>
        combineLatest([
          this.budgetDataService.timeframeList$,
          this.filterManagementService.currentFilterSet$.pipe(
            tap(filterSet => {
              this.managingMenu.anyFiltersApplied = FilterManagementService.hasSelectedFilters(filterSet);
            })
          ),
          this.historyService.objectOperationLogs$.pipe(
            startWith([]), // Emit default empty array initializing mange menu
            filter(data => !!data),
            catchError(error => {
              console.error('Error fetching operation logs:', error);
              return of([]); // Provide default empty array in case of error
            })
          ),
        ])
      ),
      debounceTime(250),
      takeUntil(this.destroy$)
    ).subscribe(
      () => this.managingMenu.generateManagingMenu()
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
